import React from "react";
import { Button, Grid } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";

const EditActionButtons = ({
  handleSave,
  handleDelete,
  handlePreview,
  handleTest,
  noDeleteButton,
  handleSetDefaultProcess,
}) => {
  return (
    <Grid container spacing={5}>
      {/*Moved to HeaderBuilder*/}
      {/*<Grid item xs={6}>*/}
      {/*  {!noDeleteButton && (*/}
      {/*    <Button variant="outlined" color="default" onClick={handleDelete} sx={{ color: color.red }}>*/}
      {/*      Usuń*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*</Grid>*/}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {handlePreview && (
          <Button variant="outlined" color="default" onClick={handlePreview} sx={{ mr: 3 }}>
            Podgląd
          </Button>
        )}
        {handleTest && (
          <Button variant="outlined" color="default" onClick={handleTest} sx={{ mr: 3 }}>
            Wyślij test
          </Button>
        )}
        {handleSetDefaultProcess && (
          <Button variant="outlined" color="default" onClick={handleSetDefaultProcess} sx={{ mr: 3 }}>
            Ustaw jako domyślny i zapisz
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Zapisz
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditActionButtons;
