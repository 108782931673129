import Dialog from "@mui/material/Dialog";
import React, { memo } from "react";
import { Card, CardActionArea, CardMedia } from "@mui/material";


const ImagePreviewDialog = ({openImage, closeImage, imageUrl}) => {
    return (
        <Dialog
            open={openImage}
            onClose={closeImage}
        >
            <Card sx={{borderRadius: 0,}}>
                <CardActionArea
                    onClick={closeImage}
                >
                    <CardMedia
                        component="img"
                        src={imageUrl} />
                </CardActionArea>
            </Card>

        </Dialog>
    );
}

export default memo(ImagePreviewDialog);
