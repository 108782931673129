import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";

const submenuGroupUlStyles = {
  dropdown: {
    lineHeight: 3,
    color: alpha (color.white, 0.4),
    letterSpacing: -0.1,
    fontSize: "0.8rem",
    fontWeight: 400,
    display: "block",
    paddingLeft: 0,
  },
  name: {
    paddingLeft: 30,
  },
};

export default submenuGroupUlStyles;
