import { Grid, List, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedListItem from "components/Edit/CustomizedListItem";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import ProcessToolbar from "components/Edit/Process/ProcessToolbar";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import EditViewLanguage from "components/EditViewLanguage";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import { useFormContext } from "react-hook-form";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useSnackbar } from "notistack";

const endpoint = "/communication-group/";
const title = "marketing_process_edit_label";
const moduleUrl = "/marketing/processes/";

const MAX_SHORT_LIST = 5;

const types = [
  { id: "AFTER_CONTACT", displayName: "Wysyłka po prośbie kontaktu z klientem" },
  { id: "AFTER_ACTIVATION", displayName: "Wysyłka po aktywacji pakietu" },
  { id: "AFTER_PAYMENT", displayName: "Wysyłka po opłaceniu zamówienia" },
  { id: "AFTER_LAB_DONE", displayName: "Wysyłka po wrzuceniu wyników badań przez laboratorium" },
  { id: "AFTER_LAB_RECIVE", displayName: "Wysyłka po otrzymaniu próbek przez laboratorium" },
  { id: "AFTER_CUSTOMER_RECEIVE", displayName: "Wysyłka po odebraniu pakiety przez Klienta" },
  { id: "AFTER_ORDER_PACKAGE", displayName: "Wysyłka po zamównia kurieru z listem przewozowym" },
  { id: "PEERCELL", displayName: "Lead - PEERCELL" },
  { id: "INVOICE", displayName: "Wysyłka Faktury" },
  { id: "AFTER_ORDER_BACKEND", displayName: "Zamówienie wstępne - Backend" },
];

const ProcessEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    type: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().nullable(),
    isDefault: Yup.bool().nullable(),
    products: Yup.array().nullable(),
    mails: Yup.array().nullable(),
    smss: Yup.array().nullable(),
  });

  const defaultValue = {
    name: "",
    type: "",
    active: true,
    isDefault: false,
    products: [],
    mails: [],
    smss: [],
  };

  const refForm = useRef(null);
  const [isDefaultProcessDialogOpen, setDefaultProcessDialogOpen] = useState(false);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
        setDefaultsProcess={current?.isDefault ? null : () => setDefaultProcessDialogOpen(true)}
        deleteButtonTitle={"Usuń Proces"}
      >
        <FormData
          id={id}
          current={current}
          refForm={refForm}
          isDefaultProcessDialogOpen={isDefaultProcessDialogOpen}
          setDefaultProcessDialogOpen={setDefaultProcessDialogOpen}
        />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm, isDefaultProcessDialogOpen, setDefaultProcessDialogOpen }) => {
  const { t } = useTranslation("common");
  const { watch, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const [allActiveProducts, setAllActiveProducts] = useState([]);
  const [allActiveMails, setAllActiveMails] = useState([]);
  const [allActiveSmss, setAllActiveSmss] = useState([]);

  const [choiceProductsDialogOpen, setChoiceProductsDialogOpen] = useState(false);
  const [choiceMailsDialogOpen, setChoiceMailsDialogOpen] = useState(false);
  const [choiceSmssDialogOpen, setChoiceSmssDialogOpen] = useState(false);

  const [isMoreProducts, setIsMoreProducts] = useState(false);
  const [isMoreMails, setIsMoreMails] = useState(false);
  const [isMoreSms, setIsMoreSms] = useState(false);

  const watchProducts = watch("products");
  const watchMails = watch("mails");
  const watchSmss = watch("smss");
  const watchType = watch("type");

  useEffect(() => {
    backend.get("/product/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveMails;
          setAllActiveProducts(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveProducts([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/sms-template/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveSmss(res.data);
        }
      }
    });
    return () => {
      setAllActiveProducts([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/mail-template/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveMails(res.data);
        }
      }
    });
    return () => {
      setAllActiveProducts([]);
    };
  }, []);

  const handleSetDefault = () => {
    setDefaultProcessDialogOpen(false);

    const d = {
      id: id,
      type: watchType,
    };

    if (!watchType || watchType?.length == 0) {
      enqueueSnackbar('Przed tą akcją musisz wybrać "Rodzaj powiadomienia"', { variant: "warning" });
      return;
    }

    backend.post(endpoint + "test-process-may-by-default", d).then((res) => {
      if (isSuccess(res)) {
        console.log(res);
        if (!res.data) {
          enqueueSnackbar(
            "Tego procesu nie można ustawić jako domyślny - istnieje już domyślny proces tego typu w tym języku.",
            {
              variant: "warring",
            },
          );
          return;
        } else {
          setValue("isDefault", true);
          refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
        return;
      }
    });
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              label={"Rodzaj powiadomienia"}
              name={"type"}
              items={types}
              disabled={current?.isDefault}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedSwitch topLabel={"Status"} rightLabel={"Aktywny"} name={"active"} />
          </Grid>
          <Grid item xs={6}>
            {current?.isDefault && <CustomizedSwitch topLabel={"Domyślny"} rightLabel={"Tak"} name={"isDefault"} />}
          </Grid>
          <Grid item xs={12}>
            <ProcessToolbar
              label={
                <Typography variant="h4" component={"h4"}>
                  Produkt
                </Typography>
              }
              labelButton="Edytuj listę"
              buttonAction={() => setChoiceProductsDialogOpen(true)}
            />
            {watchProducts?.length > 0 ? (
              <>
                <List sx={{ mb: 5 }}>
                  {watchProducts?.map(
                    (item, index) =>
                      (index < MAX_SHORT_LIST || isMoreProducts) && (
                        <CustomizedListItem key={item.id} primaryText={item.name} />
                      ),
                  )}
                </List>
                <MoreLessBoxIcon
                  list={watchProducts}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreProducts}
                  setFullListOpen={setIsMoreProducts}
                />
              </>
            ) : (
              <Typography>Brak elementów</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <ProcessToolbar
              label={
                <Typography variant="h4" component={"h4"}>
                  Email
                </Typography>
              }
              labelButton="Edytuj listę"
              buttonAction={() => setChoiceMailsDialogOpen(true)}
            />
            {watchMails?.length > 0 ? (
              <>
                <List sx={{ mb: 5 }}>
                  {watchMails?.map(
                    (item, index) =>
                      (index < MAX_SHORT_LIST || isMoreMails) && (
                        <CustomizedListItem key={item.id} primaryText={item.name} />
                      ),
                  )}
                </List>
                <MoreLessBoxIcon
                  list={watchMails}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreMails}
                  setFullListOpen={setIsMoreMails}
                />
              </>
            ) : (
              <Typography>Brak elementów</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <ProcessToolbar
              label={
                <Typography variant="h4" component={"h4"}>
                  SMS
                </Typography>
              }
              labelButton="Edytuj listę"
              buttonAction={() => setChoiceSmssDialogOpen(true)}
            />
            {watchSmss?.length > 0 ? (
              <>
                <List sx={{ mb: 5 }}>
                  {watchSmss?.map(
                    (item, index) =>
                      (index < MAX_SHORT_LIST || isMoreSms) && (
                        <CustomizedListItem key={item.id} primaryText={item.name} />
                      ),
                  )}
                </List>
                <MoreLessBoxIcon
                  list={watchSmss}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreSms}
                  setFullListOpen={setIsMoreSms}
                />
              </>
            ) : (
              <Typography>Brak elementów</Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
      <CategoryEditListDialogControled
        handleClose={() => setChoiceProductsDialogOpen(false)}
        open={choiceProductsDialogOpen}
        allItemsTable={Array.isArray(allActiveProducts) ? allActiveProducts : []}
        nameFormTables={"products"}
        nameToDisplay={"name"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setChoiceMailsDialogOpen(false)}
        open={choiceMailsDialogOpen}
        allItemsTable={Array.isArray(allActiveMails) ? allActiveMails : []}
        nameFormTables={"mails"}
        nameToDisplay={"name"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setChoiceSmssDialogOpen(false)}
        open={choiceSmssDialogOpen}
        allItemsTable={Array.isArray(allActiveSmss) ? allActiveSmss : []}
        nameFormTables={"smss"}
        nameToDisplay={"name"}
      />
      <ConfirmationDialog
        open={isDefaultProcessDialogOpen}
        close={() => setDefaultProcessDialogOpen(false)}
        confirm={handleSetDefault}
        customTitle={"marketing_title_dialog_set_default"}
      />
    </>
  );
};

export default ProcessEdit;
