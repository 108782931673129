import React from "react";
import { Chip, Select, Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import { useFormContext } from "react-hook-form";
import CriteriaDataTypeCell from "components/Edit/PriceReductions/CriteriaDataTypeCell";
import { useTranslation } from "react-i18next";

const CriteriaTableRow = ({ listCriteria, listName, typesCondition, mainOperator, handleDeleteRow }) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation("common");

  const setType = (newValue, index) => {
    listCriteria[index].type = newValue;
    setValue(listName, listCriteria);
  };

  return (
    <>
      {listCriteria?.map((row, index) => (
        <TableRow key={row.id}>
          <TableCell>
            {mainOperator === "ALL" ? (
              <Chip label="AND" color="warning" size="small" />
            ) : (
              <Chip label="OR" color="secondary" size="small" />
            )}
          </TableCell>
          <TableCell>
            <FormControl size={"small"} fullWidth>
              <Select native value={row.type} onChange={(e) => setType(e.target.value, index)}>
                {typesCondition.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </TableCell>
          <CriteriaDataTypeCell
            row={row}
            listCriteria={listCriteria}
            listName={listName}
            index={index}
            typesCondition={typesCondition}
            mainOperator={mainOperator}
          />
          <TableCell>
            <Tooltip title={t("price_reduction_del_criteria")} placement="top" arrow={true} sx={{ mr: "10px" }}>
              <IconButton onClick={() => handleDeleteRow(row)}>
                <DeleteIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default CriteriaTableRow;
