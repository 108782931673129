import React from "react";
import { Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const CodeTableRow = ({row, handleEdit, handleRemove}) => {

  return (
    <>
       <TableRow>
          <TableCell>
              {row?.name}
          </TableCell>
          <TableCell align={"right"}>
            <Tooltip title={"Edytuj kod"} placement="top" arrow={true} sx={{ mr: "10px" }}>
              <IconButton onClick={handleEdit}>
                <EditIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Usuń kod"} placement="top" arrow={true} sx={{ mr: "10px" }}>
              <IconButton onClick={handleRemove}>
                <DeleteIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
    </>
  );
};

export default CodeTableRow;

