import React from "react";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";


const ListAttachments = ({attachments, delAttachment}) => {

    return (
        <>
            <TableContainer>
                <Table>
                    <TableBody>
                        {attachments.map((attach) =>
                            <TableRow key={attach.id}>
                                <TableCell>
                                    {attach.fileName}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        color="default"
                                        onClick={() => delAttachment(attach)}
                                        size="small">
                                        <DeleteIcon fontSize={"medium"}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ListAttachments;

