import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogTextField from "components/Edit/Dialog/DialogTextField";


const TestMailDialog = ({open, handleConfirm, handleClose}) => {
    const {t} = useTranslation("common");
    const [errors, setErrors] = useState({});
    const [mail, setMail] = useState("");

    const handleSubmit = () => {
        let isError = false;
        setErrors({})

        const TestEmail = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+)*(\.[a-zA-Z]{2,4})$/i;
        isError = mail.match(TestEmail);

        if (isError == null) {
            setErrors({"mail": { message: t("form-validation-mail") }})
        } else {
            setErrors({});

            handleConfirm(mail);
        }
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Test wysyłki e-mail</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogTextField
                        label={"Mail"}
                        name={"mail"}
                        errors={errors}
                        value={mail}
                        setValue={setMail}
                    />
                </Grid>
            </Grid>

            <Grid container sx={{mt: 5}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} >
                        Wyślij Test
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </>;
};
export default TestMailDialog;
