import * as color from "assets/styles/jss/common/colors";
import * as font from "assets/styles/jss/common/fonts";

const styles = (theme) => ({
  sidebar: {
    display: "flex",
    width: 325,
    marginLeft: "5vh",
    marginTop: "5vh",
    color: color.white,
    "@media (max-width: 960px)": {
      width: "100%",
      marginTop: 0,
      marginLeft: 0,
      "& nav": {
        width: "100%",
      },
    },
  },
  leftSidebar: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    height: "90vh",
    background: 'linear-gradient(150deg, #164194 78%, #1BA8CE 111%)',
    borderRadius: 20,
    fontSize: "0.65rem",
    fontWeight: font.bold,
  },
  rightSidebar: {
    width: 250,
    background: color.secondary,
    borderRadius: 20,
    height: "90vh",
  },
  logoWrapper: {
    padding: 30,
    width: "95%",
    height: 100,
  },
  submenuWrapper: {
    height: 'calc(90vh - 200px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxSizing: "content-box",
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.secondaryHover,
      borderRadius: 100,
    },
  },
  clicked: {
    background: color.secondary,
  },
  menuSectionHeader: {
    color: color.darkGray,
    marginTop: 20,
    textTransform: "uppercase",
  },
  menuElement: {
    "&:hover": {
      background: color.blue,
      color: color.white,
      cursor: "pointer",
    },
  },
  menuElementPosition: {
    padding: "4px 10px 4px 20px",
  },
  gap: {
    marginBottom: 16,
  },
  scrollable: {
    overflowY: "auto",
  },
  btn: {
    cursor: "pointer",
    paddingLeft: 16,
    "&:hover": {
      background: color.darkGray,
      color: color.white,
    },
  },
  lock: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid" + color.gray,
  },
  lockLabel: {
    marginRight: 16,
  },
  lockAlert: {
    marginTop: 20,
    padding: 20,
    background: color.red,
    color: color.white,
  },
  lastItem: {
    marginBottom: 10,
  },
  mobileMItems: {
    backgroundColor: color.primary + " !important",
    minHeight: 600,
    "& ul": {
      paddingLeft: 0,
    },
  },
  visibleSubmenu: {
    backgroundColor: color.secondary + " !important",
  },
  back: {
    display: "flex",
    padding: "9px 0 9px 20px",
    marginTop: 20,
    cursor: "pointer",
    "&:hover": {
      background: color.secondaryHover,
    },
  },
  mobileCategories: {
    marginTop: 80,
  },
});

export default styles;
