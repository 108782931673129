import React from "react";
import TableCell from "@mui/material/TableCell";
import { ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const CellNumberInvoice = ({data, handleClick, row}) => {
    const {t} = useTranslation("common");

    return (
        <TableCell onClick={(e) => handleClick(e, row)}>

            {!data?.examinationNumbers ? (
                    data?.invoiceNumber
                ) : (
                <ListItem>
                    <ListItemText primary={data?.invoiceNumber} secondary={"Numer badania: " + data?.examinationNumbers} />
                </ListItem>
                )
            }
        </TableCell>
    );
};


export default CellNumberInvoice;