import { Grid, InputLabel } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Wysiwyg from "components/Edit/Wysiwyg";
import { useFormContext } from "react-hook-form";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import Stack from "@mui/material/Stack";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import * as color from "assets/styles/jss/common/colors";

const icons = [
  { id: "IcnOpenBook", displayName: "IcnOpenBook" },
  { id: "IcnShieldPaddlock", displayName: "IcnShieldPaddlock" },
  { id: "IcnLabMicroscope", displayName: "IcnLabMicroscope" },
  { id: "IcnMap", displayName: "IcnMap" },
  { id: "IcnDostawaKurierem", displayName: "IcnDostawaKurierem" },
  { id: "IcnPobranieKrwiWLab", displayName: "IcnPobranieKrwiWLab" },
  { id: "IcnPobranieWymazuWLab", displayName: "IcnPobranieWymazuWLab" },
  { id: "IcnPobranieWDomu", displayName: "IcnPobranieWDomu" },
  { id: "IcnDostarczenieDoLab", displayName: "IcnDostarczenieDoLab" },
  { id: "IcnNietolerancjePokarmowe", displayName: "IcnNietolerancjePokarmowe" },
  { id: "IcnBadaniaDNA", displayName: "IcnBadaniaDNA" },
  { id: "IcnBadaniaPrenatalne", displayName: "IcnBadaniaPrenatalne" },
  { id: "IcnMikrofloraJelitowa", displayName: "IcnMikrofloraJelitowa" },
  { id: "IcnBadanieKrwi", displayName: "IcnBadanieKrwi" },
  { id: "IcnAlergie", displayName: "IcnAlergie" },
  { id: "IcnKoronawirus", displayName: "IcnKoronawirus" },
  { id: "IcnBadanieMoczu", displayName: "IcnBadanieMoczu" },
  { id: "IcnSuplementy", displayName: "IcnSuplementy" },
  { id: "IcnBadanieZWymazu", displayName: "IcnBadanieZWymazu" },
  { id: "IcnRadioUnchecked", displayName: "IcnRadioUnchecked" },
  { id: "IcnRadioUncheckedBlue", displayName: "IcnRadioUncheckedBlue" },
  { id: "CardMembershipOutlined", displayName: "CardMembershipOutlined" },
  { id: "VerifiedUserOutlined", displayName: "VerifiedUserOutlined" },
  { id: "LocalHospitalOutlined", displayName: "LocalHospitalOutlined" },
  { id: "LocationOnOutlined", displayName: "LocationOnOutlined" },
  { id: "Check", displayName: "Check" },
  { id: "Search", displayName: "Search" },
  { id: "ExpandMore", displayName: "ExpandMore" },
  { id: "ShoppingCart", displayName: "ShoppingCart" },
  { id: "Phone", displayName: "Phone" },
  { id: "ShoppingCartOutlined", displayName: "ShoppingCartOutlined" },
  { id: "LocationSearching", displayName: "LocationSearching" },
  { id: "IcnCreditCard", displayName: "IcnCreditCard" },
  { id: "BoxAccept", displayName: "BoxAccept" },
  { id: "BoxExperience", displayName: "BoxExperience" },
  { id: "BoxHeart", displayName: "BoxHeart" },
];

const BoxMainPage = ({ item, name, handleDelete, handleUp, handleDown, isLastElement, index }) => {
  const { register, formState } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            borderRadius: 2,
            border: "1px solid " + alpha(color.primary, 0.15),
            p: 8,
            mb: 6,
            position: "relative",
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            {index === 0 ? (
              <IconButton disabled>
                <ArrowDropUpIcon sx={{ color: alpha(color.black, 0.05) }} />
              </IconButton>
            ) : (
              <Tooltip title={"Przesuń w górę"}>
                <IconButton onClick={() => handleUp(item, index)}>
                  <ArrowDropUpIcon />
                </IconButton>
              </Tooltip>
            )}
            {isLastElement ? (
              <IconButton disabled>
                <ArrowDropDownIcon sx={{ color: alpha(color.black, 0.05) }} />
              </IconButton>
            ) : (
              <Tooltip title={"Przesuń w dół"}>
                <IconButton onClick={() => handleDown(item, index)} disabled={isLastElement}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={"Usuń"}>
              <IconButton onClick={() => handleDelete(item)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
            <Grid item xs={12}>
              <InputLabel htmlFor={name}>{"Tytuł"}</InputLabel>
              <TextField
                {...register(name + ".title")}
                helperText={formState.errors[name + ".title"] ? formState.errors[name + ".title"].message : ""}
                error={!!formState.errors[name + ".title"]}
                variant="outlined"
                size="small"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Wysiwyg label={"Treść"} name={name + ".content"} />
            </Grid>
            <Grid item xs={12}>
              <CustomizedSelect name={name + ".icon"} label={"Ikona"} items={icons} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default BoxMainPage;
