import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@mui/material";

const DialogTextFieldRef = ({
  label,
  name,
  errors,
  refItem,
  setValue,
  disabled = false,
  type = "text",
  placeholder = "",
}) => {
  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <TextField
        placeholder={placeholder}
        disabled={disabled}
        helperText={errors[name] ? errors[name].message : ""}
        error={!!errors[name]}
        variant="outlined"
        size="small"
        fullWidth={true}
        onBlur={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        inputRef={refItem}
        inputProps={type === "number" ? { min: 0 } : {}}
      />
    </>
  );
};

export default memo(DialogTextFieldRef);
