import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles(styles);

const HeaderIcon = ({itemData, action, disabled}) => {
    const classes = useStyles();
    const {t} = useTranslation("common");

    return (
        <>
            {((itemData.disabled || disabled) ? true : false) ? (
                    <IconButton
                        disabled={true}
                        color="default"
                        className={classes.actionIcon}
                        size="large">
                        {(itemData.icon === "ContentCopy") && <ContentCopyIcon fontSize={"small"}/>}
                        {(itemData.icon === "Delete") && <DeleteIcon fontSize={"medium"}/>}
                    </IconButton>
                ) : (
                    <Tooltip  title={t(itemData.tooltip)} placement="bottom" arrow={true} >
                        <IconButton
                         color="default"
                         onClick={action}
                         className={classes.actionIcon}
                         size="large">
                         {(itemData.icon === "ContentCopy") && <ContentCopyIcon fontSize={"small"}/>}
                         {(itemData.icon === "Delete") && <DeleteIcon fontSize={"medium"}/>}
                        </IconButton>
                    </Tooltip>
                )
            }
        </>
    );
};

export default HeaderIcon;
