import React from "react";
import TableCell from "@mui/material/TableCell";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const SliderTableCellActions = ({ drag, handleEdit, idItem}) => {
    const {t} = useTranslation("common");

    return (
        <TableCell align={"right"}>
            <Tooltip title={"Przesuń, aby zmienić kolejność"} placement="right-end" arrow={true} sx={{mr: "10px"}}>
                <IconButton ref={drag} style={{cursor: 'move'}}>
                    <DragIndicatorIcon fontSize={"small"} sx={{color: alpha(color.black, 0.4)}}/>
                </IconButton>
            </Tooltip>
            <Tooltip title={"Edytuj"} placement="right-end" arrow={true} sx={{mr: "10px"}}>
                <IconButton onClick={() => handleEdit(idItem)}>
                    <EditIcon fontSize={"small"} sx={{color: alpha(color.primary, 1)}}/>
                </IconButton>
            </Tooltip>
        </TableCell>
    );
};


export default SliderTableCellActions;