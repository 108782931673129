import Dialog from "@mui/material/Dialog";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { materialDialogOpen, selectMaterialDialogOpen } from "redux/slices/materials";
import { Button, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CategoryEditListPanel from "modules/CMSDocuments/CategoryEditListPanel";
import Stack from "@mui/material/Stack";

const allPage = [
    {
        id: 1,
        title: "Jak przygotować się do badań?"
    },
    {
        id: 2,
        title: "Test na koronawirusa Lubin - gdzie wykonać badanie?"
    },
    {
        id: 3,
        title: "Insulina - normy, badanie, insulina w ciąży"
    },
    {
        id: 4,
        title: "Do których badań należy podejść na czczo?"
    },
    {
        id: 5,
        title: "Rak jelita grubego - badania"
    },
    {
        id: 6,
        title: "Badania genetyczne"
    },
    {
        id: 7,
        title: "Badania x"
    },
    {
        id: 8,
        title: "Badania y"
    }
]

const CategoryEditListDialog = ({confirm}, ref) => {
    const {t} = useTranslation("common");
    const dispatch = useDispatch();
    const [rightCheckedId, setRightCheckedId] = useState(null);
    const [leftCheckedId, setLeftCheckedId] = useState(null);
    const [leftArray, setLeftArray] = useState(allPage);
    const [leftFilteredArray, setLeftFilteredArray] = useState(allPage);
    const [rightArray, setRightArray] = useState([])
    const [rightFilteredArray, setRightFilteredArray] = useState([])
    const [rightFilter, setRightFilter] = useState("");
    const [leftFilter, setLeftFilter] = useState("");

    const open = useSelector(selectMaterialDialogOpen);

    useEffect(() => {
        filtrationLeftArray()
    }, [leftFilter, leftArray])

    useEffect(() => {
        filtrationRightArray()
    }, [rightFilter, rightArray])

    useImperativeHandle(ref, () => ({
        close() {
            dispatch(materialDialogOpen(false));
        },
        open() {
            dispatch(materialDialogOpen(true));
        },
    }));

    const handleClose = () => {
        dispatch(materialDialogOpen(false));
    };

    const handleSubmit = () => {
        confirm({});
        dispatch(materialDialogOpen(false));
    }

    const handleAllRight = () => {
        setRightArray(rightArray.concat(leftFilteredArray));

        let newArray = [...leftArray];
        for (const element of leftFilteredArray) {
            newArray = newArray.filter(item => item.id !== element.id)
        }

        setLeftArray(newArray);
    };

    const handleAllLeft = () => {
        setLeftArray(leftArray.concat(rightFilteredArray));

        let newArray = [...rightArray];
        for (const element of rightFilteredArray) {
            newArray = newArray.filter(item => item.id !== element.id)
        }

        setRightArray(newArray);
    };

    const handleCheckedRight = () => {
        if (leftFilteredArray.some((item) => item.id === leftCheckedId)) {
            setRightArray(rightArray.concat(leftArray.filter((item) => item.id === leftCheckedId)));
            setLeftArray(leftArray.filter((item) => item.id !== leftCheckedId))
            setRightCheckedId(leftCheckedId);
            setLeftCheckedId(null);
        }
    };

    const handleCheckedLeft = () => {
        if (rightFilteredArray.some((item) => item.id === rightCheckedId)) {
            setLeftArray(leftArray.concat(rightArray.filter((item) => item.id === rightCheckedId)));
            setRightArray(rightArray.filter((item) => item.id !== rightCheckedId))
            setLeftCheckedId(rightCheckedId);
            setRightCheckedId(null);
        }
    };

    const filtrationLeftArray = () => {
        const newArray = leftArray.filter(item => item.title.toLowerCase().includes(leftFilter.toLowerCase()));
        setLeftFilteredArray(newArray);
    }

    const filtrationRightArray = () => {
        const newArray = rightArray.filter(item => item.title.toLowerCase().includes(rightFilter.toLowerCase()));
        setRightFilteredArray(newArray);
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Edytuj listę</Typography>
                </Grid>
                <Grid item xs={5}>
                    <CategoryEditListPanel
                        checkedId={leftCheckedId}
                        setCheckedId={setLeftCheckedId}
                        arrayItems={leftFilteredArray}
                        setFilter={setLeftFilter}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Stack
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        sx={{mt: "55px"}}
                    >
                        <IconButton onClick={handleAllRight}>
                            <KeyboardDoubleArrowRightIcon/>
                        </IconButton>
                        <IconButton onClick={handleCheckedRight}>
                            <KeyboardArrowRightIcon/>
                        </IconButton>
                        <IconButton onClick={handleAllLeft}>
                            <KeyboardDoubleArrowLeftIcon/>
                        </IconButton>
                        <IconButton onClick={handleCheckedLeft}>
                            <KeyboardArrowLeftIcon/>
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item xs={5}>
                    <CategoryEditListPanel
                        checkedId={rightCheckedId}
                        setCheckedId={setRightCheckedId}
                        arrayItems={rightFilteredArray}
                        setFilter={setRightFilter}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={6}>
                    <Button variant="outlined" color="default" onClick={() => {
                        dispatch(materialDialogOpen(false))
                    }}>
                        Zamknij
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Zapisz
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </>;
};
export default forwardRef(CategoryEditListDialog);
