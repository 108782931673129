import { Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataTableHead } from "./DataTableHead";
import { HEAD_CELL_TYPES } from "../../enums/headCellTypes";
import CellActions from "components/Table/CellActions";
import CellLanguageActions from "components/Table/CellLanguageActions";
import CellMiniature from "components/Table/CellMiniature";
import CellNumberInvoice from "components/Table/CellNumberInvoice";
import CellMarketingConsents from "components/Table/CellMarketingConsents";
import CellAttachment from "components/Table/CellAttachment";
import CellLanguageName from "components/Table/CellLanguageName";
import RowTextField from "components/Table/RowTextField";
import { selectMenuItems, setCurrentSelectedLevelOne, setCurrentSelectedLevelZero } from "redux/slices/menu";
import { findMenu } from "utils/menu";


const useStyles = makeStyles((theme) => ({}));

const DataTableStatic = ({data}) => {
    const classes = useStyles();
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedRow, setSelectedRow] = useState({});

    const [order, setOrder] = useState("id");
    const [orderBy, setOrderBy] = useState("asc");
    const [localData, setLocalData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isToSort, setIsToSort] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property)
        setIsToSort(true)
    };

    const handleClickRow = (e, row) => {
        setSelectedRow(row);
        if(localData.additionalParameters?.textFieldRow) {
            //console.log(row);
        } else {
            //console.log(row);
        }
    }

    const handleSaveRow = (newRow) => {
        const index = localData.items.findIndex((item) => item.id == newRow.id);

        const newData = {...localData};
        newData.items[index] = newRow;

        setLocalData(newData);
        setSelectedRow({});
    }

    const isTextFieldRowTest = (row) => {
        return localData.additionalParameters?.textFieldRow && (row.id === selectedRow.id);
    }

    const menuItems = useSelector(selectMenuItems)
    useEffect(() => {
        const {levelZero, levelOne} = findMenu(menuItems);
        dispatch(setCurrentSelectedLevelZero(levelZero));
        dispatch(setCurrentSelectedLevelOne(levelOne));
    }, [menuItems])

    useEffect(() => {
        setLocalData(data);
        setIsLoading(false);
    }, [data]);

    useEffect(() => {
        if(localData && isToSort) {
            let sortedData;

            if(order === "asc") {
                sortedData = localData.items.sort(sortFunction);
            } else {
                sortedData = localData.items.reverse(sortFunction);
            }

            const newData = {
                ...localData,
                items: sortedData
            }
            setLocalData(newData);
        }
        setIsToSort(false)
    }, [isToSort]);

    const sortFunction = (a, b) => {
        if (a[orderBy] < b[orderBy]) {
            return 1
        } else if (a[orderBy] > b[orderBy]) {
            return -1;
        } else {
            return 0;
        }
    }

    return (
        <div className={classes.root}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <TableContainer className={classes.styledTable}>
                        <Table className={classes.table} aria-label="table">
                            <DataTableHead
                                headCells={localData.headCells}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                noCheckbox={localData.additionalParameters?.noCheckbox}
                            />
                            <TableBody>
                                {localData.items.map((row, i) => {
                                    if(isTextFieldRowTest(row)) {
                                        return (
                                            <RowTextField
                                                key={row.id}
                                                row={row}
                                                headCells={localData.headCells}
                                                handleSaveRow={handleSaveRow}
                                            />
                                        )
                                    } else {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                                                      onClick={(e) => handleClickRow(e, row)}>
                                                {!localData.additionalParameters?.noCheckbox &&
                                                    <TableCell key={i} align="left">
                                                        <Checkbox
                                                            color={"default"}
                                                            size={"small"}
                                                        />
                                                    </TableCell>
                                                }
                                                {localData.headCells.map((hc, i) => {
                                                    if (hc.type === HEAD_CELL_TYPES.MARKETING_CONSENT) {
                                                        return <CellMarketingConsents key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.type === HEAD_CELL_TYPES.ACTION) {
                                                        return <CellActions key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.type === HEAD_CELL_TYPES.LANGUAGE_ACTION) {
                                                        return <CellLanguageActions key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.type === HEAD_CELL_TYPES.MINIATURE) {
                                                        return <CellMiniature key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.type === HEAD_CELL_TYPES.INVOICE_NUMBER) {
                                                        return <CellNumberInvoice key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.type === HEAD_CELL_TYPES.ATTACHMENT) {
                                                        return <CellAttachment key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.type === HEAD_CELL_TYPES.LANGUAGE_NAME) {
                                                        return <CellLanguageName key={i} data={row[hc.id]}/>
                                                    }
                                                    if (hc.date) {
                                                        return (
                                                            <TableCell key={i} align="left">
                                                                {moment(row[hc.id]).utc().format("YYYY.MM.DD HH:mm")}
                                                            </TableCell>
                                                        );
                                                    }
                                                    return (
                                                        <TableCell key={i} align="left">
                                                            {!hc.bool ? (
                                                                row[hc.id]
                                                            ) : row[hc.id] ? (
                                                                <Checkbox size={"small"} checked disabled/>
                                                            ) : (
                                                                <Checkbox size={"small"} disabled/>
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {localData.pagination && (
                        <TablePagination
                            rowsPerPageOptions={[15, 25, 50]}
                            component="div"
                            count={parseInt(localData.pagination.totalItems)}
                            rowsPerPage={localData.pagination.limit}
                            page={parseInt(localData.pagination.page)}
                            labelRowsPerPage={t("tp_rows_per_page")}
                            labelDisplayedRows={({ from, to, count }) => {
                                return "" + from + "-" + to + t("tp_from") + count;
                            }}
                            sx={{ marginTop: "20px" }}
                            onPageChange={() => {}}
                        />)}
                </>
            )}
        </div>
    );
};
export default DataTableStatic;
