import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { Checkbox, ListItemText } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MoveCell from "components/Edit/DnD/MoveCell";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";

export const RowDnDFrequentlySearched = ({
  id,
  primaryText,
  secondaryText,
  index,
  handleMoveItem,
  handleCheched,
  listCheckedItem,
  handleEditOpenDialog,
}) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      handleMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.15 : 1;
  drag(drop(ref));
  return (
    <TableRow style={{ opacity }} ref={preview} data-handler-id={handlerId}>
      <TableCell align={"left"} padding={"checkbox"}>
        <Checkbox onClick={(e) => handleCheched(e, id)} checked={listCheckedItem.includes(id)} />
      </TableCell>
      <TableCell>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </TableCell>
      <TableCell align={"right"}>
        <Tooltip title={"Edytuj"} placement="bottom" arrow={true}>
          <IconButton size={"small"} onClick={() => handleEditOpenDialog(id)}>
            <EditIcon fontSize={"small"} sx={{ color: alpha(color.primary, 1) }} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <MoveCell drag={ref} width="5%" />
    </TableRow>
  );
};
