import React, { useState } from "react";
import { IconButton, TextField, Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

const LabeledRow = ({label, value, noEditable = false, name, validateNoEmpty, validateMail}) => {
    const {setValue} = useFormContext();
    const {t} = useTranslation("common");
    const [editMode, setEditMode] = useState(false)
    const [newValue, setNewValue] = useState("");
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const saveValue = () => {
        if(validateNoEmpty && (!newValue || newValue.size == 0 || validateMail)) {
            setError(true);
            setErrorMessage(t("form-validation-required"));

            return;
        } else {
            setError(false);
            setErrorMessage("");
        }

        const TestEmail = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+)*(\.[a-zA-Z]{2,4})$/i;
        if(validateMail && (newValue.match(TestEmail) == null)) {
            setError(true);
            setErrorMessage(t("form-validation-mail"));

            return;
        } else {
            setError(false);
            setErrorMessage("");
        }

        setValue(name, newValue);
        setEditMode(false);
    }

    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            {!editMode ? (
                <TableCell>
                    {value}
                    {!noEditable && <Tooltip title={"Edytuj"} placement="bottom" arrow={true} sx={{ml: "10px"}}>
                    <IconButton size={"small"} onClick={() => {
                        setNewValue(value);
                        setEditMode(true);
                    }}>
                        <EditIcon fontSize={"small"}/>
                    </IconButton>
                    </Tooltip>}
                </TableCell>
            ) : (
                <TableCell>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            defaultValue={value}
                            size={"small"}
                            fullWidth
                            onChange={(e) => {setNewValue(e.target.value)}}
                            helperText={errorMessage}
                            error={error}
                            autoFocus
                            onKeyDown = {(e) => {
                                if (e.key === 'Enter') {
                                    saveValue()
                                } else if (e.key === 'Escape') {
                                    setEditMode(false);
                                }
                            }}
                        />
                        {!noEditable && <Tooltip title={"Zapisz"} placement="bottom" arrow={true} sx={{ml: "10px"}}>
                            <IconButton size={"small"} onClick={saveValue}>
                                <DoneIcon fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>}
                    </Stack>
                </TableCell>
            )}
        </TableRow>
    );
}

export default LabeledRow;
