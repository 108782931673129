import React, { memo, useEffect, useState } from "react";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

const CustomizedSelect = ({
  label,
  name,
  items,
  disabled = false,
  defaultValue,
  noNullValue = false,
  labelField = "displayName",
}) => {
  let emptyNullValue = { id: "" };
  emptyNullValue[labelField] = " - - - ";
  const nullValues = [emptyNullValue];

  const { control } = useFormContext();
  const [values, setValues] = useState(nullValues);

  useEffect(() => {
    if (!noNullValue) {
      setValues([...nullValues, ...items]);
    } else {
      setValues([...items]);
    }
  }, [items]);

  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <FormControl fullWidth size={"small"}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : null}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <>
                <Select
                  onBlur={onBlur} // notify when input is touched
                  onChange={onChange} // send value to hook form
                  value={value == undefined || value == null ? "" : value}
                  inputRef={ref}
                  error={!!formState?.errors[name]}
                  disabled={disabled}
                  displayEmpty={false}
                >
                  {values.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item[labelField]}
                    </MenuItem>
                  ))}
                </Select>
                {formState?.errors[name] && (
                  <FormHelperText error={true}>{formState?.errors[name].message}</FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default memo(CustomizedSelect);
