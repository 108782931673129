import React, {Fragment, useEffect, useState} from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const LaboratoryFragment = ({data, row, fullView, setIsAcceptNodeAvailable}) => {
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation("common");

    const [files, setFiles] = useState([]);
    const [geneticTestId, setGeneticTestId] = useState("");

    const [fileToSaveIndex, setFileToSaveIndex] = useState([]);
    const [fileSavedIndex, setFileSavedIndex] = useState([]);

    const [isAddAvailable, setAddAvalible] = useState(false);

    useEffect(() => {
        setFiles(Array(data.mapAttributes.length));
    }, [data]);

    useEffect(() => {
        setAddAvalible(isAddButtonAvailable());
    }, [files]);

    const saveFile = () => {
        if(!isAddAvailable) {
            return;
        }

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        formData.append("taskId", row.id);
        formData.append("geneticTestId", geneticTestId);

        const headers = {
            'Content-Type': `multipart/form-data;`,
        }

        backend.post("/inbox/upload", formData, {headers: headers})
            .then((response) => {
                if (isSuccess(response) && response.data == true) {

                    cleanAfterUpload();
                    enqueueSnackbar("Udany zapis pliku", {
                        variant: "success",
                    });
                } else {
                    errorSnacke();
                }
            })
    }

    const cleanAfterUpload = () => {
        let savedFilesIdx = [...fileSavedIndex, ...fileToSaveIndex]
        setFileSavedIndex(savedFilesIdx);
        setFileToSaveIndex([]);

        let isAllFilesUploaded = true;
        for(let i = 0; i < data.mapAttributes.length; i++) {

            if(data.mapAttributes[i].value != 'Załadowany'
                && !savedFilesIdx?.includes(i)) {

                isAllFilesUploaded = false;
            }
        }
        setIsAcceptNodeAvailable(isAllFilesUploaded);
    }


    const errorSnacke = () => {
        enqueueSnackbar("Nieudany zapis pliku", {
            variant: "error",
        });
    }

    const handleSetFile = (e, gTestId, index) => {
        files[index] = e.target.files[0];
        setFiles([...files]);
        setFileToSaveIndex(prev => [...prev, index]);
        setGeneticTestId(gTestId);
    }

    const isAddButtonAvailable = () => {
        let isAddAvailable = true;

        for (const file of files) {
            if(!file) {
                isAddAvailable = false;
            }
        }
        return isAddAvailable;
    }

    return (
        <>
            {fullView && data?.attachments && <>
                <Paper sx={{p: 5, mb: 5}} variant="outlined">
                {data?.attachments.map((attach) =>
                    <Fragment key={attach.id}>
                        {attach.description == 'GENETICTEST' && <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant={"h6"}>Dodaj plik &apos;.pdf&apos; z wynikiem</Typography>
                                </Grid>
                                {data.mapAttributes.map((attr, index) => (
                                    <Fragment key={attr.key}>
                                        <Grid item xs={12}>
                                            <Typography variant={"body"}>{attr.key}</Typography><br/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="h6"
                                                        component="label"
                                                        color={"primary"}
                                                        sx={{cursor: "pointer"}}
                                            >
                                                {"WYBIERZ PLIK"}
                                                <input
                                                    hidden accept="application/pdf"
                                                    type="file"
                                                    onChange={(e) => handleSetFile(e, attach.objectId, index)}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant={"body"}>
                                                {files[index] != null ? files[index].name : "Nie wybrano pliku"}
                                                <br/>
                                                {fileSavedIndex.includes(index) ? "Załadowany" : attr.value}
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                ))}
                            </Grid>
                        </>}
                    </Fragment>
                )}
            </Paper>
            <Button
                onClick={() => saveFile()}
                size={"small"}
                color="primary"
                disabled={!isAddAvailable}
            >
                Dodaj
            </Button>
            </>}
        </>
    );
};


export default LaboratoryFragment;