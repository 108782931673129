import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import CriteriaTable from "components/Edit/PriceReductions/CriteriaTable";

const defaultEmptyRule = {
  id: 0,
  productCriteria: "ANY",
  match: 1,
  criteriaItemList: [],
};

const Criteria = ({ typesCondition, mainListName, listName, mainOperatorName }) => {
  const { watch, setValue } = useFormContext();
  const watchProductCriteriaList = watch(mainListName);

  useEffect(() => {
    if (!watchProductCriteriaList) {
      setValue(mainListName, [defaultEmptyRule]);
    }
  }, []);

  return (
    <>
      {watchProductCriteriaList?.map((rule, index) => (
        <CriteriaTable
          key={rule.id}
          typesCondition={typesCondition}
          listName={`${mainListName}.${index}.${listName}`}
          mainOperatorName={`${mainListName}.${index}.${mainOperatorName}`}
        />
      ))}
    </>
  );
};

export default Criteria;
