import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@mui/material";
import React, { forwardRef, useImperativeHandle } from "react";


import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const ConfirmationCodeField = (props, ref) => {
  const { handleConfirmationCodeSubmit } = props;

  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    confirmationCode: Yup.string().matches(/^[0-9]{6}$/, t("profile_validation_2fa")).required(t("form-validation-required")),
  });

  const defaultValues = {
    confirmationCode: "",
  };

  const { register, handleSubmit, errors, setValue, setError } = useForm({
    defaultValues: defaultValues,
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  }); // initialize the hook

  const onSubmit = (d) => {
    handleConfirmationCodeSubmit(d.confirmationCode);
  };

  useImperativeHandle(ref, () => ({
    clean() {
      setValue("confirmationCode", "");
    },
    error() {
      setError("confirmationCode", t("profile_bad_confirmation_code"));
    },
  }));

  return (<>
      <Grid item xs={12} className={'fcx-py-5'}>
        {t("profile_confirmation_code")}:
      </Grid>
      <Grid item xs={12}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField name="confirmationCode"
                     className={'fcx-mb-10'}
                     label={t("profile_confirmation_code")}
                     onChange={(e) => {
                       if (e.target.value.length === 6) setTimeout(() => handleConfirmationCodeSubmit(e.target.value), 0);
                     }}
                     inputRef={register}
                     helperText={errors.confirmationCode ? errors.confirmationCode.message : ""}
                     error={!!errors.confirmationCode}
                     variant="outlined"
                     size="small"
                     fullWidth={true}/>
        </form>
      </Grid>
    </>
  );
};
export default forwardRef(ConfirmationCodeField);