import { Grid, Paper } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedDateTimePickerMuiValidLocalTimeToUtc from "components/Edit/CustomizedDateTimePickerMuiValidLocalTimeToUtc";
import EditViewNoLanguage from "components/EditViewNoLanguage";

const endpoint = "/test-ab/";
const title = "marketing_test_ab_edit_label";
const moduleUrl = "/marketing/ab-tests/";

const TestABEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
    variantA: Yup.string().trim().required(t("form-validation-required")),
    variantB: Yup.string()
      .trim()
      .required(t("form-validation-required"))
      .notOneOf([Yup.ref("variantA")], "Nazwy wariantów muszą być różne"),
    fromDate: Yup.date().typeError(t("form-validation-date")),
    toDate: Yup.date()
      .typeError(t("form-validation-date"))
      .test("is-after-fromDate", "Data zakończenia musi być późniejsza niż data rozpoczęcia", function (value) {
        const { fromDate } = this.parent;
        return fromDate ? new Date(value) > new Date(fromDate) : true;
      }),
  }).test("variants-not-same", t("form-validation-variants-not-same"), function (value) {
    const { variantA, variantB } = value;
    return variantA !== variantB;
  });

  const defaultValue = {
    name: "",
    active: false,
    fromDate: null,
    toDate: null,
    variantA: "",
    variantB: "",
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = () => {
  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch name={"active"} rightLabel={"Aktywny"} topLabel={<>&nbsp;</>} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Wariant A"} name={"variantA"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Wariant B"} name={"variantB"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiValidLocalTimeToUtc label={"Aktywny od: "} name={"fromDate"} defaultEmpty />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiValidLocalTimeToUtc label={"Aktywny do:"} name={"toDate"} defaultEmpty />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default TestABEdit;
