import React from "react";
import TextField from "@mui/material/TextField";
import { Grid, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Wysiwyg from "components/Edit/Wysiwyg";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import { useFormContext } from "react-hook-form";

const FaqItem = ({item, name, disabled = false, handleDelete}) => {
    const {register, formState} = useFormContext();

    return (
        <>
            <Box sx={{
                borderRadius: 2,
                border: "1px solid " + alpha(color.primary, 0.15),
                p: 8,
                mb: 6,
                position: "relative",
            }}>
                <IconButton onClick={() => handleDelete(item)} sx={{position: "absolute", top: "5px", right: "5px"}}>
                    <DeleteIcon/>
                </IconButton>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor={name}>
                            {"Pytanie"}
                        </InputLabel>
                        <TextField
                            {...register(name + '.question')}
                            helperText={formState.errors[name + '.question'] ? formState.errors[name + '.question'].message : ""}
                            error={!!formState.errors[name + '.question']}
                            variant="outlined"
                            size="small"
                            fullWidth={true}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Wysiwyg
                            label={"Odpowiedź"}
                            name={name + '.answer'}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default FaqItem;
