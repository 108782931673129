import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchLockInfo = createAsyncThunk('lockInfo/fetchInfo', async (id, thunkAPI) => {
  const res = await backend.get('/lock/info');
  return res.data;
});

const lockInfoSlice = createSlice({
  name: 'lockInfo',
  initialState: {
    locked: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLockInfo.fulfilled]: (state, action) => {
      state.locked = action.payload;
    },
  },
});

export const selectLockInfo = (state) => state.lockInfo.locked;

export default lockInfoSlice.reducer;