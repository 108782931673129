export default function getBackendHost() {
  const hostname = window && window.location && window.location.hostname;
  if (hostname === "zdrowegeny.pl") {
    return "https://zdrowegeny.pl/api";
  } else if (hostname === "zga.greenlogic.tech") {
    return "https://zga.greenlogic.tech/api";
  } else if (hostname === "panel.zdrowegeny.pl") {
    return "https://panel.zdrowegeny.pl/api";
  } else if (hostname === "zg.greenlogic.tech") {
    return "https://zg.greenlogic.tech/api";
  }else if (hostname === "zg2.greenlogic.tech") {
    return "https://zg2.greenlogic.tech/api";
  }
  else {
    return "http://localhost:3030";
  }
}
