import Dialog from "@mui/material/Dialog";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogSelect from "components/Edit/Dialog/DialogSelect";
import * as color from "assets/styles/jss/common/colors";
import DialogTextFieldRef from "components/Edit/Dialog/DialogTextFieldRef";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import { MENU_ITEM_TYPES } from "enums/menuItemTypes";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useFormContext } from "react-hook-form";

const NewMenuItemDialog = ({open, handleClose, handleConfirm, handleRemove, itemToEdit}) => {
    const {t} = useTranslation("common");
    const [errors, setErrors] = useState({});
    const {getValues} = useFormContext();

    const [label, setLabel] = useState("");
    const refLabel = useRef(null);
    const [type, setType] = useState("");
    const [url, setURL] = useState("");
    const refUrl = useRef(null);
    const [image, setImage] = useState("");
    const [altText, setAltText] = useState("");
    const refAltText = useRef(null);

    const [allMenus, setAllMenus] = useState([]);
    const [linkedMenuId, setLinkedMenuId] = useState("");

    useEffect(() => {
        if(open == true && type == 'SUBMENU') {
            const languageCode = getValues("lang");
            const id = getValues("id");

            backend.get("/cms-menu/getAllByLang/" + languageCode).then((res) => {
                if (isSuccess(res)) {
                    if (res.data.length > 0) {
                        let menus = res.data;

                        if(id) {
                            menus = menus.filter(item => {return item.id != id})
                        }

                        setAllMenus(menus);
                    }
                }
            });
            return () => {
                setAllMenus([])
            }
        }
    }, [open, type]);

    useEffect(() => {
        if(itemToEdit) {
            refLabel.current.value = itemToEdit.label;
            refUrl.current.value = itemToEdit.url;
            refAltText.current.value = itemToEdit.altText;

            setLabel(itemToEdit.label);
            setURL(itemToEdit.url);
            setAltText(itemToEdit.altText);
            setType(itemToEdit.type);
            setImage(itemToEdit.image);
            setLinkedMenuId(itemToEdit.linkedMenu?.id);
        } else {
            resetValue();
        }
    }, [itemToEdit, open])

    const testNoEmptyValue = (name, value) => {
        if (!value || value === "") {
            setErrors((prevState) => ({
                    ...prevState,
                    [name]: {message: t("form-validation-required")}
                })
            )
            return true;
        }
        return false;
    }

    const handleSubmit = () => {
        let isError = false;
        setErrors({})

        testNoEmptyValue("label", label) ? isError = true : '';
        testNoEmptyValue("url", url) ? isError = true : '';

        if(type == 'SUBMENU') {
            testNoEmptyValue("linkedMenuId", linkedMenuId) ? isError = true : '';
        }

        if (!isError) {
            setErrors({});

            let menuItem = {
                label: label,
                type: type,
                url: url,
            }

            if(type == 'LINK') {
                menuItem = {...menuItem, image: image, altText: altText, linkedMenu: null}
            } else if (type == 'SUBMENU') {
                menuItem = {...menuItem, image: '', altText: '', linkedMenu: {id: linkedMenuId}}
            } else {
                menuItem = {...menuItem, image: '', altText: '', linkedMenu: null}
            }

            if(itemToEdit) {
                menuItem = {...menuItem, id : itemToEdit.id, sortOrder : itemToEdit.sortOrder}
            }

            resetValue();

            handleConfirm(menuItem);
        }
    }

    const resetValue = () => {
        setErrors({});
        refLabel.current.value = "";
        refUrl.current.value = "";
        refAltText.current.value = "";

        setLabel("");
        setURL("");
        setAltText("");
        setType("LINK");
        setImage("");
        setLinkedMenuId("");
    }

    const handleDeleteItem = () => {
        if (itemToEdit) {
            handleRemove(itemToEdit);
        }

        resetValue();
        handleClose();
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={() => {
                resetValue();
                handleClose();
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Dodaj element menu</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogTextFieldRef
                        label={"Etykieta"}
                        name={"label"}
                        errors={errors}
                        setValue={setLabel}
                        refItem={refLabel}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DialogSelect
                        label={"Kategoria"}
                        name={"type"}
                        errors={errors}
                        items={MENU_ITEM_TYPES}
                        value={type}
                        setValue={setType}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DialogTextFieldRef
                        label={"URL"}
                        name={"url"}
                        errors={errors}
                        setValue={setURL}
                        refItem={refUrl}
                    />
                </Grid>
                <Grid sx={type != "LINK" ? {display: "none"} : {}} item xs={6}>
                    <Grid item xs={12}>
                        <CustomizedImagePicker
                            image={image}
                            setImage={setImage}
                            label={"Obrazek tła"}
                            placeholder={"Wybrany obrazek:"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DialogTextFieldRef
                            label={"Tekst alternatywny obrazka"}
                            name={"altText"}
                            errors={errors}
                            setValue={setAltText}
                            refItem={refAltText}
                        />
                    </Grid>
                </Grid>
                <Grid sx={type != "SUBMENU" ? {display: "none"} : {}} item xs={12}>
                    <DialogSelect
                        label={"Podmenu"}
                        name={"linkedMenuId"}
                        errors={errors}
                        items={allMenus}
                        value={linkedMenuId}
                        setValue={setLinkedMenuId}
                    />
                </Grid>
            </Grid>

            <Grid container sx={{mt: 5}}>
                <Grid item xs={6}>
                    <Button variant="outlined" color="default" sx={{color: color.red}} onClick={handleDeleteItem}>
                        Usuń
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Zapisz
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </>;
};
export default NewMenuItemDialog;
