import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogTextField from "components/Edit/Dialog/DialogTextField";
import * as color from "assets/styles/jss/common/colors";
import moment from "moment";
import CustomizedDateTimePickerMuiDialog from "components/Edit/CustomizedDateTimePickerMuiDialog";

const AddDiscountCodeDialog = ({ open, handleClose, handleRemove, handleConfirm, itemToEdit }) => {
  const { t } = useTranslation("common");
  const [errors, setErrors] = useState({});

  const [name, setName] = useState("");
  const [maxNumberUses, setMaxNumberUses] = useState("");
  const [validFrom, setValidFrom] = useState(moment().format("yyyy-MM-DDTHH:mm"));
  const [validUntil, setValidUntil] = useState(moment().format("yyyy-MM-DDTHH:mm"));

  useEffect(() => {
    if (itemToEdit) {
      setName(itemToEdit.name);
      setMaxNumberUses(
        itemToEdit.maxNumberUses === null || itemToEdit.maxNumberUses === "" ? "0" : itemToEdit.maxNumberUses,
      );
      setValidFrom(itemToEdit.validFrom ? itemToEdit.validFrom : null);
      setValidUntil(itemToEdit.validUntil ? itemToEdit.validUntil : null);
    } else {
      resetValue();
    }
  }, [itemToEdit, open]);

  const testNoEmptyValue = (name, value) => {
    if (!value || value === "") {
      setErrors((prevState) => ({
        ...prevState,
        [name]: { message: t("form-validation-required") },
      }));
      return true;
    }
    return false;
  };

  const testPositiveInteger = (name, value) => {
    if (!value || value === "" || isNaN(value) || value < 0) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: { message: t("form-validation-number") },
      }));
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    let isError = false;
    setErrors({});

    testNoEmptyValue("name", name) ? (isError = true) : "";
    testPositiveInteger("maxNumberUses", maxNumberUses) ? (isError = true) : "";

    if (!isError) {
      setErrors({});

      let code = {
        name: name,
        maxNumberUses: maxNumberUses,
        validFrom: validFrom,
        validUntil: validUntil,
      };

      if (itemToEdit) {
        code = { ...code, id: itemToEdit.id };
      }

      resetValue();
      handleConfirm(code);
    }
  };

  const resetValue = () => {
    setErrors({});

    setName("");
    setMaxNumberUses("0");
    setValidFrom(moment().format("yyyy-MM-DDTHH:mm"));
    setValidUntil(moment().format("yyyy-MM-DDTHH:mm"));
  };

  const handleDeleteItem = () => {
    if (itemToEdit) {
      handleRemove(itemToEdit);
    }

    resetValue();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              Dodaj kod
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogTextField label={"Nazwa"} name={"name"} errors={errors} value={name} setValue={setName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogTextField
              label={"Max liczba użyć kodu (0 = bez ograniczeń)"}
              name={"maxNumberUses"}
              errors={errors}
              value={maxNumberUses}
              setValue={setMaxNumberUses}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiDialog
              value={validFrom}
              setValue={setValidFrom}
              label={"Ważny od"}
              name={"validFrom"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiDialog
              value={validUntil}
              setValue={setValidUntil}
              label={"Ważny do"}
              name={"validUntil"}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={6}>
            <Button variant="outlined" color="default" sx={{ color: color.red }} onClick={handleDeleteItem}>
              Usuń
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Zapisz
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default AddDiscountCodeDialog;
