import React, { memo } from "react";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

const yesNoSelect = [
    {
        id: null, displayName: ' - - - '
    },
    {
        id: true, displayName: "Tak"
    },
    {
        id: false, displayName: "Nie"
    }
]

const CustomizedSelectBool = ({label, name, disabled = false}) => {
    const {register, formState, control} = useFormContext();

    return (
        <>
            <Grid item xs={12}>
                <InputLabel htmlFor={name}>
                    {label}
                </InputLabel>
                <FormControl fullWidth size={"small"}>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={null}
                        render={({
                                     field: { onChange, onBlur, value, name, ref },
                                     fieldState: { invalid, isTouched, isDirty, error },
                                     formState,
                                 }) => (
                                     <>
                                         <Select
                                             onBlur={onBlur} // notify when input is touched
                                             onChange={onChange} // send value to hook form
                                             value={(value == null || value == undefined) ? '' : value}
                                             inputRef={ref}
                                             error={!!formState?.errors[name]}
                                             disabled={disabled}
                                         >
                                             {yesNoSelect.map((item) => (
                                                 <MenuItem key={item.id} value={item.id}>{item.displayName}</MenuItem>
                                             ))}
                                         </Select>
                                         {formState?.errors[name] && <FormHelperText error={true}>{formState?.errors[name].message}</FormHelperText>}
                                     </>
                        )}
                    />
                </FormControl>
            </Grid>
        </>
    );
}

export default memo(CustomizedSelectBool);
