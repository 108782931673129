import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import MenuItemsPanel from "components/Edit/Product/MenuItemsPanel";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import { fetchAvailableLangs, selectLanguages } from "redux/slices/language";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RowDnDProductInCategory } from "components/Edit/DnD/RowDnDProductInCategory";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import backend from "api/backend";
import { isSuccess } from "utils/http";

const endpoint = "/cms-menu/";
const title = "cms_menu_edit_label";
const moduleUrl = "/cms/menu/";

const MAX_SHORT_LIST = 5;

const MenuEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Menu"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const dispatch = useDispatch();
  const { watch, getValues, setValue } = useFormContext();
  const [allLanguagesSelect, setAllLanguagesSelect] = useState([]);
  const languages = useSelector(selectLanguages);
  const [blockChangeLang, setBlockChangeLang] = useState(false);
  const watchItems = watch("menuItems");
  const watchBanners = watch("menuBanners");

  const [isOpenDialogSelectBanners, setIsOpenDialogSelectBanners] = useState(false);
  const [isMoreBanners, setIsMoreBanners] = useState(false);
  const [allActiveBanners, setAllActiveBanners] = useState(null);

  const moveItem = (dragIndex, hoverIndex) => {
    const fields = getValues("menuBanners");
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue("menuBanners", newFields);
  };

  useEffect(() => {
    dispatch(fetchAvailableLangs());
  }, []);

  useEffect(() => {
    if (languages?.length > 0) {
      const allLangs = [];
      for (const lang of languages) {
        allLangs.push({ id: lang.code, displayName: lang.name, sortOrder: lang.sortOrder });
      }
      setAllLanguagesSelect(
        allLangs.sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        }),
      );
    }
  }, [languages]);

  useEffect(() => {
    if (
      watchItems?.find((item) => {
        return item.type == "SUBMENU";
      })
    ) {
      setBlockChangeLang(true);
    } else {
      setBlockChangeLang(false);
    }
  }, [watchItems]);

  useEffect(() => {
    backend.get("/banner/getAllPageBanner").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveBanners(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveBanners([]);
    };
  }, []);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect
              label={"Język"}
              name={"lang"}
              items={allLanguagesSelect}
              defaultValue={"pl"}
              noNullValue
              disabled={blockChangeLang}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <MenuItemsPanel label={"Elementy menu"} name={"menuItems"} />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4">Banery</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setIsOpenDialogSelectBanners(true)}
              >
                Edytuj listę
              </Button>
            </Box>
            {!watchBanners?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {watchBanners?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMoreBanners) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={moveItem}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.buttonHref}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={watchBanners}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreBanners}
                  setFullListOpen={setIsMoreBanners}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      {isOpenDialogSelectBanners && allActiveBanners && (
        <CategoryEditListDialogControled
          handleClose={() => setIsOpenDialogSelectBanners(false)}
          open={isOpenDialogSelectBanners}
          allItemsTable={Array.isArray(allActiveBanners) ? allActiveBanners : []}
          nameFormTables={"menuBanners"}
          nameToDisplay={"name"}
        />
      )}
    </>
  );
};

export default MenuEdit;
