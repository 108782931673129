import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import TableCell from "@mui/material/TableCell";
import { Checkbox } from "@mui/material";
import SliderTableCellActions from "components/Edit/Slider/SliderTableCellActions";
import TableRow from "@mui/material/TableRow";
import { MENU_ITEM_TYPES } from "enums/menuItemTypes";

export const RowDnDMenu = ({ id, item, index, handleMoveItem, checked, handleCheched, handleEdit, extendedRow}) => {
    const ref = useRef(null)

    /* const moveCard = useCallback((dragIndex, hoverIndex) => {
        setList((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            }),
        )
    }, [])*/

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        drop(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            handleMoveItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0.15 : 1
    drag(drop(ref))
    return (
        <TableRow style={{ opacity }} ref={preview} data-handler-id={handlerId}>
            <TableCell>
                <Checkbox
                    onClick={(e) => handleCheched(e, id)}
                    checked={checked}
                />
            </TableCell>
            <TableCell>{item.label}</TableCell>
            {extendedRow && <TableCell>{item.altText}</TableCell>}
            {extendedRow && item.type && <TableCell>{MENU_ITEM_TYPES.find((type) => type.id == item.type)["displayName"]}</TableCell>}
            <SliderTableCellActions idItem={id} drag={ref} handleEdit={handleEdit}/>
        </TableRow>
    )
}
