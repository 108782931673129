import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";
import cloneDeep from "lodash/cloneDeep";

export const fetchCrudList = createAsyncThunk("users/list", async (data, thunkAPI) => {
  const { endpoint, filters } = data;
  const { page, limit, orderBy, order, attributes } = filters;
  const params = new URLSearchParams();
  params.set("page", page);
  params.set("limit", limit);
  params.set("order", order === "desc" ? orderBy + "+desc" : orderBy);
  attributes.forEach((a) => {
    params.set(`filter[${a.name}]`, a.value);
  });
  const res = await backend.get(`${endpoint}?${params.toString()}`);
  return { data: res.data, status: res.status };
});
export const fetchCrudItem = createAsyncThunk("user/edit", async (data, thunkAPI) => {
  const { endpoint, id } = data;
  const url = endpoint + id;
  const res = await backend.get(url);
  return { data: res.data, status: res.status };
});

function updateAttribute(attributes, name, value) {
  if (!value) {
    return attributes.filter((attribute) => attribute.name !== name);
  }
  let nameAttribute = attributes.filter((attribute) => attribute.name == name);
  if (nameAttribute.length === 0) {
    attributes.push({
      name,
      value,
    });
  } else {
    nameAttribute[0].value = value;
  }
  return attributes;
}

const crudSlice = createSlice({
  name: "crud",
  initialState: {
    toolbarItems: [],
    isLoading: false,
    list: [],
    headCells: [],
    currentItem: null,
    filters: [],
    filter: {
      page: 0,
      limit: 15,
      order: "asc",
      orderBy: "id",
      attributes: [],
    },
    info: {
      totalItems: 0,
      totalPages: 0,
    },
    error: {
      isError: false,
      status: 0,
      error: "",
      message: "",
    },
  },
  reducers: {
    setListPage: (state, action) => {
      state.filter = { ...state.filter, page: action.payload };
    },
    setListLimit: (state, action) => {
      state.filter = { ...state.filter, limit: action.payload };
    },
    setListOrder: (state, action) => {
      state.filter = { ...state.filter, order: action.payload };
    },
    setListOrderBy: (state, action) => {
      state.filter = { ...state.filter, orderBy: action.payload };
    },
    clearCurrentItem: (state, action) => {
      state.currentItem = null;
    },
    clearAllFilters: (state, action) => {
      state.filter = { ...state.filter, page: 0, limit: 15, order: "desc", orderBy: "id", attributes: [] };
    },
    setAttribute: (state, action) => {
      const currentState = cloneDeep(state.filter.attributes);
      if (Array.isArray(action.payload)) {
        let cs = currentState;
        action.payload.forEach(({ name, value }) => {
          cs = updateAttribute(cs, name, value);
        });
        console.log(cs);
        state.filter = { ...state.filter, attributes: cs };
      } else {
        const { name, value } = action.payload;
        const attributes = updateAttribute(currentState, name, value);
        state.filter = { ...state.filter, attributes };
      }
    },
  },
  extraReducers: {
    [fetchCrudList.pending]: (state, action) => {
      state.isLoading = true;
      state.list = [];
      state.filters = [];
      state.error = { isError: false, status: 0, error: "", message: "" };
    },
    [fetchCrudList.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        console.info("fetchCrudList Aborted");
      } else {
        state.isLoading = false;
        state.list = [];
        state.filters = [];
        state.error = { isError: true, message: action };
      }
    },
    [fetchCrudList.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data, status } = action.payload;
      if (status == 200) {
        const { toolbarItems, headCells, items, pagination, filters } = data;
        state.toolbarItems = toolbarItems;
        state.headCells = headCells;
        state.filters = filters;
        state.list = items;
        state.info = { ...state.info, totalItems: pagination.totalItems, totalPages: pagination.totalItems };
      } else {
        console.error(action.payload);
        state.error = { isError: true, status: data.status, error: data.error, message: data.message };
      }
    },
    [fetchCrudItem.pending]: (state, action) => {
      state.isLoading = true;
      state.error = { isError: false, status: 0, error: "", message: "" };
    },
    [fetchCrudItem.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = { isError: true, message: action };
    },
    [fetchCrudItem.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data, status } = action.payload;
      if (status == 200) {
        const { toolbarItems, item } = data;
        state.toolbarItems = toolbarItems;
        state.currentItem = item;
      } else {
        state.error = { isError: true, status: data.status, error: data.error, message: data.message };
      }
    },
  },
});
export const selectListFilters = (state) => state.crud.filter;
export const selectListListFilters = (state) => state.crud.filters;
export const selectListInfo = (state) => state.crud.info;
export const selectListHeadCells = (state) => state.crud.headCells;
export const selectListItems = (state) => state.crud.list;
export const selectEditItem = (state) => state.crud.currentItem;
export const selectListIsLoading = (state) => state.crud.isLoading;
export const selectListToolbar = (state) => state.crud.toolbarItems;
export const selectListError = (state) => state.crud.error;
export const {
  setListPage,
  setListLimit,
  setListOrder,
  setListOrderBy,
  clearAllFilters,
  setAttribute,
  clearCurrentItem,
} = crudSlice.actions;
export default crudSlice.reducer;
