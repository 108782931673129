import MomentUtils from "@date-io/moment";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./i18n";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import AppThemeProvider from "./themes/AppThemeProvider";

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <AppThemeProvider>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <App />
            </LocalizationProvider>
          </Suspense>
        </AppThemeProvider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== "production") {
  reportWebVitals();
}
