import { Grid, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import Divider from "@mui/material/Divider";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import backend from "api/backend";
import { isSuccess } from "utils/http";

const endpoint = "/users/";
const title = "settings_user_edit_label";
const moduleUrl = "/settings/users/";

const UserEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    roleId: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
    name: Yup.string().trim().required(t("form-validation-required")),
    login: Yup.string().trim().required(t("form-validation-required")),
    email: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
    repeatedPassword: Yup.string().test("passwords-match", t("form-validation-match-password"), function (value) {
      return this.parent.password === value;
    }),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Użytkownika"}
      >
        <FormData />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = () => {
  const { register, formState } = useFormContext();
  const [roleTypes, setRoleTypes] = useState([]);

  useEffect(() => {
    backend.get("/roles/all").then((res) => {
      if (isSuccess(res)) {
        setRoleTypes(res.data);
      }
    });
  }, []);

  const { t } = useTranslation("common");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              label={"Rola"}
              name={"roleId"}
              items={roleTypes}
              errors={formState?.errors}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch
              topLabel={<>&nbsp;</>}
              rightLabel={"Aktywny"}
              name={"active"}
              inputRef={register}
              errors={formState?.errors}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} inputRef={register} errors={formState?.errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Login"} name={"login"} inputRef={register} errors={formState?.errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Email"} name={"email"} inputRef={register} errors={formState?.errors} />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              label={"Hasło"}
              name={"password"}
              inputRef={register}
              errors={formState?.errors}
              type={"password"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              label={"Powtórz hasło"}
              name={"repeatedPassword"}
              inputRef={register}
              errors={formState?.errors}
              type={"password"}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default UserEdit;
