import React from "react";
import TableCell from "@mui/material/TableCell";
import { TableRow, Typography } from "@mui/material";

const RowNoData = ({colSpan}) => {
    return (
        <TableRow>
            <TableCell align={"center"}  colSpan={colSpan}>
                <Typography>brak danych</Typography>
            </TableCell>
        </TableRow>
    )
};

export default RowNoData;