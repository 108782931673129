import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import DataTable from "components/Table/DataTable";


const additionalParameters = {
    "noCheckboxColumn": true,
    "textFieldRow": true

}
const toolbarItems = [
    {
        "name": "toolbar_search_field",
        "tooltip": "toolbar_search_field_tooltip",
        "icon": "Search",
        "action": "search",
        "disabled": false,
        "sortOrder": 4,
        "type": "FIELDS_SEARCH"
    }];

const TranslationsList = () => {
    const {t} = useTranslation("common");

    return (
        <Layout
            header={t("settings_translations_label")}
            bottom={<EmptySection/>}
            top={<HeaderBuilder
                headerData={toolbarItems}
                moduleUrl={"/settings/translations"}
            />}
        >
            <Paper sx={{p: 10, mb: 10}} elevation={8}>
                <DataTable
                    modulePath={"/settings/translations"}
                    endpoint={"/locales/"}
                    additionalParameters={additionalParameters}/>
            </Paper>
        </Layout>
    );
};
export default TranslationsList;
