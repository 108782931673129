import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import backend from "api/backend";
import ConfirmationCodeField from "components/ConfirmationCodeField";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserLogged } from "redux/slices/user";
import { isSuccess } from "utils/http";
import * as Yup from "yup";
import Paper from "@mui/material/Paper";
import * as color from "assets/styles/jss/common/colors";
import Typography from "@mui/material/Typography";

const Login = () => {
    const {t} = useTranslation("common");
    const [submitButtonEnable, setSubmitButtonEnable] = useState(true);
    const [error, setError] = useState(false);
    const [fa, setFa] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const ref = useRef(null);

    const validationSchema = Yup.object({
        login: Yup.string().trim().min(2, t("form-validation-min2")).required(t("form-validation-required")),
        password: Yup.string().trim().min(8, t("form-validation-min2")).required(t("form-validation-required")),
    });

    const defaultValues = {
        login: "",
        password: "",
    };

    const [values, setValues] = useState({});

    const handleError = (status) => {
        if (status === 405) {
            setFa(true);
        } else if (status === 412) {
            ref.current.error();
        } else {
            setFa(false);
            setError(true);
            setSubmitButtonEnable(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
    };

    const handleConfirmationCodeSubmit = (value) => {
        backend
            .post("/user/login", {...values, confirmationCode: value})
            .then((response) => {
                if (isSuccess(response)) {
                    dispatch(setUserLogged(true));
                } else {
                    handleError(response.status);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const onSubmit = (d) => {
        setValues(d);
        setSubmitButtonEnable(false);
        backend
            .post("/user/login", d)
            .then((response) => {
                if (isSuccess(response)) {
                    dispatch(setUserLogged(true));
                } else {
                    handleError(response.status);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const {register, control, handleSubmit, formState: {errors}} = useForm({
        "defaultValues": defaultValues,
        "criteriaMode": "all",
        // "resolver": yupResolver(validationSchema),
    }); // initialize the hook

    return (

        <Grid
            container
            spacing={5}
            direction="column"
            alignItems="center"
            justifyContent="center"
            minHeight={"100vh"}
        >
            <Grid item xs={4}>
                <Paper sx={{p: 10, mb: 10, textAlign: "center"}} elevation={8}>
                    {error && <Typography variant={"h5"} style={{ background: color.lightRed, color: color.red, mb: 20, }}>{t("login_error_bad_login_or_password")}</Typography>}
                    {!fa ? (
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <img src="/logo-zg.svg" style={{maxWidth: 300, mb: 20}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        {...register("login")}
                                        name="login"
                                        label={t("login_login")}
                                        variant="outlined"
                                        helperText={errors.login ? errors.login.message : ""}
                                        error={!!errors.login}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        {...register("password")}
                                        type="password"
                                        label={t("login_password")}
                                        variant="outlined"
                                        id="outlined-required"
                                        helperText={errors.password ? errors.password.message : ""}
                                        error={!!errors.password}
                                        fullWidth
                                    />
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                    {/*<a className={classes.btnFirst} onClick={() => history.push('/remind-password')}>*/}
                                    {/*  Forgot password*/}
                                    {/*</a>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!submitButtonEnable}
                                        disableElevation={true}
                                        onClick={handleSubmit}
                                    >
                                        {t("login_sigin")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    ) : (
                        <ConfirmationCodeField ref={ref} handleConfirmationCodeSubmit={handleConfirmationCodeSubmit}/>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default Login;
