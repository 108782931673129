import React from "react";
import { Button, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

const SliderToolbar = ({label = "",
                           labelButton = "",
                           buttonAction,
                           handleClone = () => {},
                           handleDelete = () => {},
                           cloneOrCopyButtonDisabled = false}) => {

    return (
        <>
            <Toolbar>
                <Typography fontWeight={"bold"}>{label}</Typography>
                <Divider sx={{ml: 5, mr: 3}} orientation="vertical" flexItem/>
                {cloneOrCopyButtonDisabled ? (
                    <>
                        <IconButton
                            color="default"
                            size="large"
                            disabled={cloneOrCopyButtonDisabled}
                        >
                            <ContentCopyIcon fontSize={"small"}/>
                        </IconButton>
                        <IconButton
                            color="default"
                            size="large"
                            disabled={cloneOrCopyButtonDisabled}
                        >
                            <DeleteIcon fontSize={"small"}/>
                        </IconButton>
                    </>
                    ) : (
                    <>
                        <Tooltip title={"Duplikuj"} placement="bottom" arrow={true}>
                            <IconButton
                                color="default"
                                onClick={handleClone}
                                size="large"
                                disabled={cloneOrCopyButtonDisabled}
                            >
                                <ContentCopyIcon fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Usuń"}>
                            <IconButton
                                color="default"
                                onClick={handleDelete}
                                size="large"
                                disabled={cloneOrCopyButtonDisabled}
                            >
                                <DeleteIcon fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                    </>
                    )
                }
                <Box sx={{flexGrow: 1}}/>
                {buttonAction && <Box sx={{display: {md: 'flex'}}}>
                    <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={buttonAction}
                    >
                        {labelButton}
                    </Button>
                </Box>}
            </Toolbar>
        </>
    );
}

export default SliderToolbar;

