//TODO fix this on production
const HOSTNAME = window && window.location && window.location.hostname;
const STATIC_ASSETS_URL="https://"+ HOSTNAME + "/assets"

export default function getImage(img) {
    return STATIC_ASSETS_URL + '/' + img;
}

export function getAssetsAddress(){
    return STATIC_ASSETS_URL;
}


