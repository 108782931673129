import React from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import EmailIcon from "@mui/icons-material/AlternateEmail";
import * as color from "assets/styles/jss/common/colors";

const CustomizedListItemVariable = ({ primaryText, secondaryText, handleClick}) => {

  return (
    <>
      <ListItem>
        <ListItemButton onClick={handleClick}>
          <ListItemAvatar>
            <Avatar sx={{ background: color.gray }}>
              <EmailIcon fontSize={"small"} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
            primary={primaryText}
            secondary={secondaryText} />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default CustomizedListItemVariable;

