export const HEAD_CELL_TYPES = {
  ACTION: "ACTION",
  LANGUAGE_ACTION: "LANGUAGE_ACTION",
  MINIATURE: "MINIATURE",
  INVOICE_NUMBER: "INVOICE_NUMBER",
  ATTACHMENT: "ATTACHMENT",
  MARKETING_CONSENT: "MARKETING_CONSENT",
  LANGUAGE_NAME: "LANGUAGE_NAME",
  DATA: "DATA",
  STATUS_INVERTED: "STATUS_INVERTED",
  STATUS: "STATUS",
  PRODUCT_TYPE: "PRODUCT_TYPE",
  INBOX: "INBOX",
  TRANSLATION: "TRANSLATION",
  MAP_STATUS: "MAP_STATUS",
};
