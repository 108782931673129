import Layout from "layout/Layout";
import React, { useEffect, useState } from "react";
import { Box, Grid, Menu, Paper, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ListIcon from "@mui/icons-material/List";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Article } from "@mui/icons-material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import { useTranslation } from "react-i18next";
import ShortcutsEditListDialog from "modules/Home/ShortcutsEditListDialog";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuItems, setCurrentSelectedLevelOne, setCurrentSelectedLevelZero } from "redux/slices/menu";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CircularProgress from "@mui/material/CircularProgress";
import { findMenu } from "utils/menu";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import WebIcon from "@mui/icons-material/Web";
import CategoryIcon from "@mui/icons-material/Category";
import CommentIcon from "@mui/icons-material/Comment";
import InboxIcon from "@mui/icons-material/Inbox";
import ShortcutIconMui from "@mui/icons-material/Shortcut";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import QuizIcon from "@mui/icons-material/Quiz";
import MenuIcon from "@mui/icons-material/Menu";
import PanoramaIcon from "@mui/icons-material/Panorama";
import ShortTextIcon from "@mui/icons-material/ShortText";
import PeopleIcon from "@mui/icons-material/People";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import UndoIcon from "@mui/icons-material/Undo";
import EmailIcon from "@mui/icons-material/Email";
import StoreIcon from "@mui/icons-material/Store";
import SchemaIcon from "@mui/icons-material/Schema";
import SmsIcon from "@mui/icons-material/Sms";
import DiscountIcon from "@mui/icons-material/Discount";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import LanguageIcon from "@mui/icons-material/Language";
import TranslateIcon from "@mui/icons-material/Translate";
import SettingsIcon from "@mui/icons-material/Settings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LinkIcon from "@mui/icons-material/Link";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

const endpoint = "/shortcuts/";

const toolbarItems = [
    {
        "name":"toolbar_button_add",
        "tooltip":"toolbar_button_add_tooltip",
        "icon":"",
        "action":"add_shortcut",
        "disabled":false,
        "sortOrder":0,
        "type": "BUTTON"
    }];

const ShortcutIcon = ({iconName, iconColor}) => {
    const color = {color : iconColor}
    const sx = {color}

    switch (iconName) {
        case "page":
            return <WebIcon sx={sx} fontSize="large"/>
        case "article":
            return <Article sx={sx} fontSize="large"/>
        case "category":
            return <CategoryIcon sx={sx} fontSize="large"/>
        case "comment":
            return <CommentIcon sx={sx} fontSize="large"/>
        case "inbox":
            return <InboxIcon sx={sx} fontSize="large"/>
        case "shortcut":
            return <ShortcutIconMui sx={sx} fontSize="large"/>
        case "media":
            return <PermMediaIcon sx={sx} fontSize="large"/>
        case "people":
            return <PeopleIcon sx={sx} fontSize="large"/>
        case "faq":
            return <QuizIcon sx={sx} fontSize="large"/>
        case "menu":
            return <MenuIcon sx={sx} fontSize="large"/>
        case "panorama":
            return <PanoramaIcon sx={sx} fontSize="large"/>
        case "contact":
            return <ContactMailIcon sx={sx} fontSize="large"/>
        case "question":
            return <HelpCenterIcon sx={sx} fontSize="large"/>
        case "back":
            return <UndoIcon sx={sx} fontSize="large"/>
        case "footer":
            return <ShortTextIcon sx={sx} fontSize="large"/>
        case "mail":
            return <EmailIcon sx={sx} fontSize="large"/>
        case "store":
            return <StoreIcon sx={sx} fontSize="large"/>
        case "schema":
            return <SchemaIcon sx={sx} fontSize="large"/>
        case "sms":
            return <SmsIcon sx={sx} fontSize="large"/>
        case "discount":
            return <DiscountIcon sx={sx} fontSize="large"/>
        case "person":
            return <PersonIcon sx={sx} fontSize="large"/>
        case "language":
            return <LanguageIcon sx={sx} fontSize="large"/>
        case "translate":
            return <TranslateIcon sx={sx} fontSize="large"/>
        case "settings":
            return <SettingsIcon sx={sx} fontSize="large"/>
        case "analytics":
            return <AnalyticsIcon sx={sx} fontSize="large"/>
        case "link":
            return <LinkIcon sx={sx} fontSize="large"/>
        case "sitemap":
            return <AccountTreeIcon sx={sx} fontSize="large"/>
        case "group":
            return <GroupIcon sx={sx} fontSize="large"/>
        case "list":
            return <ListIcon sx={sx} fontSize="large"/>
        case "credit_card":
            return <CreditCardIcon sx={sx} fontSize="large"/>
        case "receipt":
            return <ReceiptIcon sx={sx} fontSize="large"/>
        default:
            return <ArrowForwardIcon sx={sx} fontSize="large"/>
    }
}

const Shortcuts = () => {
    const history = useHistory();
    const { t } = useTranslation("common");
    const menuItems = useSelector(selectMenuItems);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [shortcuts, setShortcuts] = useState([]);
    const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);
    const [idItemToDel, setIdItemToDel] = useState(null);

    useEffect(() => {
        getShortcuts();
    }, [menuItems]);

    useEffect(() => {
        const {levelZero, levelOne} = findMenu(menuItems);
        dispatch(setCurrentSelectedLevelZero(levelZero));
        dispatch(setCurrentSelectedLevelOne(levelOne));
    }, [menuItems])

    const getShortcuts = () => {
        setIsLoading(true);
        backend.get(endpoint).then((res) => {
            if (isSuccess(res)) {
                if (res.data.length > 0) {
                    setShortcutsList(res.data);
                }
            }
            setIsLoading(false);
        });
    }

    const addShortcut = (shortcut) => {
        let nextSortOrder = 0;

        if(shortcuts?.length > 0) {
            nextSortOrder = shortcuts.sort((a, b) => a.sortOrder - b.sortOrder)[shortcuts.length - 1].sortOrder + 1;
        }

        const d = {shortcut : shortcut, sortOrder: nextSortOrder}
        backend.post(endpoint, d).then((res) => {
            if (isSuccess(res)) {
                getShortcuts();
                enqueueSnackbar(t("common_status_successfully"), {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
                    variant: "error",
                });
            }
        });
    }

    const setShortcutsList = (shortShortcuts) => {
        const newShortcut = [];
        for (const shortShortcut of shortShortcuts) {
            const {title, icon} = findTitleAndIconFromMenu(shortShortcut.shortcut);
            let fullShortcut = {
                id: shortShortcut.id,
                url: shortShortcut.shortcut,
                sortOrder: shortShortcut.sortOrder,
                title: title,
                icon: icon,
            }

            if(shortShortcut.shortcut.endsWith("/new")) {
                fullShortcut = {...fullShortcut, colorIcon: "orange"}
            } else {
                fullShortcut = {...fullShortcut, colorIcon: "blue"}
            }
            newShortcut.push(fullShortcut);
        }
        setShortcuts(newShortcut);
    }

    const findTitleAndIconFromMenu = (url) => {
        for (const mainMenuItem of menuItems) {
            for(const groupMenuItem of mainMenuItem.group) {
                for(const page of groupMenuItem.pages) {
                    if(url.includes(page.path)) {
                        return {title: page.title, icon: page.icon};
                    }
                }
            }
        }
        return '?'
    }

    const handleDelete = (idToDelete) => {
        setIdItemToDel(idToDelete);
        setOpenedRemoveDialog(true);
    }

    const handleDeleteFromDB = () => {
        backend.delete(endpoint + idItemToDel).then(
            (res) => deleteResponseFunction(res)
        );
        setOpenedRemoveDialog(false);
    }

    const deleteResponseFunction = (res) => {
        if (isSuccess(res)) {
            enqueueSnackbar(t("common_status_successfully"), {
                variant: "success",
            });
            if(shortcuts?.length > 1) {
                getShortcuts();
            } else {
                setShortcuts([]);
            }
        } else {
            enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
                variant: "error",
            });
        }
    }

    return <>
        <Layout
            header={t("shortcuts_label")}
            bottom={<EmptySection/>}
            top={<HeaderBuilder headerData={toolbarItems} actionAddShortcut={() => setAddDialogOpen(true)}/>}
        >
            {(isLoading || !menuItems || (Array.isArray(menuItems) && menuItems.length === 0)) ?
            (
                <CircularProgress/>
            )  : (
                <Grid container spacing={5}>
                    {shortcuts.map(shortcut => (
                        <Grid id={"grid"} item key={shortcut.id} sm={3} alignItems={"flex-end"}>
                            <Paper
                                id={"paper"}
                                sx={{pb: 10, cursor: 'pointer'}}
                                elevation={8}
                                onClick={() => history.push(shortcut.url)}
                            >
                                <Stack
                                    id={"stackTop"}
                                    direction={"row"}
                                    alignItems={"right"}
                                    justifyContent={"right"}
                                    sx={{p: 2}}
                                >
                                    <Box onClick={(e) => e.stopPropagation() }>
                                        <PopupState id={"menu"} variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <IconButton id={"menu-button"} variant="contained" {...bindTrigger(popupState)}>
                                                        <MoreVertIcon id={"menu-icon"}/>
                                                    </IconButton>
                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem id={"menu-item"} onClick={(e) => {
                                                            popupState.close(e)
                                                            handleDelete(shortcut.id)
                                                        }}>Usuń</MenuItem>
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </Box>
                                </Stack>
                                <Stack
                                    id={"stack"}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={4}
                                >
                                    <ShortcutIcon id={"shortcutIcon"} iconName={shortcut.icon} iconColor={shortcut.colorIcon}/>
                                    <Typography id={"shortcutText"} variant={"subtitle1"}>
                                        {shortcut.title}
                                    </Typography>
                                </Stack>
                            </Paper>
                        </Grid>))}
                </Grid>)}
        </Layout>
        <ConfirmationDialog
            open={openedRemoveDialog}
            close={() => setOpenedRemoveDialog(false)}
            confirm={handleDeleteFromDB}
        />
        <ShortcutsEditListDialog
            open={addDialogOpen}
            handleClose={() => setAddDialogOpen(false)}
            handleConfirm={addShortcut}
            menuItem={menuItems}
        />
    </>
};
export default Shortcuts;
