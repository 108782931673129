import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
    name: "theme",
    initialState: {
        currentTheme: localStorage.getItem('appTheme') || 'normal',
    },
    reducers: {
        themeSwitch: (state, action) => {
            const currentTheme = action.payload === true ? 'dark' : 'normal';
            localStorage.setItem('appTheme', currentTheme);
            state.currentTheme = currentTheme
        }
    },
});

export const selectTheme = (state) => state.theme.currentTheme;

export const {themeSwitch} = themeSlice.actions;

export default themeSlice.reducer;