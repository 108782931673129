import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { TableRow, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const RowTextField = ({row, headCells, handleSaveRow}) => {
    const {t} = useTranslation("common")

    const [newRow, setNewRow] = useState(row);

    useEffect(() => {
        setNewRow(row)
    }, [row])

    const handleChange = (newValue, hc) => {
        const newState = {...newRow, [hc.id]: newValue};

        setNewRow(newState);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSaveRow(newRow);
        }
    }


    return (
        <TableRow>
            {headCells.map((hc, i) =>
                <TableCell key={hc.id}>
                    <TextField
                        size="small"
                        name={hc.id}
                        placeholder={row[hc.id]}
                        value={newRow[hc.id]}
                        onChange={(e) => handleChange(e.target.value, hc)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                </TableCell>
            )}
        </TableRow>
    );
};


export default RowTextField;