import React, { useEffect, useState } from "react";
import DiscountCriteriaTable from "components/Edit/DiscountCode/DiscountCriteriaTable";
import { useFormContext } from "react-hook-form";
import { Button } from "@mui/material";

const defaultEmptyRule = {
    id: 0,
    productCriteria: 'ANY',
    match: 1,
    criteriaItemList: []
}

const ProductCriteria = ({typesCondition, mainListName, listName, mainOperatorName, matchName}) => {
    const {watch, setValue, getValues} = useFormContext();
    const watchProductCriteriaList = watch(mainListName);
    const [nextId, setNextId] = useState(-1);

    const addHandle = () => {
        const newItem = {...defaultEmptyRule, id : nextId}
        setNextId(prevState => prevState - 1);
        setValue(mainListName, [...watchProductCriteriaList, newItem ])
    }

    useEffect(() => {
        if(!watchProductCriteriaList) {
            setValue(mainListName, [defaultEmptyRule])
        }
    }, [])

    return (
        <>
            {
                watchProductCriteriaList?.map((rule, index) => (
                    <DiscountCriteriaTable
                        key={rule.id}
                        typesCondition={typesCondition}
                        listName={`${mainListName}.${index}.${listName}`}
                        mainOperatorName={`${mainListName}.${index}.${mainOperatorName}`}
                        matchName={`${mainListName}.${index}.${matchName}`}
                    />
                    )
                )
            }
            <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={addHandle}
            >
                Dodaj
            </Button>
        </>
    );
};

export default ProductCriteria;

