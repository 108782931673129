import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import DataTable from "components/Table/DataTable";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import getBackendHost from "utils/backendUrl";
import axios from "axios";

const AddOrderDialog = React.lazy(() => import("modules/ShopOrders/AddOrderDialog"));
const ReportDialog = React.lazy(() => import("modules/ShopOrders/ReportDialog"));

const toolbarItems = [
  {
    name: "toolbar_button_add",
    tooltip: "toolbar_button_add_tooltip",
    icon: "",
    action: "add_dialog",
    disabled: false,
    sortOrder: 0,
    type: "BUTTON",
  },
  {
    name: "toolbar_button_report",
    tooltip: "toolbar_button_report",
    icon: "",
    action: "order_report",
    disabled: false,
    sortOrder: 0,
    type: "BUTTON",
  },
  {
    name: "toolbar_icon_copy",
    tooltip: "toolbar_icon_duplicate_tooltip",
    icon: "ContentCopy",
    action: "copy",
    disabled: false,
    sortOrder: 1,
    type: "ICON",
  },
  {
    name: "toolbar_icon_del",
    tooltip: "toolbar_icon_del_tooltip",
    icon: "Delete",
    action: "delete",
    disabled: false,
    sortOrder: 2,
    type: "ICON",
  },
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
    searchFieldName: "orderNumber:like",
  },
];

const OrdersList = () => {
  const { t } = useTranslation("common");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [reportDialogIsOpen, setReportDialogIsOpen] = useState(false);
  const [getReportProgress, setGetReportProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const handleAdd = (item) => {
    backend.post("/order/add", item).then((res) => {
      if (isSuccess(res)) {
        console.log(res.data);
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        if (res.data.id != null) {
          history.push("/shop/orders/" + res.data.id);
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const generateReport = (reqView) => {
    setGetReportProgress(true);
    const url = getBackendHost() + "/order/report";

    const fromStr = reqView.from.format("YYYYMMDDHHmm");
    const toStr = reqView.to.format("YYYYMMDDHHmm");

    const fileName = `raport_${fromStr}-${toStr}.xlsx`;

    axios
      .post(url, reqView, {
        withCredentials: true,
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar("Raport wygenerowany", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Błąd podczas generowania raportu", {
          variant: "error",
        });
      })
      .finally(() => {
        setGetReportProgress(false);
      });
  };

  const handleOpenDialogAdd = () => {
    setDialogIsOpen(true);
  };

  const handleOpenDialogOrderReport = () => {
    setReportDialogIsOpen(true);
  };

  return (
    <Layout
      header={t("shop_orders_label")}
      bottom={<EmptySection />}
      top={
        <HeaderBuilder
          headerData={toolbarItems}
          actionAddDialog={handleOpenDialogAdd}
          orderReport={handleOpenDialogOrderReport}
        />
      }
    >
      {dialogIsOpen && (
        <Suspense fallback={<></>}>
          <AddOrderDialog close={() => setDialogIsOpen(false)} open={dialogIsOpen} confirm={handleAdd} />
        </Suspense>
      )}
      {reportDialogIsOpen && (
        <Suspense fallback={<></>}>
          <ReportDialog
            close={() => setReportDialogIsOpen(false)}
            open={reportDialogIsOpen}
            confirm={generateReport}
            getReportProgress={getReportProgress}
          />
        </Suspense>
      )}
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable modulePath={"/shop/orders"} endpoint={"/order/"} />
      </Paper>
    </Layout>
  );
};
export default OrdersList;
