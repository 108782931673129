import styles from "./styles";
import { ListItem, SvgIcon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MuiMenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import SettingsIcon from "@mui/icons-material/Settings";
import { MENU_ICON_TYPES } from "../../../enums/menuIconTypes";

const useStyles = makeStyles(styles);

const MenuItem = ({item, show, index, onClickedItem}) => {
    const classes = useStyles();

    const markItem = () => {
        onClickedItem(index);
    };

    const selectIcon = (typeIcon) => {
        switch (typeIcon) {
            case MENU_ICON_TYPES.HOME_ICON:
                return HomeIcon;
            case MENU_ICON_TYPES.DESCRIPTION_ICON:
                return DescriptionIcon;
            case MENU_ICON_TYPES.SHOPPING_CART_ICON:
                return ShoppingCartIcon;
            case MENU_ICON_TYPES.FLASH_ON_ICON:
                return FlashOnIcon;
            case MENU_ICON_TYPES.SETTINGS_ICON:
                return SettingsIcon
            default:
                return MuiMenuIcon
        }
    }

    return (
        <ListItem onClick={markItem} index={index} className={clsx(classes.category, show === index && classes.clicked)}>
            <div className={classes.imgWrapper}>
                <SvgIcon index={index} component={selectIcon(item.icon)} className={classes.img} inheritViewBox/>
            </div>
            <span className={classes.name}>{item.name}</span>
        </ListItem>
    );
};

export default MenuItem;