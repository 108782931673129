import React, { memo, useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { InputLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import * as color from "assets/styles/jss/common/colors";
import MediaDialog from "modules/CMSResources/MediaDialog";

const MEDIA_FOLDER = "/cmsstatic";

const Wysiwyg = ({ label, name, setInitEditor = () => {}, simpleEditor }) => {
  const { control } = useFormContext();
  const [editor, setEditor] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);
  const [imageAlt, setImageAlt] = useState(null);

  const [openDialogSelectImage, setOpenDialogSelectImage] = useState(false);

  useEffect(() => {
    if (imageSrc != null) {
      let img = '<img src="' + MEDIA_FOLDER + imageSrc + '"';
      if (imageAlt) {
        img += ' alt="' + imageAlt + '"';
      }
      img += ">";

      editor.s.insertHTML(img);

      setImageSrc(null);
      setImageAlt(null);
    }
  }, [imageSrc]);

  const openDialog = (edit) => {
    setEditor(edit);
    setOpenDialogSelectImage(true);
  };

  const imageButton = {
    name: "select image",
    icon: "image",
    tooltip: "select image",
    exec: function (edit) {
      openDialog(edit);
    },
  };

  const cfgFragment = simpleEditor
    ? {
        buttons: ["source", "undo", "redo", "|", "paragraph", "|", "bold", "italic", "|", "link", "|"],
        minHeight: 120,
        limitChars: 120,
        placeholder: "1 zdanie, maksymalnie 120 znaków",
      }
    : {
        buttons: [
          "source",
          "|",
          "undo",
          "redo",
          "|",
          "font",
          "fontsize",
          "brush",
          "paragraph",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "eraser",
          "|",
          "left",
          "center",
          "right",
          "justify",
          "|",
          "indent",
          "outdent",
          "|",
          "ul",
          "ol",
          "|",
          imageButton,
          "link",
          "table",
        ],
        minHeight: 256,
      };

  //source - change mode
  const defaultConfig = {
    spellcheck: false,
    language: "pl",
    enter: "DIV",
    defaultMode: "1",
    toolbarAdaptive: false,
    readonly: false,
    style: {
      background: color.white,
      color: color.black,
    },
    events: {
      afterInit: (instance) => {
        setInitEditor(instance);
      },
    },
    ...cfgFragment,
  };

  const [config, setConfig] = useState(defaultConfig);

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <JoditEditor
            value={value}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => {
              onChange(newContent);
            }} // preferred to use only this option to update the content for performance reasons
          />
        )}
      />
      {openDialogSelectImage && (
        <MediaDialog
          open={openDialogSelectImage}
          close={() => setOpenDialogSelectImage(false)}
          setImage={setImageSrc}
          setImageAlt={setImageAlt}
        />
      )}
    </>
  );
};

export default memo(Wysiwyg);
