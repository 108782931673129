import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import HeaderButton from "./HeaderButton";
import HeaderIcon from "./HeaderIcon";
import HeaderTextFieldsSearch from "./HeaderTextFieldsSearch";
import { HEADER_ITEM_TYPES } from "../../enums/headerItemTypes";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import * as color from "assets/styles/jss/common/colors";
import {
  selectCloneOrCopyButtonDisabled,
  setListCloneDialogOpen,
  setListDeleteDialogOpen,
} from "redux/slices/materials";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, setCurrentLanguageCode } from "redux/slices/language";

const useStyles = makeStyles(styles);

const HeaderBuilder = ({
  isEditViewActive = false,
  headerData,
  actionAddDialog = () => {},
  actionExportDialog = () => {},
  actionAddShortcut = () => {},
  orderReport = () => {},
  moduleUrl,
  noBackButton,
  handleDelete,
  noDeleteButton,
  deleteButtonTitle,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchFieldsItem, setSearchFieldsItem] = useState(null);
  const [languageSelectItem, setLanguageSelectItem] = useState(null);
  const [exportItem, setExportItem] = useState(null);
  const [isIconPresent, setIsIconPresent] = useState(false);

  useEffect(() => {
    if (Array.isArray(headerData)) {
      setSearchFieldsItem(headerData.find((element) => element.type === HEADER_ITEM_TYPES.FIELDS_SEARCH));
      setLanguageSelectItem(headerData.find((element) => element.type === HEADER_ITEM_TYPES.SELECT_LANGUAGE));
      setExportItem(headerData.find((element) => element.type === HEADER_ITEM_TYPES.BUTTON_EXPORT));
      setIsIconPresent(headerData.some((element) => element.type === HEADER_ITEM_TYPES.ICON));
    }
  }, [headerData]);

  const actionAdd = () => {
    changeLanguage("pl").then((r) => {
      if (r === true) {
        dispatch(setCurrentLanguageCode("pl"));
        history.push(moduleUrl + "/new");
      }
    });
  };

  const choiceActionButton = (item) => {
    switch (item.action) {
      case "add":
        return actionAdd(item);
      case "add_dialog":
        return actionAddDialog(item);
      case "export_dialog":
        return actionExportDialog(item);
      case "add_shortcut":
        return actionAddShortcut(item);
      case "order_report":
        return orderReport(item);
      case "copy":
        return dispatch(setListCloneDialogOpen(true));
      case "delete":
        return dispatch(setListDeleteDialogOpen(true));
      default:
        return console.log(item.action);
    }
  };

  return (
    <Toolbar>
      {/* to left */}
      {isEditViewActive && !noBackButton && (
        <Button
          variant={"outlined"}
          size={"small"}
          color="primary"
          startIcon={<ArrowBackIosNewIcon sx={{ color: color.primary }} />}
          onClick={() => history.push(moduleUrl)}
        >
          Powrót
        </Button>
      )}
      {Array.isArray(headerData) &&
        headerData.map((item, i) => (
          <Fragment key={i}>
            {item.type === HEADER_ITEM_TYPES.BUTTON && (
              <HeaderButton key={i} itemData={item} action={() => choiceActionButton(item)} />
            )}
          </Fragment>
        ))}
      {isIconPresent && <Divider className={classes.divider} orientation="vertical" flexItem />}
      {Array.isArray(headerData) &&
        headerData.map((item, i) => (
          <Fragment key={i}>
            {item.type === HEADER_ITEM_TYPES.ICON && (
              <HeaderIcon
                key={i}
                itemData={item}
                action={() => choiceActionButton(item)}
                disabled={useSelector(selectCloneOrCopyButtonDisabled)}
              />
            )}
          </Fragment>
        ))}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { md: "flex" } }}>
        {/* to right */}
        {exportItem && <HeaderButton itemData={exportItem} action={() => choiceActionButton(exportItem)} />}
        {/*Moded to title*/}
        {/*{languageSelectItem && <HeaderSelectLanguage itemData={languageSelectItem} />}*/}
        {searchFieldsItem && <HeaderTextFieldsSearch itemData={searchFieldsItem} />}
        {!noDeleteButton && handleDelete && (
          <Button variant="outlined" color="default" onClick={handleDelete} sx={{ color: color.red }}>
            {`${deleteButtonTitle ? deleteButtonTitle : "Usuń"}`}
          </Button>
        )}
      </Box>
    </Toolbar>
  );
};

export default HeaderBuilder;
