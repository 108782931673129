import moment from "moment";
import "moment/locale/pl";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { InputLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

/* for useForm yup type date!! */
/* validFrom: Yup.date().typeError(t("form-validation-date")).nullable() */

function CustomizedDateTimePickerMuiValidLocalTimeToUtc({ label, name = "x", disabled = false, defaultEmpty = false }) {
  moment.locale("pl");
  const { control } = useFormContext();
  const handleChange = (value, onChange) => {
    if (value) {
      onChange(value);
    } else {
      onChange(null);
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultEmpty ? "" : moment().format("yyyy-MM-DDTHH:mm")}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <InputLabel htmlFor={name}>{label}</InputLabel>
              <DateTimePicker
                inputFormat={"yyyy-MM-DD HH:mm"}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    fullWidth
                    size="small"
                    helperText={formState?.errors[name] ? formState?.errors[name].message : ""}
                    error={!!formState?.errors[name]}
                  />
                )}
                readOnly={disabled}
                value={value}
                onChange={(v) => handleChange(v, onChange)}
              />
            </LocalizationProvider>
          </>
        )}
      />
    </>
  );
}

export default memo(CustomizedDateTimePickerMuiValidLocalTimeToUtc);
