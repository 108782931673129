import { Grid, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import backend from "api/backend";

const endpoint = "/language/";
const title = "settings_language_edit_label";
const moduleUrl = "/settings/languages/";

const LanguageEdit = () => {
    const {id} = useParams();
    const current = useSelector(selectEditItem);
    const {t} = useTranslation("common");

    const validationSchema = Yup.object({
        languageCode: Yup.string().trim().required(t("form-validation-required")),
        active: Yup.bool().required(t("form-validation-required")),
    });

    const refForm = useRef(null);

    return (
        <>
            <EditViewNoLanguage
                endpoint={endpoint}
                title={title}
                moduleUrl={moduleUrl}
                validationSchema={validationSchema}
                refForm={refForm}
                noDeleteButton
                disableToolbar
            >
                <FormData id={id} current={current} refForm={refForm} />
            </EditViewNoLanguage>
        </>
    );
};

const FormData = ({id, current, refForm}) => {
    const {t} = useTranslation("common");

    const [langs, setLangs] = useState([{id: "pl", displayName: "Polski"}])

    useEffect(async () => {
        const res = await backend.get(`/language/all-available`);
        if (res.status === 200) {
            const arr = res.data.map(e => ({id: e.code, displayName: e.name}))
            console.log(arr)
            setLangs(arr);
        }
    }, [])

    return <>
        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                    <CustomizedSelect
                        label={"Język"}
                        name={"languageCode"}
                        items={langs}
                        defaultValue={'pl'}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedSwitch
                        name={"active"}
                        rightLabel={"Aktywny"}
                        topLabel={<>&nbsp;</>}
                    />
                </Grid>
            </Grid>
        </Paper>
    </>;
};

export default LanguageEdit;
