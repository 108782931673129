import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import DataTable from "components/Table/DataTable";

const toolbarItems = [
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 1,
    type: "FIELDS_SEARCH",
    searchFieldName: "url:like",
  },
];

const SitemapList = () => {
  const { t } = useTranslation("common");

  return (
    <Layout
      header={t("settings_sitemap_label")}
      bottom={<EmptySection />}
      top={<HeaderBuilder headerData={toolbarItems} moduleUrl={"/settings/sitemap"} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable isClickable={false} modulePath={"/settings/sitemap"} endpoint={"/sitemap/"} />
      </Paper>
    </Layout>
  );
};
export default SitemapList;
