import moment from "moment";
import "moment/locale/pl";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./datePicker.css";

function CustomSingleDatePicker({
  placeholder,
  date,
  setDate,
  rest,
  restrict = false,
  restrictDate = moment(),
  onlyTodayAndTomorrowMode = false,
}) {
  moment.locale("pl");
  const [focused, setFocused] = useState();
  const twoMonthBeforeAndOneAfter = (day) =>
    day.isBefore(restrictDate) || day.isAfter(moment(restrictDate).endOf("month"));
  const todayAndTomorrow = (day) => day.isBefore(restrictDate) || day.isAfter(moment(restrictDate).add(1, "day"));
  return (
    <SingleDatePicker
      placeholder={placeholder}
      date={date}
      onDateChange={(date) => setDate(date)}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      small={true}
      noBorder
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      isOutsideRange={(day) =>
        restrict ? (onlyTodayAndTomorrowMode ? todayAndTomorrow(day) : twoMonthBeforeAndOneAfter(day)) : false
      }
      {...rest}
    />
  );
}

export default CustomSingleDatePicker;
