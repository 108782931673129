import { Button, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import BoxMainPage from "components/Settings/Box/BoxMainPage";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useFieldArray, useFormContext } from "react-hook-form";

const name = "boxes";

const BoxesMainPage = () => {
  const { control, getValues, setValue } = useFormContext();
  const [nextId, setNextId] = useState(-1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { fields, append, remove } = useFieldArray({
    name: name,
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: "idUseField",
  });

  const handleAddBox = () => {
    let nextSortOrder = 0;
    if (fields?.length > 0) {
      nextSortOrder = fields[fields.length - 1].sortOrder + 1;
    }
    const newItem = { id: nextId, title: "", content: "", icon: "", sortOrder: nextSortOrder };
    append(newItem);

    setNextId((prev) => {
      return prev - 1;
    });
  };

  const handleDeleteGroup = () => {
    const index = fields.findIndex((object) => {
      return object.id === itemToDelete.id;
    });

    remove(index);

    setItemToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleUp = (item, index) => {
    let arr = getValues(name);

    if (arr && arr.length > 0 && index > 0) {
      const tmp = arr[index - 1];
      arr[index - 1] = arr[index];
      arr[index] = tmp;
      arr = setSortOrder(arr, "sortOrder");
      setValue(name, [...arr]);
    }
  };
  const handleDown = (item, index) => {
    let arr = getValues(name);

    if (arr && arr.length > 0 && index < arr.length - 1) {
      const tmp = arr[index + 1];
      arr[index + 1] = arr[index];
      arr[index] = tmp;
      arr = setSortOrder(arr, "sortOrder");
      setValue(name, [...arr]);
    }
  };

  const setSortOrder = (array, sortOrderfieldName) => {
    let order = 0;
    for (const el of array) {
      el[sortOrderfieldName] = order;
      order++;
    }
    return [...array];
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent="flex-end">
            <Button variant="contained" color="secondary" size="small" onClick={handleAddBox}>
              Dodaj
            </Button>
          </Stack>
        </Grid>
        {fields.map((item, index) => (
          <BoxMainPage
            index={index}
            isLastElement={index === fields.length - 1}
            key={item.id}
            item={item}
            name={`${name}.${index}`}
            handleUp={handleUp}
            handleDown={handleDown}
            handleDelete={(idToDel) => {
              setItemToDelete(idToDel);
              setDeleteDialogOpen(true);
            }}
          />
        ))}
      </Grid>
      <ConfirmationDialog
        open={deleteDialogOpen}
        close={() => {
          setItemToDelete(null);
          setDeleteDialogOpen(false);
        }}
        confirm={handleDeleteGroup}
      />
    </>
  );
};

export default BoxesMainPage;
