import { alpha, createTheme } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import * as font from "assets/styles/jss/common/fonts";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { tableCellClasses } from "@mui/material";

const theme = createTheme(({
    breakpoints: {
        values: {
            xxs: 0,
            xs: 375,
            sm: 760,
            md: 960,
            lg: 1280,
            xl: 1400,
        },
    },
    spacing: 4,
    borderRadius: 4,
    palette: {
        primary: {
            main: color.primary,
            contrastText: color.white,
        },
        secondary: {
            main: color.secondary,
            contrastText: color.white,
        },
        default: {
            main: alpha(color.black, 0.4),
            contrastText: color.white,
        },
        info: {
            main: color.blue,
            contrastText: color.white,
        },
        success: {
            main: color.green,
            contrastText: color.white,
        },
        warning: {
            main: color.orange,
            contrastText: color.white,
        },
        error: {
            main: color.red,
            contrastText: color.white,
        },
        text: {
            primary: color.black,
            secondary: color.darkGray,
            disabled: color.darkGray,
            hint: color.gray,
        },
        divider: alpha(color.primary, 0.15),
        background: {
            paper: color.white,
            default: color.lightGray,
        },
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 0 0 rgb(0 0 0 / 10%), 0px 1px 0px",
                    paddingBottom: "10px",
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(color.black, 0.5),
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "uppercase",
                    borderRadius: 64,
                    paddingLeft: "4vh !important",
                    paddingRight: "4vh !important",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "10px",
                    padding: "4vh",
                    boxSizing: "content-box",
                    "@media (max-width: 960px)": {
                        padding: "20px",
                        margin: "10px",
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {padding: "0"},
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "0 !important",
                    marginBottom: "2vh !important",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: "2vh 0",
                },
            },
        },
        MuiFormLabel:{
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                    marginBottom: 6,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                button: {
                    "&:hover": {
                        backgroundColor: alpha(color.primary, 0.05),
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: alpha(color.primary, 0.05),
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: alpha(color.primary, 0.05),
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "0.88rem",
                    backgroundColor: alpha(color.primary, 0.05),
                    borderRadius: 8,
                },
                adornedStart: {
                    padding: "0 10px",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    fontSize: "0.95rem",
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: alpha(color.primary, 0.15),
                        transition: "0.3s border-color",
                    },
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: alpha(color.primary, 0.4),
                        transition: "0.3s border-color",
                    },
                    [`&$disabled .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: color.gray,
                    },
                    [`&$focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: alpha(color.primary, 1),
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 20,
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: color.blue + "!important",
                    "&$checked": {
                        color: color.blue + "!important",
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    backgroundColor: color.lightGray,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 14,
                    paddingRight: 14,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: alpha(color.black, 0.4),
                },
                colorSecondary: {
                    color: alpha(color.black, 0.7) + " !important",
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                thumb: {
                    boxShadow: "0px 3px 3px -2px rgba(164, 176, 204, 0.5),0px 3px 4px 0px rgba(164, 176, 204, 0.5),0px 1px 8px 0px rgba(164, 176, 204, 0.5)",
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                [`&.${tableCellClasses.head}`]: {
                    backgroundColor: color.black,
                    color: color.white,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    cursor: "pointer",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: "bold",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: color.white,
                    color: color.black,
                    marginBottom: 20,
                    padding: "2px 25px",
                    minHeight: 36,
                    '&:first-of-type': {
                        borderRadius: "50px 0 0 50px",
                        paddingLeft: "30px",
                    },
                    '&:last-child': {
                        borderRadius: "0 50px 50px 0",
                        paddingRight: "30px",
                    },
                    '&.Mui-selected': {
                        backgroundColor: alpha(color.primary, 0.15),
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: "none",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "45px !important",
                    padding: "0 !important",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "0.7rem",
                    color: color.white,
                    backgroundColor: alpha(color.black, 0.8),
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 4,
                    paddingBottom: 4,
                    border: 0,
                },
                arrow: {
                    color: alpha(color.black, 0.8),
                }
            },
        },
    },
    typography: {
        htmlFontSize: 10,
        fontFamily: '"Red Hat Display", sans-serif',
        fontSize: 10,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "1.7rem",
            lineHeight: 1.4,
            letterSpacing: 0,
            color: color.black,
        },
        h2: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "1.5rem",
            lineHeight: 1.4,
            letterSpacing: 0,
            color: color.black,
        },
        h3: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "1.4rem",
            lineHeight: 1.3,
            letterSpacing: 0,
            color: color.black,
        },
        h4: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "1.2rem",
            lineHeight: 1.2,
            letterSpacing: "0.00735em",
            color: color.black,
        },
        h5: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "1.25rem",
            lineHeight: 1.1,
            letterSpacing: 0,
            color: color.black,
        },
        h6: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "0.90rem",
            lineHeight: 1.42,
            letterSpacing: "0.0075em",
            color: color.black,
            marginBottom: 10,
        },
        subtitle1: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.regular,
            fontSize: "1.1rem",
            lineHeight: 1.25,
            letterSpacing: 0,
            color: color.black,
        },
        subtitle2: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.regular,
            fontSize: "1.05rem",
            lineHeight: 1.45,
            letterSpacing: 0,
            color: color.black,
        },
        body1: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.regular,
            fontSize: "1rem",
            lineHeight: 1.54,
            letterSpacing: "0.00938em",
            color: color.black,
        },
        body2: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.regular,
            fontSize: "0.90rem",
            lineHeight: 1.42,
            letterSpacing: "0.01071em",
            color: color.black,
        },
        button: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.bold,
            fontSize: "0.9rem",
            lineHeight: 1.75,
            letterSpacing: "-0.02857em",
            textTransform: "initial",
        },
        caption: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.regular,
            fontSize: "0.8rem",
            lineHeight: 1.4,
            letterSpacing: "0",
            color: color.black,
        },
        overline: {
            fontFamily: '"Red Hat Display", sans-serif',
            fontWeight: font.regular,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
            color: color.black,
        },
    },
    zIndex: {
        mobileStepper: 1000,
        fab: 1050,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
    shadows: [
        "none",
        "0px 2px 1px -1px rgba(164, 176, 204, 0.08),0px 1px 1px 0px rgba(164, 176, 204, 0.08),0px 1px 3px 0px rgba(164, 176, 204, 0.08)",
        "0px 3px 1px -2px rgba(164, 176, 204, 0.08),0px 2px 2px 0px rgba(164, 176, 204, 0.08),0px 1px 5px 0px rgba(164, 176, 204, 0.08)",
        "0px 3px 3px -2px rgba(164, 176, 204, 0.08),0px 3px 4px 0px rgba(164, 176, 204, 0.08),0px 1px 8px 0px rgba(164, 176, 204, 0.08)",
        "0px 2px 4px -1px rgba(164, 176, 204, 0.08),0px 4px 5px 0px rgba(164, 176, 204, 0.08),0px 1px 10px 0px rgba(164, 176, 204, 0.08)",
        "0px 3px 5px -1px rgba(164, 176, 204, 0.08),0px 5px 8px 0px rgba(164, 176, 204, 0.08),0px 1px 14px 0px rgba(164, 176, 204, 0.08)",
        "0px 3px 5px -1px rgba(164, 176, 204, 0.08),0px 6px 10px 0px rgba(164, 176, 204, 0.08),0px 1px 18px 0px rgba(164, 176, 204, 0.08)",
        "0px 4px 5px -2px rgba(164, 176, 204, 0.08),0px 7px 10px 1px rgba(164, 176, 204, 0.08),0px 2px 16px 1px rgba(164, 176, 204, 0.08)",
        "0px 5px 5px -3px rgba(164, 176, 204, 0.08),0px 8px 10px 1px rgba(164, 176, 204, 0.08),0px 3px 14px 2px rgba(164, 176, 204, 0.08)",
        "0px 5px 6px -3px rgba(164, 176, 204, 0.08),0px 9px 12px 1px rgba(164, 176, 204, 0.08),0px 3px 16px 2px rgba(164, 176, 204, 0.08)",
        "0px 6px 6px -3px rgba(164, 176, 204, 0.08),0px 10px 14px 1px rgba(164, 176, 204, 0.08),0px 4px 18px 3px rgba(164, 176, 204, 0.08)",
        "0px 6px 7px -4px rgba(164, 176, 204, 0.08),0px 11px 15px 1px rgba(164, 176, 204, 0.08),0px 4px 20px 3px rgba(164, 176, 204, 0.08)",
        "0px 7px 8px -4px rgba(164, 176, 204, 0.08),0px 12px 17px 2px rgba(164, 176, 204, 0.08),0px 5px 22px 4px rgba(164, 176, 204, 0.08)",
        "0px 7px 8px -4px rgba(164, 176, 204, 0.08),0px 13px 19px 2px rgba(164, 176, 204, 0.08),0px 5px 24px 4px rgba(164, 176, 204, 0.08)",
        "0px 7px 9px -4px rgba(164, 176, 204, 0.08),0px 14px 21px 2px rgba(164, 176, 204, 0.08),0px 5px 26px 4px rgba(164, 176, 204, 0.08)",
        "0px 8px 9px -5px rgba(164, 176, 204, 0.08),0px 15px 22px 2px rgba(164, 176, 204, 0.08),0px 6px 28px 5px rgba(164, 176, 204, 0.08)",
        "0px 8px 10px -5px rgba(164, 176, 204, 0.08),0px 16px 24px 2px rgba(164, 176, 204, 0.08),0px 6px 30px 5px rgba(164, 176, 204, 0.08)",
        "0px 8px 11px -5px rgba(164, 176, 204, 0.08),0px 17px 26px 2px rgba(164, 176, 204, 0.08),0px 6px 32px 5px rgba(164, 176, 204, 0.08)",
        "0px 9px 11px -5px rgba(164, 176, 204, 0.08),0px 18px 28px 2px rgba(164, 176, 204, 0.08),0px 7px 34px 6px rgba(164, 176, 204, 0.08)",
        "0px 9px 12px -6px rgba(164, 176, 204, 0.08),0px 19px 29px 2px rgba(164, 176, 204, 0.08),0px 7px 36px 6px rgba(164, 176, 204, 0.08)",
        "0px 10px 13px -6px rgba(164, 176, 204, 0.08),0px 20px 31px 3px rgba(164, 176, 204, 0.08),0px 8px 38px 7px rgba(164, 176, 204, 0.08)",
        "0px 10px 13px -6px rgba(164, 176, 204, 0.08),0px 21px 33px 3px rgba(164, 176, 204, 0.08),0px 8px 40px 7px rgba(164, 176, 204, 0.08)",
        "0px 10px 14px -6px rgba(164, 176, 204, 0.08),0px 22px 35px 3px rgba(164, 176, 204, 0.08),0px 8px 42px 7px rgba(164, 176, 204, 0.08)",
        "0px 11px 14px -7px rgba(164, 176, 204, 0.08),0px 23px 36px 3px rgba(164, 176, 204, 0.08),0px 9px 44px 8px rgba(164, 176, 204, 0.08)",
        "0px 11px 15px -7px rgba(164, 176, 204, 0.08),0px 24px 38px 3px rgba(164, 176, 204, 0.08),0px 9px 46px 8px rgba(164, 176, 204, 0.08)",
    ],
}));
export default theme;
