import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogTextField from "components/Edit/Dialog/DialogTextField";
import * as color from "assets/styles/jss/common/colors";

const AddFrequentlySearchedsDialog = ({ open, handleClose, handleRemove, handleConfirm, itemToEdit }) => {
  const { t } = useTranslation("common");
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (itemToEdit) {
      setName(itemToEdit.name);
      setUrl(itemToEdit.url);
    } else {
      resetValue();
    }
  }, [itemToEdit, open]);

  const testNoEmptyValue = (name, value) => {
    if (!value || value === "") {
      setErrors((prevState) => ({
        ...prevState,
        [name]: { message: t("form-validation-required") },
      }));
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    setErrors({});

    const isErrorName = testNoEmptyValue("name", name);
    const isErrorUrl = testNoEmptyValue("url", url);

    if (!isErrorName && !isErrorUrl) {
      setErrors({});

      let values = {
        name: name,
        url: url,
      };

      if (itemToEdit) {
        values = { ...values, id: itemToEdit.id };
      }

      resetValue();
      handleConfirm(values);
    }
  };

  const resetValue = () => {
    setErrors({});
    setName("");
    setUrl("");
  };

  const handleDeleteItem = () => {
    if (itemToEdit) {
      handleRemove(itemToEdit);
    }

    resetValue();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              Dodaj Frazę
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DialogTextField label={"Fraza"} name={"name"} errors={errors} value={name} setValue={setName} />
          </Grid>
          <Grid item xs={12}>
            <DialogTextField label={"Url"} name={"url"} errors={errors} value={url} setValue={setUrl} />
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={6}>
            <Button variant="outlined" color="default" sx={{ color: color.red }} onClick={handleDeleteItem}>
              Usuń
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Zapisz
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default AddFrequentlySearchedsDialog;
