import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import DialogSelect from "components/Edit/Dialog/DialogSelect";
import * as color from "assets/styles/jss/common/colors";

const exportTypes = [
    {
        id: 'PHONE',
        displayName: "Eksport zgód marketingowych telefonicznych"
    },
    {
        id: 'EMAIL',
        displayName: "Eksport zgód marketingowych mailowych"
    },
    {
        id: 'ALL',
        displayName: "Eksport wszystkich zgód marketingowych"
    }
];

const ExportConsentsDialog = ({open, handleClose, handleExport}) => {
    const {t} = useTranslation("common");
    const dispatch = useDispatch();

    const [selectExportType, setSelectExportType] = useState(exportTypes?.length > 0 ? exportTypes[0].id : '');


    const handleSubmit = () => {
        handleExport(selectExportType);
        handleClose();
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Eksportuj</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogSelect
                        label={"Rodzaj eksportu"}
                        name={"exportType"}
                        value={selectExportType}
                        setValue={setSelectExportType}
                        items={exportTypes}
                        errors={{}}
                    />
                </Grid>
            </Grid>

            <Grid container sx={{mt: 5}}>
                <Grid item xs={6}>
                    <Button variant="outlined" color="default" sx={{color: color.red}} onClick={handleClose}>
                        Zamknij
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Eksportuj
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </>;
};
export default ExportConsentsDialog
