import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import SliderToolbar from "components/Edit/Slider/SliderToolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Typography } from "@mui/material";
import AddFrequentlySearchedsDialog from "components/Edit/Configuration/AddFrequentlySearchedsDialog";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import { RowDnDFrequentlySearched } from "components/Edit/DnD/RowDnDFrequentlySearched";

const MAX_SHORT_LIST = 5;

const FrequentlySearchedPhrazePanel = ({ label, name }) => {
  const { control, setValue, getValues } = useFormContext();
  const { fields, append } = useFieldArray({
    name: name,
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: "idUseField",
  });
  const [listCheckedItem, setListCheckedItem] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [cloneOrCopyButtonDisabled, setCloneOrCopyButtonDisabled] = useState(true);
  const [newItemId, setNewItemId] = useState(-1);
  const [itemToEdit, setItemToEdit] = useState(null);

  const [isMore, setIsMore] = useState(false);

  useEffect(() => {
    if (listCheckedItem.length > 0) {
      setCloneOrCopyButtonDisabled(false);
    } else {
      setCloneOrCopyButtonDisabled(true);
    }
  }, [listCheckedItem]);

  const handleCheched = (e, id) => {
    const checkboxChecked = e.target.checked;
    if (checkboxChecked) {
      setListCheckedItem((prevState) => {
        setListCheckedItem([...prevState, id]);
      });
    } else {
      const tmpArray = listCheckedItem.filter((item) => {
        return item !== id;
      });
      setListCheckedItem(tmpArray);
    }
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const fields = getValues(name);
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue(name, newFields);
  };

  const handleDelete = () => {
    //del item from edit dialog
    if (itemToDelete) {
      const index = fields.findIndex((object) => {
        return object.id === itemToDelete.id;
      });

      //remove(index); //slow
      fields.splice(index, 1);
      setValue(name, fields);

      setListCheckedItem([]);
      setItemToDelete(null);
      setDeleteDialogOpen(false);

      return;
    }

    //del checked items
    for (const item of listCheckedItem) {
      const index = fields.findIndex((object) => {
        return object.id === item;
      });

      fields.splice(index, 1);
    }

    setValue(name, fields);

    setListCheckedItem([]);
    setDeleteDialogOpen(false);
  };

  const handleCanelDelete = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  const handleClone = () => {
    let newId = newItemId;
    const itemsToAdd = [];
    //let nextOrder = (fields?.length > 0) ? fields[fields.length -1].order + 1 : 0;

    for (const item of listCheckedItem) {
      const itemToClone = fields.find((object) => {
        return object.id === item;
      });

      const itemToAdd = Object.assign({}, itemToClone);

      itemToAdd.id = newId;
      newId -= 1;
      // itemToAdd.order = nextOrder;
      // nextOrder += 1;
      itemToAdd.name = "Kopia - " + itemToAdd.name;
      itemsToAdd.push(itemToAdd);
    }

    //append(itemsToAdd); //slow
    fields.push(...itemsToAdd);
    setValue(name, fields);

    setNewItemId(newId);
    setListCheckedItem([]);
    setCloneDialogOpen(false);
  };

  const handleAddOrEditItem = (item) => {
    setAddDialogOpen(false);

    if (Object.hasOwn(item, "id")) {
      setItemToEdit(null);
      const indexToUpdate = fields.findIndex((object) => {
        return object.id === item.id;
      });
      console.log(item);
      fields[indexToUpdate] = item;
      setValue(name, fields);
      //update(indexToUpdate, item); //slow
    } else {
      //append({...item, id: newItemId, order: nextOrder}); //slow
      fields.push({ ...item, id: newItemId });
      setValue(name, fields);

      setNewItemId((prevState) => prevState - 1);
    }
  };

  const handleEditOpenDialog = (id) => {
    setItemToEdit(
      fields.find((object) => {
        return object.id === id;
      }),
    );
    setAddDialogOpen(true);
  };

  const handleRemoveFromEditDialog = (item) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  return (
    <>
      <SliderToolbar
        label={label}
        labelButton={"Dodaj"}
        buttonAction={() => setAddDialogOpen(true)}
        handleClone={() => setCloneDialogOpen(true)}
        handleDelete={() => setDeleteDialogOpen(true)}
        cloneOrCopyButtonDisabled={cloneOrCopyButtonDisabled}
      />
      {!fields?.length > 0 ? (
        <Typography>Brak elementów</Typography>
      ) : (
        <>
          <Table sx={{ pb: 0 }}>
            <TableBody>
              <DndProvider backend={HTML5Backend}>
                {fields?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMore) && (
                      <RowDnDFrequentlySearched
                        key={item.id}
                        id={item.id}
                        handleMoveItem={moveItem}
                        index={index}
                        primaryText={item.name}
                        secondaryText={item.url}
                        handleCheched={handleCheched}
                        listCheckedItem={listCheckedItem}
                        handleEditOpenDialog={handleEditOpenDialog}
                      />
                    ),
                )}
              </DndProvider>
            </TableBody>
          </Table>
          <MoreLessBoxIcon
            list={fields}
            maxShortListElement={MAX_SHORT_LIST}
            fullListOpen={isMore}
            setFullListOpen={setIsMore}
          />
        </>
      )}
      <AddFrequentlySearchedsDialog
        open={addDialogOpen}
        handleConfirm={handleAddOrEditItem}
        handleRemove={handleRemoveFromEditDialog}
        itemToEdit={itemToEdit}
        handleClose={() => {
          setAddDialogOpen(false);
          setItemToEdit(null);
        }}
      />
      <ConfirmationDialog open={deleteDialogOpen} close={handleCanelDelete} confirm={handleDelete} />
      <ConfirmationDialog
        open={cloneDialogOpen}
        close={() => setCloneDialogOpen(false)}
        confirm={handleClone}
        customTitle={"confirmation_modal_title_clone"}
      />
    </>
  );
};

export default FrequentlySearchedPhrazePanel;
