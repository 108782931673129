import React, { memo } from "react";
import { InputLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { MuiColorInput } from "mui-color-input";

const CustomizedColorPicker = ({ label, name }) => {
  const { control, formState } = useFormContext();

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiColorInput
            {...field}
            format="hex"
            helperText={formState?.errors[name] ? formState?.errors[name].message : ""}
            error={!!formState?.errors[name]}
            fullWidth
            size="small"
            isAlphaHidden
          />
        )}
      />
    </>
  );
};

export default memo(CustomizedColorPicker);
