import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Select } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import Switch from "@mui/material/Switch";

const operatorBool = [
    { id: "EQUALS", name: "jest równy/a" }
];

const operatorNumber = [
    { id: "EQUALS", name: "jest równy", twoValue : false},
    { id: "NOT_EQUAL", name: "jest różny", twoValue : false},
    { id: "GREATER_THAN", name: "większy", twoValue : false},
    { id: "GREATER_OR_EQUAL", name: "większy lub równy", twoValue : false},
    { id: "LESS_THAN", name: "mniejszy", twoValue : false},
    { id: "LESS_OR_EQUAL", name: "mniejszy lub równy", twoValue : false},
    { id: "BETWEEN", name: "pomiędzy", twoValue : true },
    { id: "BETWEEN_INCLUSIVE", name: "pomiędzy (włącznie)",twoValue : true }
];

const operatorText = [
    // { id: "EQUALS", name: "jest równy"},
    // { id: "NOT_EQUAL", name: "jest różny"},
    { id: "IEQUALS", name: "jest równy"},
    { id: "INOT_EQUAL", name: "nie jest równy"},
    { id: "ICONTAINS", name: "zawiera"},
    { id: "INOT_CONTAINS", name: "nie zawiera"},
    { id: "ISTARTS_WITH", name: "zaczyna się na"},
    { id: "INOT_STARTS_WITH", name: "nie zaczyna się na"},
    { id: "IENDS_WITH", name: "kończy się na"},
    { id: "INOT_ENDS_WITH", name: "nie kończy się na"},
    { id: "IS_NULL", name: "jest pusty"},
];

const DiscountCriteriaDataTypeCell = ({row, index, listCriteria, listName, typesCondition}) => {
    const {setValue} = useFormContext();
    const [dataType, setDataType] = useState(null);
    const [twoValue, setTwoValue] = useState(false);
    const [noValue, setNoValue] = useState(false);

    useEffect(() => {
        if (row?.type != '') {
            for (const type of typesCondition) {
                if (type.id == row.type) {
                    setDataType(type.type);
                }
            }
        }

        if (dataType == 'number') {
            for (const operator of operatorNumber) {
                if (operator.id == row.operator) {
                    setTwoValue(operator.twoValue);
                }
            }
        }

        dataType == 'text' && row?.operator == 'IS_NULL' ? setNoValue(true) : setNoValue(false);

    }, [row, dataType])

    const setOperator = (newValue, index) => {
        listCriteria[index].operator = newValue
        setValue(listName, listCriteria);
    }

    const setBoolValue = (newValue, index) => {
        listCriteria[index].boolValue = newValue
        listCriteria[index].stringValue = ""
        listCriteria[index].numberFirstValue = ""
        listCriteria[index].numberSecondValue = ""
        setValue(listName, listCriteria);
    }

    const setTextValue = (newValue, index) => {
        listCriteria[index].stringValue = newValue
        listCriteria[index].numberFirstValue = ""
        listCriteria[index].numberSecondValue = ""
        setValue(listName, listCriteria);
    }

    const setNumberFirstValue = (newValue, index) => {
        listCriteria[index].numberFirstValue = newValue
        listCriteria[index].StringValue = ""
        if(!twoValue) {
            listCriteria[index].numberSecondValue = ""
        }
        setValue(listName, listCriteria);
    }

    const setNumberSecondValue = (newValue, index) => {
        listCriteria[index].numberSecondValue = newValue
        listCriteria[index].stringValue = ""
        setValue(listName, listCriteria);
    }

    if(dataType == 'bool') {
        return (
            <>
                <TableCell>
                    <FormControl size={"small"} fullWidth>
                        <Select
                            native
                            id={"equalCondition-" + row.id}
                            name={"equalCondition-" + row.id}
                            value={row.operator}
                        >
                            {operatorBool.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch
                                checked={row.boolValue}
                                color="secondary"
                                onChange={(e) => setBoolValue(e.target.checked, index)}
                            />
                        } label="Prawda" />
                    </FormGroup>
                </TableCell>
                <TableCell/>
            </>
        );
    } else if (dataType == 'number' || dataType == 'integer') {
        return (
            <>
                <TableCell>
                    <FormControl size={"small"} fullWidth>
                        <Select
                            native
                            id={"equalCondition-" + row.id}
                            name={"equalCondition-" + row.id}
                            value={row.operator}
                            onChange={(e) => setOperator(e.target.value, index)}
                        >
                            {operatorNumber.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <TextField
                        size={"small"}
                        onBlur={(e) => setNumberFirstValue(e.target.value, index)}
                        defaultValue={row.numberFirstValue}
                        fullWidth={!twoValue}
                        type={"number"}
                        inputProps={dataType == 'integer' ? {min: 0} : {min: 0, step: 0.01}}
                    />
                </TableCell>
                {!twoValue && <TableCell />}
                {twoValue && <TableCell>
                    <TextField
                        size={"small"}
                        onBlur={(e) => setNumberSecondValue(e.target.value, index)}
                        defaultValue={row.numberSecondValue}
                        type={"number"}
                        inputProps={dataType == 'integer' ? {min: 0} : {min: 0, step: 0.01}}
                    />
                </TableCell>}
            </>
        );
    } else if (dataType == 'text') {
        return (
            <>
                <TableCell>
                    <FormControl size={"small"} fullWidth>
                        <Select
                            native
                            id={"equalCondition-" + row.id}
                            name={"equalCondition-" + row.id}
                            value={row.operator}
                            onChange={(e) => setOperator(e.target.value, index)}
                        >
                            {operatorText.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                {!noValue && <>
                    <TableCell>
                        <TextField
                            size={"small"}
                            defaultValue={row.stringValue}
                            onBlur={(e) => setTextValue(e.target.value, index)}
                            fullWidth
                        />
                    </TableCell>
                    <TableCell/>
                </>}
                {noValue && <><TableCell/><TableCell/></>}
            </>
        );
    } else {
        return <></>
    }
};

export default DiscountCriteriaDataTypeCell;

