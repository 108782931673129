import React, { useEffect, useState } from "react";
import { Button, Grid, Select } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import FormControl from "@mui/material/FormControl";
import * as color from "assets/styles/jss/common/colors";
import Divider from "@mui/material/Divider";
import { useFormContext } from "react-hook-form";
import ConfirmationDialog from "components/ConfirmationDialog";
import Stack from "@mui/material/Stack";
import CriteriaTableRow from "components/Edit/PriceReductions/CriteriaTableRow";
import { useTranslation } from "react-i18next";

const CriteriaTable = ({ typesCondition, listName, mainOperatorName }) => {
  const { setValue, watch } = useFormContext();
  const watchMainOperator = watch(mainOperatorName);
  const watchlist = watch(listName);
  const [nextId, setNextId] = useState(0);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { t } = useTranslation("common");

  const meetsConditions = [
    { id: "ALL", name: t("price_reduction_criteria_all") },
    { id: "ANY", name: t("price_reduction_criteria_any") },
  ];

  useEffect(() => {
    if (!watchlist) {
      setValue(listName, []);
    }
  }, []);

  useEffect(() => {
    if (watchMainOperator == null) {
      setMainOperator("ANY");
    }
  }, [watchMainOperator]);

  const addCriteria = () => {
    const newItem = {
      id: nextId,
      type: typesCondition[0]["id"],
      operator: "EQUALS",
      boolValue: true,
      numberFirstValue: "",
      numberSecondValue: "",
      stringValue: "",
    };

    setNextId((prevState) => prevState - 1);
    setValue(listName, [...watchlist, newItem]);
  };

  const setMainOperator = (newValue) => {
    setValue(mainOperatorName, newValue);
  };

  const handleDeleteRow = (row) => {
    setRowToDelete(row);
    setDeleteDialogOpen(true);
  };

  const removeItem = () => {
    const index = watchlist?.findIndex((object) => {
      return object.id === rowToDelete.id;
    });

    watchlist?.splice(index, 1);
    setValue(listName, watchlist);

    setRowToDelete(null);
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider sx={{ background: color.black, mb: 4, mt: 0 }} />
      </Grid>
      <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
        <Grid item>
          <Stack direction="row" spacing={5}>
            <FormControl size={"small"}>
              <Select native value={watchMainOperator} onChange={(e) => setMainOperator(e.target.value)}>
                {meetsConditions?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item>
          <Button size="small" color="secondary" variant="contained" onClick={addCriteria}>
            {t("price_reduction_add_criteria")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 5 }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <CriteriaTableRow
                listCriteria={watchlist}
                listName={listName}
                typesCondition={typesCondition}
                mainOperator={watchMainOperator}
                handleDeleteRow={handleDeleteRow}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ConfirmationDialog open={deleteDialogOpen} close={() => setDeleteDialogOpen(false)} confirm={removeItem} />
    </>
  );
};

export default CriteriaTable;
