import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { setAttribute, setListPage } from "redux/slices/crud";
import { selectListFilters } from "redux/slices/crud";
import { debounce } from "throttle-debounce";

const HeaderTextFieldsSearch = ({ itemData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const filters = useSelector(selectListFilters);
  const searchRef = useRef(null);

  useEffect(() => {
    let searchValue = filters?.attributes[0]?.value;
    if (searchValue && searchRef) {
      searchRef.current.value = searchValue;
    }
  }, []);

  const handleChangeValue = (e) => {
    const value = itemData?.searchFieldName
      ? { name: itemData.searchFieldName, value: e.target.value }
      : { name: "name:like", value: e.target.value };
    dispatch(setListPage(0))
    dispatch(setAttribute(value));
  };

  const searchDebounce = debounce(300, handleChangeValue);

  return (
    <TextField
      id={"searchField"}
      inputRef={searchRef}
      placeholder={t(itemData.name)}
      type="search"
      variant="outlined"
      size="small"
      onChange={searchDebounce}
      disabled={itemData.disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default HeaderTextFieldsSearch;
