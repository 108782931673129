import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({isLogged, component: Component, ...rest}) => {
    return <Route {...rest} render={props => (
        isLogged
            ? <Component {...props} />
            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    )}/>
}



