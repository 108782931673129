import { Box, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MenuItem from "layout/Menu/MenuItem/MenuItem";
import React, { useState } from "react";
import { selectCurrentSelectedLevelZero, selectMenuItems, setCurrentSelectedLevelZero } from "redux/slices/menu";
import styles from "./styles";
import Submenu from "layout/Menu/Submenu/Submenu";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "assets/img/logo-zg.svg";

const useStyles = makeStyles(styles);

const Menu = () => {
    const classes = useStyles();
    const menuItems = useSelector(selectMenuItems)
    const dispatch = useDispatch()

    //const [sidebar, setSidebar] = useState(0);
    const sidebar = useSelector(selectCurrentSelectedLevelZero)
    const [mobileMain, setMobileMain] = useState(true);

    const markMenuItem = (itemIdx) => {
        let clickedIndex = parseInt(itemIdx);
        dispatch(setCurrentSelectedLevelZero(clickedIndex));
        setMobileMain(false);
    };

    const backToMain = () => {
        setMobileMain(true);
    };

    return ( (menuItems?.length > 0) &&
        <div className={classes.sidebar}>
            {/* mobile menu */}
            <Hidden mdUp>
                <nav className={clsx(classes.mobileMItems, !mobileMain && classes.visibleSubmenu)}>
                    {!mobileMain && (
                        <Box className={classes.back} onClick={backToMain}>
                            <ChevronLeftIcon/>
                            {menuItems[sidebar].name}
                        </Box>
                    )}
                    {mobileMain && (
                        <>
                            <ProfileInfo/>
                            <ul className={classes.mobileCategories}>
                                {menuItems.map((menu, index) => (
                                    <MenuItem
                                        main={mobileMain}
                                        key={"sdbar." + index}
                                        index={index}
                                        item={menu}
                                        onClickedItem={markMenuItem}
                                    />
                                ))}
                            </ul>
                        </>
                    )}

                    {!mobileMain && <Submenu group={menuItems[sidebar].group} main={mobileMain}/>}
                </nav>
            </Hidden>
            {/* normal */}
            <Hidden mdDown>
                <nav>
                    <div className={classes.leftSidebar}>
                        {menuItems.map((menu, index) => (
                            <MenuItem key={"sdbar." + index} index={index} show={sidebar} item={menu} onClickedItem={markMenuItem}/>
                        ))}
                    </div>
                </nav>

                <div className={classes.rightSidebar}>
                    <div className={classes.logoWrapper}>
                        <Logo/>
                    </div>
                    <div className={classes.submenuWrapper}>
                        <Submenu group={menuItems[sidebar].group}/>
                    </div>
                    <ProfileInfo/>
                </div>
            </Hidden>
        </div>
    );
};
export default Menu;
