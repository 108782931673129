import React from "react";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Box from "@mui/material/Box";

const MoreLessBoxIcon = ({ list, maxShortListElement, fullListOpen, setFullListOpen}) => {
  return <>
    <Box sx={{ textAlign: 'center' }}>
      {(list?.length > maxShortListElement && !fullListOpen) && <IconButton onClick={() => setFullListOpen(true)}><MoreHorizIcon/></IconButton>}
      {(list?.length > maxShortListElement && fullListOpen) && <Button onClick={() => setFullListOpen(false)}><ExpandLessIcon/></Button>}
    </Box>
  </>
};
export default MoreLessBoxIcon;