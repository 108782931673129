import React from "react";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import * as color from "assets/styles/jss/common/colors";

const ProcessToolbar = ({label = "", labelButton = "", buttonAction}) => {

  return (
      <>
          <Toolbar>
              {label}
              <Box sx={{flexGrow: 1}}/>
              {buttonAction && <Box sx={{display: {md: 'flex'}}}>
                  <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={buttonAction}
                  >
                      {labelButton}
                  </Button>
              </Box>}
          </Toolbar>
          <Divider sx={{ background: color.black, my: 4 }} />
        </>
  );
}

export default ProcessToolbar;

