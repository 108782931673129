import { Grid, InputLabel, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import moment from "moment/moment";

const endpoint = "/marketing-consents/";
const title = "marketing_consent_edit_label";
const moduleUrl = "/marketing/consents/";

const ConsentEdit = () => {
    const {id} = useParams();
    const current = useSelector(selectEditItem);
    const {t} = useTranslation("common");

    const validationSchema = {
        value: Yup.string().trim().required(t("form-validation-required")),
        firstName: Yup.string().trim().required(t("form-validation-required")),
        lastName: Yup.string().trim().required(t("form-validation-required")),
        active: Yup.boolean().required(t("form-validation-required")),

    };

    const validationSchemaMail = {
        ...validationSchema,
        value: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
    }

    const refForm = useRef(null);

    return (
        <>
            <EditViewNoLanguage
                endpoint={endpoint}
                title={title}
                moduleUrl={moduleUrl}
                validationSchema={current?.type == 'EMAIL' ? Yup.object(validationSchemaMail) : Yup.object(validationSchema)}
                refForm={refForm}
                noDeleteButton={true}
            >
                <FormData id={id} current={current} refForm={refForm}/>
            </EditViewNoLanguage>
        </>
    );
};

const FormData = ({id, current, refForm}) => {
    const {t} = useTranslation("common");

    return <>
        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Imię"}
                        name={"firstName"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Nazwisko"}
                        name={"lastName"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={current?.type == 'EMAIL' ? "Email" : "Telefon"}
                        name={"value"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>

                </Grid>
                <Grid item xs={12} sm={"auto"}>
                    <CustomizedSwitch
                        topLabel={""}
                        rightLabel={"Aktywny"}
                        name={"active"}
                    />
                </Grid>
                {current?.registrationDate && <Grid item xs={12}>
                    <InputLabel>Data rejestracji</InputLabel>
                    <Typography>{moment(current?.registrationDate)?.format("DD.MM.YYYY o HH:mm")}</Typography>
                </Grid>}
            </Grid>
        </Paper>
    </>;
};

export default ConsentEdit;
