import React from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { PRODUCT_TYPES } from "enums/productTypes";


const CellProductType = ({data, handleClick, row}) => {
    const {t} = useTranslation("common");

    return (
        <TableCell onClick={(e) => handleClick(e, row)}>
            {data && PRODUCT_TYPES.find(type => type.id == data)?.displayName}
        </TableCell>
    );
};

export default CellProductType;