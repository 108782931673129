import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import Wysiwyg from "components/Edit/Wysiwyg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizedDatePickerMui from "components/Edit/CustomizedDateTimePickerMui";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import axios from "axios";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import Autocomplete from "@mui/material/Autocomplete";
import CustomizedSelect from "components/Edit/CustomizedSelect";

const endpoint = "/comment/";
const title = "cms_comment_edit_label";
const moduleUrl = "/cms/comments/";

const statusValues = [
  { id: "NEW", displayName: "Nowy" },
  { id: "ACCEPTED", displayName: "Zaakceptowany" },
  { id: "BANNED", displayName: "Odrzucony" },
];

const CommentEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    author: Yup.string().trim().required(t("form-validation-required")),
    email: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
    status: Yup.string().required(t("form-validation-required")),
    date: Yup.string().nullable(),
    ip: Yup.string().nullable(),
    pageHandler: Yup.string().trim().required(t("form-validation-required")),
    pageTitle: Yup.string().nullable(),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Komentarz"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { setValue, formState } = useFormContext();
  const [allArticle, setAllArticle] = useState([]);

  const { t } = useTranslation("common");

  useEffect(() => {
    backend.get("/article/getAllWithLang").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllArticle(res.data);
        }
      }
    });
    return () => {
      setAllArticle([]);
    };
  }, []);

  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setValue("ip", res.data.IPv4);
  };

  useEffect(() => {
    if (id == "new") {
      getIp();
    }
  }, []);

  const handleSetArticle = (newArticle) => {
    if (newArticle) {
      setValue("pageHandler", newArticle.url);
      setValue("pageTitle", newArticle.title);
      setValue("lang", newArticle.lang);
    } else {
      setValue("pageHandler", "");
      setValue("pageTitle", "");
      setValue("language", "");
    }
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Autor"} name={"author"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Email"} name={"email"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              name={"status"}
              label={t("cms_edit_page_state")}
              items={statusValues}
              defaultValue={"NEW"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12}>
            <Wysiwyg label={"Zajawka"} name={"content"} />
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Szczegółowe informacje
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <CustomizedDatePickerMui label={"Data dodania komentarza"} name="date" />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomizedTextField label={"IP"} name={"ip"} disabled={id == "new" ? false : true} />
                  </Grid>
                  {id == "new" && (
                    <Grid item xs={12}>
                      <InputLabel>{"Artykuł"}</InputLabel>
                      <Autocomplete
                        disablePortal
                        options={allArticle}
                        getOptionLabel={(option) => {
                          return "(" + option.lang?.toUpperCase() + ") " + option.title;
                        }}
                        onChange={(e, newValue) => {
                          handleSetArticle(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size={"small"}
                            helperText={formState.errors["pageHandler"] ? formState.errors["pageHandler"].message : ""}
                            error={!!formState.errors["pageHandler"]}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <CustomizedTextField label={"URL strony"} name={"pageHandler"} disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomizedTextField label={"Tytuł strony"} name={"pageTitle"} disabled />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CommentEdit;
