import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { fetchAvailableLangs, selectLanguages } from "redux/slices/language";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useFormContext } from "react-hook-form";

const LanguagePanel = () => {
    const {watch, setValue} = useFormContext();
    const watchLanguages = watch("languages")
    const dispatch = useDispatch();
    const languages = useSelector(selectLanguages);
    const [allLanguages, setAllLanguages] = useState([]);

    useEffect(() => {
        dispatch(fetchAvailableLangs());
    }, []);

    useEffect(() => {
        if(languages?.length > 0) {
            const allLangs = [];

            let nextId = 0;
            for (const lang of languages) {
                allLangs.push({id: nextId, lang: lang.code, isAvailable: true, sortOrder: lang.sortOrder});
                nextId++;
            }
            setAllLanguages(allLangs.sort((a, b) => {return a.sortOrder - b.sortOrder}));
        }
    }, [languages]);

    useEffect(() => {
        if(!watchLanguages && allLanguages?.length > 0) {
            setValue("languages", allLanguages)
        }
    }, [allLanguages])

    const changeValue = (index, newValue) => {
        watchLanguages[index] = {...watchLanguages[index], isAvailable: newValue}
        setValue("languages", watchLanguages);
    }

    return (
        <>
            <Stack direction={"row"} spacing={2}>
                {watchLanguages?.map((language, index) => (
                    <FormControlLabel key={language.lang} control={
                        <Switch
                            checked={language.isAvailable}
                            color="secondary"
                            onChange={(e) => changeValue(index, e.target.checked)}
                        />
                    } label={language.lang.toUpperCase()}/>
                ))}
            </Stack>
        </>
    );
};

export default LanguagePanel;

