import "moment/locale/pl";
import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { InputLabel } from "@mui/material";
import moment from "moment/moment";

function CustomizedDateTimePickerMuiDialog({ label, name, value, setValue, errors, disabled = false }) {
  moment.locale("pl");
  const handleChange = (value) => {
    if (value) {
      setValue(value);
    } else {
      setValue("");
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pl">
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <DateTimePicker
          inputFormat={"yyyy-MM-DD HH:mm"}
          renderInput={(props) => (
            <TextField
              {...props}
              fullWidth
              size="small"
              helperText={errors[name] ? errors[name].message : ""}
              error={!!errors[name]}
            />
          )}
          readOnly={disabled}
          value={value}
          onChange={(v) => handleChange(v)}
        />
      </LocalizationProvider>
    </>
  );
}

export default CustomizedDateTimePickerMuiDialog;
