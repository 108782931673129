import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Input, InputLabel, Typography } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import { useSnackbar } from "notistack";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: alpha(color.primary, 0.15),
    borderStyle: 'dashed',
    backgroundColor: alpha(color.primary, 0.05),
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
};

const CustomizedDropZoneAllFile = ({label, selectedFile}) => {
    const {enqueueSnackbar} = useSnackbar();


    const onDrop = useCallback(acceptedFiles => {
        if(acceptedFiles?.length > 0) {
            selectedFile(acceptedFiles[0])
        } else {
            enqueueSnackbar("Nieudane dodawanie pliku. \n Wybierz jeden plik. \n Plik może być za duży.", {
                variant: "warning",
            });
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({onDrop, maxFiles: 1, maxSize: 10000000});

    const style = useMemo(() => ({
        ...baseStyle,
    }), []);

    return (
        <>
            <InputLabel>
                {label}
            </InputLabel>
            <div className="container">
                <div {...getRootProps({style})}>
                    <Input {...getInputProps()} />
                        <>
                            <FileDownloadIcon/>
                            <Typography>Przeciągnij lub wybierz plik</Typography>
                        </>
                </div>
            </div>
        </>
    );
}

export default CustomizedDropZoneAllFile;

