import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import "./assets/styles/scss/global.scss";
import { PrivateRoute } from "components/PrivateRoute";
import { PublicRoute } from "components/PublicRoute";
import Login from "./modules/Login";
import { fetchMenu } from "redux/slices/menu";
import { fetchCurrentUser, fetchIsLogged, selectIsLogged } from "redux/slices/user";
import MarketingConsentsList from "modules/Marketing/ConsentsList";
import MarketingProcessesList from "modules/Marketing/ProcessesList";
import MarketingMailList from "modules/Marketing/MailList";
import MarketingSmsList from "modules/Marketing/SmsList";
import MarketingPopupsList from "modules/Marketing/PopupsList";
import MarketingDiscountCodesList from "modules/Marketing/DiscountCodesList";
import Page404 from "modules/Page404";
import CustomersList from "modules/ShopOrders/CustomersList";
import OrdersList from "modules/ShopOrders/OrdersList";
import InvoicesList from "modules/ShopOrders/InvoicesList";
import ContactFormsList from "modules/ShopNotifications/ContactFormsList";
import ProductsList from "modules/ShopCatalog/ProductsList";
import CategoryList from "modules/ShopCatalog/CategoryList";
import InboxList from "modules/Home/InboxList";
import PagesList from "modules/CMSDocuments/PagesList";
import EntriesList from "modules/CMSDocuments/EntriesList";
import CmsCategoriesList from "modules/CMSDocuments/CategoriesList";
import CommentsList from "modules/CMSDocuments/CommentsList";
import CmsMediaList from "modules/CMSResources/MediaList";
import FooterList from "modules/CmsComponents/FooterList";
import SlidersList from "modules/CmsComponents/SlidersList";
import MenuList from "modules/CmsComponents/MenuList";
import FaqList from "modules/CmsComponents/FaqList";
import PageEdit from "modules/CMSDocuments/PageEdit";
import UsersList from "modules/SettingsAccess/UsersList";
import RolesList from "modules/SettingsAccess/RolesList";
import LanguagesList from "modules/SettingsConfiguration/LanguagesList";
import RedirectsList from "modules/SettingsConfiguration/RedirectsList";
import SitemapList from "modules/SettingsConfiguration/SitemapList";
import LogList from "modules/SettingsConfiguration/LogList";
import RedirectEdit from "modules/SettingsConfiguration/RedirectEdit";
import CommentEdit from "modules/CMSDocuments/CommentEdit";
import EntryEdit from "modules/CMSDocuments/EntryEdit";
import CategoryEdit from "modules/CMSDocuments/CategoryEdit";
import MediaEdit from "modules/CMSResources/MediaEdit";
import FooterEdit from "modules/CmsComponents/FooterEdit";
import SliderEdit from "modules/CmsComponents/SliderEdit";
import MenuEdit from "modules/CmsComponents/MenuEdit";
import FaqEdit from "modules/CmsComponents/FaqEdit";
import CustomerEdit from "modules/ShopOrders/CustomerEdit";
import ShopCategoryEdit from "modules/ShopCatalog/CategoryEdit";
import ProductEdit from "modules/ShopCatalog/ProductEdit";
import OrderEdit from "modules/ShopOrders/OrderEdit";
import ProductInquiriesList from "modules/ShopNotifications/ProductInquiriesList";
import ComplaintsList from "modules/ShopNotifications/ComplaintsList";
import SystemMessagesList from "modules/ShopNotifications/SystemMessagesList";
import TranslationsList from "modules/SettingsConfiguration/TranslationsList";
import ProcessEdit from "modules/Marketing/ProcessEdit";
import MailEdit from "modules/Marketing/MailEdit";
import SmsEdit from "modules/Marketing/SmsEdit";
import PopupEdit from "modules/Marketing/PopupEdit";
import ConsentEdit from "modules/Marketing/ConsentEdit";
import DiscountCodeEdit from "modules/Marketing/DiscountCodeEdit";
import LanguageEdit from "modules/SettingsConfiguration/LanguageEdit";
import SitemapEdit from "modules/SettingsConfiguration/SitemapEdit";
import AnalyticalCodesEdit from "modules/SettingsConfiguration/AnalyticalCodesEdit";
import ConfigurationEdit from "modules/SettingsConfiguration/ConfigurationEdit";
import SmsSettings from "modules/SettingsIntegrations/SmsEdit";
import MailSettings from "modules/SettingsIntegrations/MailEdit";
import UserEdit from "modules/SettingsAccess/UserEdit";
import RoleEdit from "modules/SettingsAccess/RoleEdit";
import Shortcuts from "modules/Home/Shortcuts";
import StartPage from "modules/Home/StartPage";
import GeneticTest from "modules/Home/GeneticTest";
import PricesReducesList from "modules/Marketing/PriceReducesList";
import PriceReducesEdit from "modules/Marketing/PriceReducesEdit";
import BannerList from "modules/CmsComponents/BannerList";
import BannerEdit from "modules/CmsComponents/BannerEdit";
import TestABList from "modules/Marketing/TestABList";
import TestABEdit from "modules/Marketing/TestABEdit";

function App() {
  const isLogged = useSelector(selectIsLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIsLogged());
  }, []);

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchCurrentUser());
      dispatch(fetchMenu());
    }
  }, [isLogged]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateRoute isLogged={isLogged} exact path="/" component={StartPage} />

          <PrivateRoute isLogged={isLogged} exact path="/home/inbox" component={InboxList} />
          <PrivateRoute isLogged={isLogged} exact path="/home/inbox/genetic-tests/:id" component={GeneticTest} />
          <PrivateRoute isLogged={isLogged} exact path="/home/shortcuts" component={Shortcuts} />

          <PrivateRoute isLogged={isLogged} exact path="/cms/pages" component={PagesList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/pages/:id" component={PageEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/pages/:id/:langCode" component={PageEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/posts" component={EntriesList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/posts/:id" component={EntryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/posts/:id/:langCode" component={EntryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/categories" component={CmsCategoriesList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/categories/:id" component={CategoryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/categories/:id/:langCode" component={CategoryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/comments" component={CommentsList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/comments/:id" component={CommentEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/cms/media" component={CmsMediaList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/media/:id" component={MediaEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/cms/faq" component={FaqList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/faq/:id" component={FaqEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/faq/:id/:langCode" component={FaqEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/menu" component={MenuList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/menu/:id" component={MenuEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/sliders" component={SlidersList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/sliders/:id" component={SliderEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/sliders/:id/:langCode" component={SliderEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/footers" component={FooterList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/footers/:id" component={FooterEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/footers/:id/:langCode" component={FooterEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/shop/customers" component={CustomersList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/customers/:id" component={CustomerEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/orders" component={OrdersList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/orders/new" component={OrdersList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/orders/:id" component={OrderEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/invoices" component={InvoicesList} />

          <PrivateRoute isLogged={isLogged} exact path="/shop/contact-forms" component={ContactFormsList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/product-inquiries" component={ProductInquiriesList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/complaints" component={ComplaintsList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/system-messages" component={SystemMessagesList} />

          <PrivateRoute isLogged={isLogged} exact path="/shop/products" component={ProductsList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/products/:id" component={ProductEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/products/:id/:langCode" component={ProductEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/categories" component={CategoryList} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/categories/:id" component={ShopCategoryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/shop/categories/:id/:langCode" component={ShopCategoryEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/marketing/processes" component={MarketingProcessesList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/processes/:id" component={ProcessEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/processes/:id/:langCode" component={ProcessEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/mail" component={MarketingMailList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/mail/:id" component={MailEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/mail/:id/:langCode" component={MailEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/sms" component={MarketingSmsList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/sms/:id" component={SmsEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/sms/:id/:langCode" component={SmsEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/popups" component={MarketingPopupsList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/popups/:id" component={PopupEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/popups/:id/:langCode" component={PopupEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/consents" component={MarketingConsentsList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/consents/:id" component={ConsentEdit} />
          <PrivateRoute
            isLogged={isLogged}
            exact
            path="/marketing/discount-codes"
            component={MarketingDiscountCodesList}
          />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/discount-codes/:id" component={DiscountCodeEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/price-reduces" component={PricesReducesList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/price-reduces/:id" component={PriceReducesEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/banners" component={BannerList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/banners/:id" component={BannerEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/banners/:id/:langCode" component={BannerEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/ab-tests" component={TestABList} />
          <PrivateRoute isLogged={isLogged} exact path="/marketing/ab-tests/:id" component={TestABEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/users" component={UsersList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/users/:id" component={UserEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/roles" component={RolesList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/roles/:id" component={RoleEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/sms" component={SmsSettings} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/sms/:langCode" component={SmsSettings} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/mail" component={MailSettings} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/mail/:langCode" component={MailSettings} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/languages" component={LanguagesList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/languages/:id" component={LanguageEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/translations" component={TranslationsList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/configuration" component={ConfigurationEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/configuration/:id" component={ConfigurationEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/codes" component={AnalyticalCodesEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/codes/:langCode" component={AnalyticalCodesEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/redirects" component={RedirectsList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/redirects/:id" component={RedirectEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/sitemap" component={SitemapList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/sitemap/:id" component={SitemapEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/log" component={LogList} />

          <PublicRoute isLogged={isLogged} exact path="/login" component={Login} />

          <PrivateRoute isLogged={isLogged} component={Page404} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
