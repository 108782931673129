import React, { memo, useEffect, useRef, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { TextField, Tooltip } from "@mui/material";


const CellTranslate = ({language, row, handleSave, handleClick, handleChange, isSelectedRow, isTranslateToSave}) => {
    const [value, setValue] = useState("");
    const [orgValue, setOrgValue] = useState("");
    const [isThisCellClicked, setIsThisCellClicked] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const val = row.keys.find(item => item.lang === language);
        setOrgValue(val.text);
        setValue(val.text);
    }, [])

    useEffect(() => {
        if (!isSelectedRow && value != orgValue && !isTranslateToSave) {
            setValue(orgValue);
        } else if (!isSelectedRow && value != orgValue && isTranslateToSave) {
            setOrgValue(value);
        }

        if(!isSelectedRow) {
            setIsThisCellClicked(false);
        }

        if(isSelectedRow) {
            updateCursorToEnd();
        }

    }, [isSelectedRow])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            save();
        } else if (e.key === "Escape") {
            cancel(e);
        }
    }

    const save = () => {
        handleSave(row);
    }

    const cancel = (e) => {
        handleClick(e, {});
    }
    const updateCursorToEnd = () => inputRef.current.selectionStart = value.length;

    return (
       <>
           <Tooltip title={row.key}>
               {isSelectedRow ?  (
                   <TableCell>
                       <TextField

                           inputRef={inputRef}
                           autoFocus={isThisCellClicked}
                           size="small"
                           fullWidth
                           value={value}
                           onChange={(e) => {
                               setValue(e.target.value);
                               handleChange(language, e.target.value)
                           }}
                           onKeyDown={handleKeyDown}
                           multiline
                       />
                   </TableCell>
               ) : (
                   <TableCell
                       align="left"
                       onClick={(e) => {
                           handleClick(e, row)
                           setIsThisCellClicked(true);
                       }}
                   >
                       {value}
                   </TableCell>
               )}
           </Tooltip>
       </>
    )
};

export default memo(CellTranslate);