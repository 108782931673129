import { Grid, InputLabel, LinearProgress, List, Paper } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import Wysiwyg from "components/Edit/Wysiwyg";
import CustomizedDropZoneAllFile from "components/Edit/Mail/CustomizedDropZoneAllFile";
import CustomizedListItemVariable from "components/Edit/Mail/CustomizedListItemVariable";
import TestMailDialog from "modules/Marketing/TestMailDialog";
import EditViewLanguage from "components/EditViewLanguage";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import ListAttachments from "components/Edit/Mail/ListAttachments";
import { useParams } from "react-router-dom";

const endpoint = "/mail-template/";
const title = "marketing_mail_edit_label";
const moduleUrl = "/marketing/mail/";

const variables = [
  {
    id: 1,
    name: "@openCode@",
    description: "kod otwarcia",
  },
  {
    id: 2,
    name: "@testCode@",
    description: "kod badania",
  },
];

const MailEdit = () => {
  const { t } = useTranslation("common");
  const [openDialogTestMail, setOpenDialogTestMail] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    subject: Yup.string().trim().required(t("form-validation-required")),
    content: Yup.string(),
  });

  const defaultValue = {
    name: "",
    subject: "",
    content: "",
  };

  const refForm = useRef(null);
  const childRef = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        testMessage={() => setOpenDialogTestMail(true)}
        defaultValue={defaultValue}
        customSave={() => childRef.current.customSave()}
        deleteButtonTitle={"Usuń Email"}
      >
        <CustomFormData
          openDialogTestMail={openDialogTestMail}
          handleCloseDialogTestMail={() => setOpenDialogTestMail(false)}
          refForm={refForm}
          ref={childRef}
        />
      </EditViewLanguage>
    </>
  );
};
// eslint-disable-next-line react/display-name
const CustomFormData = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const { openDialogTestMail, handleCloseDialogTestMail, refForm } = props;

  const { watch, setValue, getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [editorJodit, setEditorJodit] = useState(null);
  const [testIsProgress, setTestIsProgress] = useState(0);

  const [newAttachment, setNewAttachment] = useState(null);
  const [nextId, setNextId] = useState(-1);
  const [attachments, setAttachments] = useState([]);
  const [countFileToUpload, setCountFileToUpload] = useState(-1);
  const [fileSaved, setFileSaved] = useState([]);

  const { id } = useParams();

  const watchSubject = watch("subject");
  const watchContent = watch("content");
  const watchAttachments = watch("attachments");

  const setEditorCallback = useCallback(
    (instance) => {
      setEditorJodit(instance);
    },
    [editorJodit],
  );

  useEffect(() => {
    console.log(attachments);
  }, [attachments]);

  useEffect(() => {
    if (watchAttachments) {
      setAttachments(watchAttachments.map((item) => ({ id: item.id, fileName: item.fileName, file: null })));
    }
  }, [watchAttachments]);

  useEffect(() => {
    if (newAttachment) {
      const index = attachments.findIndex((attachment) => {
        return attachment.fileName == newAttachment.name;
      });

      if (index > -1) {
        enqueueSnackbar("Plik z taką nazwą jest już na liście załączników.", {
          variant: "warning",
        });
        return;
      }

      const newFile = {
        id: nextId,
        file: newAttachment,
        fileName: newAttachment.name,
      };

      setNextId((prevState) => prevState - 1);
      setAttachments((prev) => [...prev, newFile]);
      setNewAttachment(null);
    }
  }, [newAttachment]);

  const delAttachment = (file) => {
    const index = attachments.findIndex((attachment) => {
      return attachment.id == file.id;
    });
    if (index > -1) {
      attachments.splice(index, 1);
    }

    setAttachments([...attachments]);
  };

  const handleSendTestMail = (mail) => {
    handleCloseDialogTestMail();

    let attachmentChanged = id === "new";
    if (attachments?.length != watchAttachments?.length) {
      attachmentChanged = true;
    } else {
      for (const attachment of attachments) {
        if (attachment.id < 0) {
          attachmentChanged = true;
          break;
        }
      }
    }

    if (attachmentChanged) {
      enqueueSnackbar("Wysyłka wiadomości testowej możliwa po zapisaniu załączników", {
        variant: "warning",
      });

      return;
    }

    const d = {
      emailAddress: mail,
      subject: watchSubject,
      content: watchContent,
      attachments: watchAttachments,
    };

    setTestIsProgress((prev) => prev + 1);

    backend.post(endpoint + "test-send-mail", d).then((res) => {
      if (isSuccess(res)) {
        if (res.data) {
          enqueueSnackbar("Test wiadomości został wysłany poprawnie", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Wysyłka wiadomości testowej - nieudana", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
      setTestIsProgress((prev) => prev - 1);
    });
  };

  useImperativeHandle(ref, () => ({
    customSave() {
      let count = 0;
      for (const file of attachments) {
        if (file.id < 0) {
          count++;
        }
      }

      setCountFileToUpload(count);
      setFileSaved([]);

      for (const file of attachments) {
        if (file.id < 0) {
          const formData = new FormData();

          formData.append("file", file.file);
          formData.append("name", file.fileName);

          const headers = {
            "Content-Type": `multipart/form-data;`,
          };

          backend.post("/mail-template/upload", formData, { headers: headers }).then((response) => {
            if (isSuccess(response)) {
              const { data } = response;
              if (data.ok) {
                setFileSaved((prev) => [...prev, data]);
              } else {
                enqueueSnackbar("Nieudany zapis pliku: " + file.fileName, {
                  variant: "error",
                });
              }
            } else {
              enqueueSnackbar("Nieudany zapis pliku: " + file.fileName, {
                variant: "error",
              });
            }
          });
        }
      }
    },
  }));

  useEffect(() => {
    if (countFileToUpload == fileSaved.length) {
      let currentAttachments = [];
      const attachs = getValues("attachments");
      if (attachs) {
        currentAttachments = [...getValues("attachments")];
      }

      //remove removed item
      let i = currentAttachments.length - 1;
      while (i >= 0) {
        const index = attachments.findIndex((item) => {
          return item.id == currentAttachments[i].id;
        });

        if (index < 0) {
          currentAttachments.splice(i, 1);
        }

        i--;
      }

      if (fileSaved.length > 0) {
        let idx = -1;
        const newAttachments = [];
        for (const fileSavedElement of fileSaved) {
          const newAttachment = {
            id: idx,
            fileName: fileSavedElement.fileName,
            pathToFile: fileSavedElement.pathToFile,
          };
          idx--;
          newAttachments.push(newAttachment);
        }

        setValue("attachments", [...currentAttachments, ...newAttachments]);
      } else {
        setValue("attachments", [...currentAttachments]);
      }

      setCountFileToUpload(-1);
      setFileSaved([]);
      refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  }, [countFileToUpload, fileSaved]);

  return (
    <>
      <Grid container spacing={5}>
        {/* main */}
        <Grid item xs={12} sm={8}>
          <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomizedTextField label={"Nazwa"} name={"name"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Tytuł"} name={"subject"} />
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg label={"Zawartość email"} name={"content"} setInitEditor={setEditorCallback} />
              </Grid>
              <Grid item xs={12}>
                {testIsProgress > 0 && (
                  <>
                    {testIsProgress > 1 ? (
                      <InputLabel>Wysyłka wiadomości ({testIsProgress})...</InputLabel>
                    ) : (
                      <InputLabel>Wysyłka wiadomości...</InputLabel>
                    )}
                    <LinearProgress />
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* right */}
        <Grid item xs={12} sm={4}>
          <Grid item xs={12}>
            <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <CustomizedDropZoneAllFile label={"Lista załączników"} selectedFile={setNewAttachment} />
                </Grid>
                <Grid item xs={12}>
                  <ListAttachments attachments={attachments} delAttachment={delAttachment} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
              <Grid container spacing={5}>
                <List>
                  {variables.map((item) => (
                    <CustomizedListItemVariable
                      key={item.id}
                      primaryText={item.name}
                      secondaryText={item.description}
                      handleClick={() => {
                        editorJodit?.selection?.insertHTML(item.name);
                      }}
                    />
                  ))}
                </List>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <TestMailDialog
        open={openDialogTestMail}
        handleConfirm={handleSendTestMail}
        handleClose={handleCloseDialogTestMail}
      />
    </>
  );
});

export default forwardRef(MailEdit);
