import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { Grid, InputLabel } from "@mui/material";
import { useFormContext } from "react-hook-form";

const CustomizedTextField = ({label, name, disabled = false, type = "text", defaultValue = '', step = 1}) => {
    const {register, control, formState} = useFormContext();

    return (
        <>
            <Grid item xs={12}>
                <InputLabel htmlFor={name}>
                    {label}
                </InputLabel>
                <TextField
                    {...register(name)}
                    helperText={formState?.errors[name] ? formState?.errors[name].message : ""}
                    error={!!formState?.errors[name]}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    disabled={disabled}
                    type={type}
                    inputProps={(type === 'number') ? {min: 0, step: step} : {}}
                    defaultValue={defaultValue}
                />
            </Grid>
        </>
    );
}

export default memo(CustomizedTextField);
