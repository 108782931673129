import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import SliderToolbar from "components/Edit/Slider/SliderToolbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as color from "assets/styles/jss/common/colors";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Checkbox, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { alpha } from "@mui/material/styles";
import AddPageDialog from "modules/Marketing/AddPageDialog";

const PopupsItemsPanel = ({label, name}) => {
    const {control, setValue} = useFormContext();
    const {fields, append} = useFieldArray({
        name: name,
        control,
        keyName: 'idUseField',
    });
    const [listCheckedItem, setListCheckedItem] = useState([]);
    const [itemToDelete, setItemToDelete]= useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [cloneOrCopyButtonDisabled, setCloneOrCopyButtonDisabled] = useState(true);
    const [newItemId, setNewItemId] = useState(-1);
    const [itemToEdit, setItemToEdit] = useState(null);

    useEffect(() => {
        if(listCheckedItem.length > 0) {
            setCloneOrCopyButtonDisabled(false);
        } else {
            setCloneOrCopyButtonDisabled(true);
        }
    }, [listCheckedItem]);

    const handleCheched = (e, id) => {
        const checkboxChecked = e.target.checked;
        if(checkboxChecked) {
            setListCheckedItem(prevState => {setListCheckedItem([...prevState, id])})
        } else {
            const tmpArray = listCheckedItem.filter((item) => {return item !== id});
            setListCheckedItem(tmpArray);
        }
    }

    const handleDelete = () => {
        //del item from edit dialog
        if(itemToDelete) {
            const index = fields.findIndex(object => {
                return object.id === itemToDelete.id;
            });

            fields.splice(index, 1);
            setValue(name, fields);

            setListCheckedItem([]);
            setItemToDelete(null)
            setDeleteDialogOpen(false);

            return;
        }

        //del checked items
        for (const item of listCheckedItem) {
            const index = fields.findIndex(object => {
                return object.id === item;
            });

            fields.splice(index, 1);
        }

        setValue(name, fields);

        setListCheckedItem([]);
        setDeleteDialogOpen(false);
    }

    const handleCanelDelete = () => {
        setDeleteDialogOpen(false)
        setItemToDelete(null);
    }

    const handleClone = () => {
        let newId = newItemId;
        const itemsToAdd = [];

        for (const item of listCheckedItem) {
            const itemToClone = fields.find(object => {
                return object.id === item;
            });

            const itemToAdd = Object.assign({}, itemToClone)

            itemToAdd.id = newId;
            newId -= 1;
            itemToAdd.link = "Kopia - " + itemToAdd.link
            itemsToAdd.push(itemToAdd);
        }

        fields.push(...itemsToAdd);
        setValue(name, fields);

        setNewItemId(newId);
        setListCheckedItem([]);
        setCloneDialogOpen(false);
    }

    const handleAddOrEditItem = (item) => {
        setAddDialogOpen(false);

        if(Object.hasOwn(item, 'id')) {
            setItemToEdit(null);
            const indexToUpdate = fields.findIndex(object => {return object.id === item.id})
            fields[indexToUpdate] = item;
            setValue(name, fields);
        } else {
            fields.push({...item, id: newItemId});
            setValue(name, fields);

            setNewItemId(prevState => prevState - 1);
        }
    };

    const handleEditOpenDialog = (id) => {
        setItemToEdit(
            fields.find(object => {return object.id === id})
        )
        setAddDialogOpen(true);
    }

    const handleRemoveFromEditDialog = (item) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    }

    return (
        <>
            <SliderToolbar
                label={label}
                labelButton={"Dodaj adres"}
                buttonAction={() => setAddDialogOpen(true)}
                handleClone={() => setCloneDialogOpen(true)}
                handleDelete={() => setDeleteDialogOpen(true)}
                cloneOrCopyButtonDisabled={cloneOrCopyButtonDisabled}
            />
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{borderTop: "1px solid" + color.black}}>
                            <TableCell padding={"checkbox"}></TableCell>
                            <TableCell>Url</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <Checkbox
                                        onClick={(e) => handleCheched(e, row.id)}
                                        checked={listCheckedItem.includes(row.id) ? true : false}
                                    />
                                </TableCell>
                                <TableCell>{row.link}</TableCell>
                                <TableCell>
                                    <Tooltip title={"Edytuj"} placement="bottom" arrow={true} sx={{mr: "10px"}}>
                                        <IconButton size={"small"} onClick={() => handleEditOpenDialog(row.id)}>
                                            <EditIcon fontSize={"small"} sx={{color: alpha(color.primary, 1)}}/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {fields?.length === 0 && <Typography>Brak elementów</Typography>}
            </TableContainer>
            <AddPageDialog
                open={addDialogOpen}
                handleConfirm={handleAddOrEditItem}
                handleRemove={handleRemoveFromEditDialog}
                itemToEdit={itemToEdit}
                handleClose={() => {
                    setAddDialogOpen(false);
                    setItemToEdit(null);
                }}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                close={handleCanelDelete}
                confirm={handleDelete}
            />
            <ConfirmationDialog
                open={cloneDialogOpen}
                close={() => setCloneDialogOpen(false)}
                confirm={handleClone}
                customTitle={"confirmation_modal_title_clone"}
            />
        </>
    );
}

export default PopupsItemsPanel;
