import React from "react";
import TableCell from "@mui/material/TableCell";
import { Avatar } from "@mui/material";
import getImage from "utils/imagesUrl";

const CellMiniature = ({data, handleClick, row}) => {
    return (
        <TableCell onClick={(e) => handleClick(e, row)}>
            <Avatar
                src={getImage(data)}
                sx={{width: 60, height: 40, borderRadius: 0,}}
            />
        </TableCell>
    );
};


export default CellMiniature;