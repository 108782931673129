import React, {Fragment} from "react";
import { Button, Paper} from "@mui/material";
import { useTranslation } from "react-i18next";

const NoLaboratoryFragment = ({data, fullView}) => {
    const {t} = useTranslation("common");
    const PATH_ORDER = "/shop/orders/";
    const PATH_GENETIC_TEST = "/home/inbox/genetic-tests/";
    const PATH_COMMENT = "/cms/comments/";

    return (
        <>
            {fullView && data?.attachments && <Paper sx={{p: 5, mb: 5}} variant="outlined">
                Załączniki: <br/>
                {data?.attachments.map((attach) =>
                    <Fragment key={attach.id}>
                        {attach.description == 'TESTORDER' && <><br/>
                            <Button
                                href={PATH_ORDER + attach.objectId}
                                size={"small"}
                                color="primary"
                            >
                                Zamówienie
                            </Button>
                        </>}
                        {attach.description == 'GENETICTEST' && <><br/>
                            <Button
                                href={PATH_GENETIC_TEST + attach.objectId}
                                size={"small"}
                                color="primary"
                            >
                                Zlecenie badania
                            </Button>
                        </>}
                        {attach.description == 'COMMENT' && <><br/>
                            <Button
                                href={PATH_COMMENT + attach.objectId}
                                size={"small"}
                                color="primary"
                            >
                                Komentarz
                            </Button></>}
                    </Fragment>
                )}
            </Paper>}
        </>
    );
};


export default NoLaboratoryFragment;