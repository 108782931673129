import { Grid, InputLabel, Paper } from "@mui/material";
import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import EditViewLanguage from "components/EditViewLanguage";

const endpoint = "/analytical-codes/";
const title = "settings_analytical_code_edit_label";
const moduleUrl = "/settings/codes/";

const AnalyticalCodesEdit = () => {
    const {id} = useParams();
    const current = useSelector(selectEditItem);
    const {t} = useTranslation("common");

    const validationSchema = Yup.object({
        codesBeforeHead: Yup.string().nullable(),
        codesBeforeBody: Yup.string().nullable(),
        codesAfterBody: Yup.string().nullable()
    });

    const defaultValue = {
        codesBeforeHead: '',
        codesBeforeBody: '',
        codesAfterBody: ''
    }

    const refForm = useRef(null);

    return (
        <>
            <EditViewLanguage
                endpoint={endpoint}
                title={title}
                moduleUrl={moduleUrl}
                validationSchema={validationSchema}
                refForm={refForm}
                noDeleteButton={true}
                noBackButton={true}
                itemWithoutId={true}
                defaultValue={defaultValue}
            >
                <FormData id={id} current={current} refForm={refForm}/>
            </EditViewLanguage>
        </>
    );
};

const FormData = ({id, current, refForm}) => {
    const {register, control, formState} = useFormContext();

    const {t} = useTranslation("common");

    return <>
        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <InputLabel htmlFor={"codesBeforeHead"}>
                        {"Head (przed znacznikiem </head>"}
                    </InputLabel>
                    <TextField
                        {...register("codesBeforeHead")}
                        multiline={true}
                        minRows={6}
                        helperText={formState?.errors["codesBeforeHead"] ? formState?.errors["codesBeforeHead"].message : ""}
                        error={!!formState?.errors["codesBeforeHead"]}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor={"codesAfterBody"}>
                        {"Body (za znacznikiem <body>"}
                    </InputLabel>
                    <TextField
                        {...register("codesAfterBody")}
                        multiline={true}
                        minRows={6}
                        helperText={formState?.errors["codesAfterBody"] ? formState?.errors["codesAfterBody"].message : ""}
                        error={!!formState?.errors["codesAfterBody"]}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor={"codesBeforeBody"}>
                        {"Body (przed znacznikiem </body>"}
                    </InputLabel>
                    <TextField
                        {...register("codesBeforeBody")}
                        multiline={true}
                        minRows={6}
                        helperText={formState?.errors["codesBeforeBody"] ? formState?.errors["codesBeforeBody"].message : ""}
                        error={!!formState?.errors["codesBeforeBody"]}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
        </Paper>
    </>;
};

export default AnalyticalCodesEdit;
