import React, { useState } from "react";
import { IconButton, TextField, Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

const CityRow = ({label, valueZipCode, valueCity, nameZipCode, nameCity}) => {
    const {setValue} = useFormContext();
    const {t} = useTranslation("common");
    const [editMode, setEditMode] = useState(false)
    const [newValueCity, setNewValueCity] = useState("");
    const [newValueZipCode, setNewValueZipCode] = useState("");
    const [errorZipCode, setErrorZipCode] = useState(false);
    const [errorCity, setErrorCity] = useState(false)

    const saveValue = () => {
        let isError = false;

        if(!newValueZipCode || newValueZipCode.size == 0) {
            isError = true;
            setErrorZipCode(true);
        } else {
            setErrorZipCode(false);
        }

        if(!newValueCity || newValueCity.size == 0) {
            isError = true;
            setErrorCity(true);
        } else {
            setErrorCity(false);
        }

        if(!isError) {
            setValue(nameCity, newValueCity);
            setValue(nameZipCode, newValueZipCode);
            setEditMode(false);
        }
    }

    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            {!editMode ? (
                <TableCell>
                    {valueZipCode ? valueZipCode : "" + " " + valueCity ? valueCity : ""}
                    <Tooltip title={"Edytuj"} placement="bottom" arrow={true} sx={{ml: "10px"}}>
                    <IconButton size={"small"} onClick={() => {
                        setNewValueZipCode(valueZipCode);
                        setNewValueCity(valueCity);
                        setEditMode(true)
                    }}>
                        <EditIcon fontSize={"small"}/>
                    </IconButton>
                    </Tooltip>
                </TableCell>
            ) : (
                <TableCell>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            defaultValue={valueZipCode}
                            size={"small"}
                            sx={{width: 2/6}}
                            onChange={(e) => {setNewValueZipCode(e.target.value)}}
                            helperText={errorZipCode ? t("form-validation-required") : ''}
                            error={errorZipCode}
                            onKeyDown = {(e) => {
                                if (e.key === 'Enter') {
                                    saveValue()
                                } else if (e.key === 'Escape') {
                                    setEditMode(false);
                                }
                            }}
                        />
                        <TextField
                            defaultValue={valueCity}
                            size={"small"}
                            sx={{width: 4/6}}
                            onChange={(e) => {setNewValueCity(e.target.value)}}
                            helperText={errorCity ? t("form-validation-required") : ''}
                            error={errorCity}
                            autoFocus
                            onKeyDown = {(e) => {
                                if (e.key === 'Enter') {
                                    saveValue()
                                } else if (e.key === 'Escape') {
                                    setEditMode(false);
                                }
                            }}
                        />
                        <Tooltip title={"Zapisz"} placement="bottom" arrow={true} sx={{ml: "10px"}}>
                            <IconButton size={"small"} onClick={saveValue}>
                                <DoneIcon fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            )}
        </TableRow>
    );
}

export default CityRow;
