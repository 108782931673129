const light = "300";
const regular = "400";
const medium = "500";
const bold = "700";

export {
  light,
  regular,
  medium,
  bold,
};
