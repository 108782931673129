import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CustomAutocompleteSelect from "components/Edit/CustomAutocompleteSelect";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as color from "assets/styles/jss/common/colors";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import ConfirmationDialog from "components/ConfirmationDialog";

const EditOrderDialog = ({ close, open, confirm, orderProducts}) => {
    const {t} = useTranslation("common");
    const [errors, setErrors] = useState({});
    const [products, setProducts] = useState([]);
    const [allActiveProducts, setAllActiveProducts] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [indexProductToDel, setIndexProductToDel] = useState(-1);

    useEffect(() => {
        backend.get("/product/getAllByLang/pl").then((res) => {
            if (isSuccess(res)) {
                if (res.data.length > 0) {
                    setAllActiveProducts(res.data.filter(item => {return item.active}))
                }
            }
        });
        return () => {
            setAllActiveProducts([])
        }
    }, []);

    useEffect(() => {
        if(open == true) {
            setErrors({});
            setProducts([...orderProducts]);
        }
    }, [open])

    useEffect(() => {
        if(!products || products?.length == 0) {
            setTotalCost(0);
        } else if (products?.length > 0) {
            let totalPrice = 0;
            for (const product of products) {
                if(product.retailPrice) {
                    totalPrice += (product.retailPrice * product.amount);
                }
            }
            setTotalCost(totalPrice);
        }
    }, [products])

    const testNoEmptyArray = (name, array) => {
        if (array?.length == 0) {
            setErrors((prevState) => ({
                    ...prevState,
                    [name]: {message: t("form-validation-required")}
                })
            )
            return true;
        }
        return false;
    }

    const addProducts = (product) => {
        let newProduct = {
            id: 0 - product.id,
            name: product.name,
            amount: 1,
            retailPrice: product.retailPrice,
            codes: product.codes
        }

        setProducts((prev) => [...prev, newProduct]);
    }

    const handleSubmit = () => {
        let isError = false
        setErrors({})

        testNoEmptyArray("products", products) ? isError = true : '';

        if (!isError) {
            setErrors({});
            confirm(products, totalCost);
        }
    }

    const handleDelete = (index) => {
        setIndexProductToDel(index);
        setIsDeleteDialogOpen(true);
    }

    const delProduct = () => {
        setProducts(prev => prev.slice(0,indexProductToDel).concat(prev.slice(indexProductToDel + 1)));
        setIsDeleteDialogOpen(false);
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={() => close()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Dodaj zamówienie</Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomAutocompleteSelect
                        name={"products"}
                        addProduct={addProducts}
                        allProducts={allActiveProducts}
                        label={"Wybierz badania"}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa produktu</TableCell>
                                    <TableCell align={"center"}>Ilość</TableCell>
                                    <TableCell align={"right"}>Wartość</TableCell>
                                    <TableCell align={"right"}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products?.map((product, index) => <TableRow key={index}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell align={"center"}>{product.amount}</TableCell>
                                    <TableCell align={"right"}>{product.retailPrice ? (product.amount * product.retailPrice)?.toFixed(2) : ''}</TableCell>
                                    <TableCell align={"right"}>
                                        <Tooltip title={"Usuń"} placement="right-end" arrow={true} sx={{mr: "10px"}}>
                                            <IconButton onClick={() => handleDelete(index)}>
                                                <DeleteIcon fontSize={"small"} sx={{color: alpha(color.primary, 1)}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)}
                                {products?.length > 0 && <TableRow>
                                    <TableCell align={"right"}  colSpan={3} sx={{ fontWeight: 'bold' }}>
                                        Suma: {totalCost?.toFixed(2)}
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>}
                                {products?.length === 0 && <TableRow>
                                    <TableCell align={"center"}  colSpan={4}>
                                        Brak produktów
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container sx={{mt: 5}}>
                <Grid item xs={6}>
                    <Button variant="outlined" color="default" onClick={() => {close()}}>
                        Anuluj
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Zapisz
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
        <ConfirmationDialog
            open={isDeleteDialogOpen}
            close={() => setIsDeleteDialogOpen(false)}
            confirm={delProduct}
        />
    </>;
};
export default EditOrderDialog;
