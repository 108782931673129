import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useFormContext } from "react-hook-form";

const currency = [
    {
        id: "PLN",
        displayName: "Polski Złoty"
    },
    {
        id: "EUR",
        displayName: "Euro"
    },
    {
        id: "CZK",
        displayName: "Korona Czeska"
    },
    {
        id: "HUF",
        displayName: "Forint Węgierski"
    }
]

const ShopConfiguration = () => {
    const {getValues} = useFormContext();
    const [allActiveFooters, setAllActiveFooters] = useState([]);
    const [allMenus, setAllMenus] = useState([]);

    useEffect(() => {
        backend.get("/footer/getAll").then((res) => {
            if (isSuccess(res)) {
                if (res.data.length > 0) {
                    const allFooters = res.data;
                    const activeFooters = allFooters.filter(item => {return !item.offline});
                    const footer = getValues("footerId");

                    if(!footer || activeFooters.find(function (element) {return element.id == footer;})) {
                        setAllActiveFooters(activeFooters);
                    } else if (allFooters.find(function (element) {return element.id == footer;})) {
                        setAllActiveFooters([...activeFooters, ...allFooters.filter(item => {return item.id == footer})] );
                    } else {
                        setAllActiveFooters([...activeFooters, {id: footer, displayName: "Item removed?", active: false}]);
                    }
                }
            }
        });
        return () => {
            setAllActiveFooters([])
        }
    }, []);

    useEffect(() => {
        backend.get("/cms-menu/getAll").then((res) => {
            if (isSuccess(res)) {
                if (res.data.length > 0) {
                    const menus = res.data;
                    const menu = getValues("mainMenuId");

                    if(!menu || menus.find(function (element) {return element.id == menu;})) {
                        setAllMenus(menus);
                    }  else {
                        setAllMenus([...menus, {id: menu, displayName: "Item removed?"}]);
                    }
                }
            }
        });
        return () => {
            setAllActiveFooters([])
        }
    }, []);

    return (
        <>
            <Accordion square defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{p: 0}}
                >
                    <Typography variant="h4" component={"h4"}>Ogólne</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0, mb: 5}}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <CustomizedTextField
                                label={"Nazwa"}
                                name={"name"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomizedTextField
                                label={"Url"}
                                name={"siteUrl"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomizedSelect
                                label={"Waluta"}
                                name={"currency"}
                                items={currency}
                                noNullValue
                                defaultValue={'EUR'}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion square defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{p: 0}}
                >
                    <Typography variant="h4" component={"h4"}>Metaznaki SEO</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0, mb: 5}}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12}>
                            <CustomizedTextField
                                label={"Domyślny Meta Title"}
                                name={"defaultMetaTitle"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomizedTextField
                                label={"Domyślny Meta Description"}
                                name={"defaultMetaDescription"}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion square defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{p: 0}}
                >
                    <Typography variant="h4" component={"h4"}>Szablon</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0, mb: 5}}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <CustomizedSelect
                                label={"Główne menu"}
                                name={"mainMenuId"}
                                items={allMenus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomizedSelect
                                label={"Stopka"}
                                name={"footerId"}
                                items={allActiveFooters}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomizedTextField
                                label={"Strona główna bloga"}
                                name={"blogPage"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomizedTextField
                                label={"Strona główna wszystkich badań"}
                                name={"productsPage"}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ShopConfiguration;
