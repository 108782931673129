import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const MoveToLabDialog = ({ open, action, products, handleClose, handleSubmit }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              {action == "RESET"
                ? "Resetuj hasło"
                : action == "MOVE"
                ? "Przenieś do laboratorium"
                : action == "BACK"
                ? "Cofnij do aktywacji"
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {products?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.productName}</TableCell>
                      <TableCell>{row.numberTest}</TableCell>
                      <TableCell align={"right"}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            handleSubmit(row, action);
                          }}
                        >
                          Wykonaj
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default MoveToLabDialog;
