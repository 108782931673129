import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Grid, InputLabel, Typography } from "@mui/material";
import Wysiwyg from "components/Edit/Wysiwyg";
import TabPanel from "components/Edit/CustomizedTabPanel";
import TextField from "@mui/material/TextField";
import ConfirmationDialog from "components/ConfirmationDialog";
import CustomizedTabsWithDelControled from "components/CustomizedTabsWithDelControled";
import { useSelector } from "react-redux";
import { selectListIsLoading } from "redux/slices/crud";

const ContentsTabsPanel = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);
    const [indexTabToDelete, setIndexTabToDelete] = useState(null)
    const [newTabId, setNewTabId] = useState(-1)
    const [fieldsIsToSort, setFieldsIsToSort] = useState(true);
    const {register, control, formState, setValue} = useFormContext();
    const isLoading = useSelector(selectListIsLoading);
    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        name: "tabs",
        control, // control props comes from useForm (optional: if you are using FormContext)
        keyName: "useFormkey"
    });


    //sort
    useEffect(() => {
        if(fieldsIsToSort && fields.length > 0) {
            let tmpFields = [...fields];
            tmpFields.sort((a, b) => {return a.sortOrder - b.sortOrder})
            remove();
            append(tmpFields);
        }
        setFieldsIsToSort(false)
    }, [fields])

    useEffect(() => {
        setFieldsIsToSort(true);
    }, [isLoading])

    const handleAddTab = () => {
        if(fields.length === 0) {
            setActiveTab(0);
        }

        let nextSortOrder = 0;

        if(fields.length > 0) {
            nextSortOrder = Math.max.apply(Math, fields.map(item => item.sortOrder)) + 1;
        }

        append({id: newTabId, name: 'Nowa zakładka', content: '', sortOrder: nextSortOrder})

        setNewTabId(newTabId - 1);
    }

    const handleOpenRemoveDialog = (tabToDeleteIndex) => {
        setIndexTabToDelete(tabToDeleteIndex)
        setOpenedRemoveDialog(true);
    }

    const handleRemoveTab = () => {
        remove(indexTabToDelete);

        if (indexTabToDelete <= activeTab && activeTab != 0) {
            setActiveTab(prevState => setActiveTab(prevState - 1));
        }

        setOpenedRemoveDialog(false);
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Button variant="contained" color="secondary" size="small" onClick={handleAddTab}>Dodaj zakładkę</Button>
                </Grid>
                {(!fields?.length > 0) ?
                    (<Grid item xs={12}>
                        <Typography>Brak zakładek</Typography>
                    </Grid>)
                    :
                    (!fieldsIsToSort &&
                        <Grid item xs={12}>
                            <CustomizedTabsWithDelControled
                                tabsList={fields}
                                activeTab={activeTab}
                                handleChange={(event, newValue) => {setActiveTab(newValue)}}
                                handleDel={handleOpenRemoveDialog}
                                titleName={"name"}
                            />
                        </Grid>)
                }
            </Grid>

            {!fieldsIsToSort && fields.map((field, index) => (
                <TabPanel key={field.id} value={activeTab} index={index}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor={`tabs.${index}.name`}>
                                {"Nazwa zakładki"}
                            </InputLabel>
                            <TextField
                                {...register(`tabs.${index}.name`)}
                                key={field.id} // important to include key with field's id
                                helperText={formState?.errors[`tabs.${index}.name`] ? formState?.errors[`tabs.${index}.name`].message : ""}
                                error={!!formState?.errors[`tabs.${index}.name`]}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Wysiwyg
                                label={"Treść strony"}
                                name={`tabs.${index}.content`}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
            ))}
            <ConfirmationDialog
                open={openedRemoveDialog}
                close={() => setOpenedRemoveDialog(false)}
                confirm={handleRemoveTab}
            />
        </>
    );
}

export default ContentsTabsPanel;
