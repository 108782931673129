import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Button, Grid, Typography } from "@mui/material";
import CodeTableRow from "components/Edit/DiscountCode/CodeTableRow";
import AddDiscountCodeDialog from "modules/Marketing/AddDiscountCodeDialog";

const DiscountItemsPanel = ({name}) => {
    const {control, setValue} = useFormContext();
    const {fields, append} = useFieldArray({
        name: name,
        control, // control props comes from useForm (optional: if you are using FormContext)
        keyName: 'idUseField',
    });
    const [itemToDelete, setItemToDelete]= useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [newItemId, setNewItemId] = useState(-1);
    const [itemToEdit, setItemToEdit] = useState(null);

    const handleDelete = () => {
        if(itemToDelete) {
            const index = fields.findIndex(object => {
                return object.id === itemToDelete.id;
            });

            fields.splice(index, 1);
            setValue(name, fields);

            setItemToDelete(null)
            setDeleteDialogOpen(false);

            return;
        }
    }

    const handleCanelDelete = () => {
        setDeleteDialogOpen(false)
        setItemToDelete(null);
    }

    const handleAddOrEditItem = (item) => {
        setAddDialogOpen(false);

        if(Object.hasOwn(item, 'id')) {
            setItemToEdit(null);
            const indexToUpdate = fields.findIndex(object => {return object.id === item.id})
            fields[indexToUpdate] = item;
            setValue(name, fields);
        } else {
            fields.push({...item, id: newItemId});
            setValue(name, fields);

            setNewItemId(prevState => prevState - 1);
        }
    };

    const handleEditOpenDialog = (id) => {
        setItemToEdit(
            fields.find(object => {return object.id === id})
        )
        setAddDialogOpen(true);
    }

    const handleRemoveFromEditDialog = (item) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    }

    return (
        <>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => setAddDialogOpen(true)}
                    >
                        Dodaj kod
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: 5 }}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            {fields.map((row) => (
                                <CodeTableRow
                                    key={row.id}
                                    row={row}
                                    handleEdit={() => handleEditOpenDialog(row.id)}
                                    handleRemove={() => handleRemoveFromEditDialog(row)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    {fields?.length === 0 && <Typography>Brak elementów</Typography>}
                </TableContainer>
            </Grid>
            <ConfirmationDialog
                open={deleteDialogOpen}
                close={handleCanelDelete}
                confirm={handleDelete}
            />
            <AddDiscountCodeDialog
                open={addDialogOpen}
                handleConfirm={handleAddOrEditItem}
                handleRemove={handleRemoveFromEditDialog}
                itemToEdit={itemToEdit}
                handleClose={() => {
                    setAddDialogOpen(false);
                    setItemToEdit(null);
                }}
            />
        </>
    );
}

export default DiscountItemsPanel;
