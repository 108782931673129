import { Grid, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import { fetchAvailableLangs, selectLanguages } from "redux/slices/language";

const endpoint = "/redirect/";
const title = "settings_redirects_edit_label";
const moduleUrl = "/settings/redirects/";

const redirectTypes = [
  { id: "FORWARD", displayName: "Forward" },
  { id: "REDIRECT_PERM", displayName: "Przekierowanie stałe (301)" },
  { id: "REDIRECT_TEMP", displayName: "Przekierowanie tymczasowe - (302) " },
];

const RedirectEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    incomingUrl: Yup.string().trim().required(t("form-validation-required")),
    newUrl: Yup.string().trim().required(t("form-validation-required")),
    typeRedirect: Yup.string().trim().required(t("form-validation-required")),
    langCode: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń przekierowanie"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const languages = useSelector(selectLanguages);
  const [allLanguagesSelect, setAllLanguagesSelect] = useState([]);

  useEffect(() => {
    dispatch(fetchAvailableLangs());
  }, []);

  useEffect(() => {
    if (languages?.length > 0) {
      const allLangs = [];
      for (const lang of languages) {
        allLangs.push({ id: lang.code, displayName: lang.name, sortOrder: lang.sortOrder });
      }
      setAllLanguagesSelect(
        allLangs.sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        }),
      );
    }
  }, [languages]);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={"Język"} name={"langCode"} items={allLanguagesSelect} defaultValue={"pl"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              label={"Typ Przekierowania"}
              name={"typeRedirect"}
              items={redirectTypes}
              defaultValue={"FORWARD"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Przychodzący URL"} name={"incomingUrl"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nowy URL"} name={"newUrl"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default RedirectEdit;
