import React from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { HEAD_CELL_TYPES } from "../../enums/headCellTypes";


const CellStatus = ({data, type, handleClick, row}) => {
    const {t} = useTranslation("common");

    return (
        <TableCell onClick={(e) => handleClick(e, row)}>
            {(data === 'PUBLISHED' || data === true) ? (
                (type === HEAD_CELL_TYPES.STATUS_INVERTED) ? t('status_unpublished') : t('status_published')
            ) : (
                (type === HEAD_CELL_TYPES.STATUS_INVERTED) ? t('status_published') : t('status_unpublished')
            )}
        </TableCell>
    );
};

export default CellStatus;