import Layout from "layout/Layout";
import React, {useEffect, useState} from "react";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import backend from "api/backend";
import {isSuccess} from "utils/http";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Paper, TableHead, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const ENDPOINT = "/inbox/genetic-test/";
const MODULE_URL = "/home/inbox/";

const GeneticTest = () => {
    const { t } = useTranslation("common");
    const [geneticTest, setGeneticTest] = useState([]);
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const {enqueueSnackbar} = useSnackbar();
    const {id} = useParams();


    useEffect(() => {
        const getData = () => {
            backend.get(ENDPOINT + id).then((res) => {
                if (isSuccess(res)) {
                    setGeneticTest(res.data);
                }
            }).catch(() => {
                enqueueSnackbar("Błąd pobierania danych", {
                    variant: "error",
                });}
            ).finally(() => {
                setIsLoading(false);
            });
        }
        getData();
    }, []);

    useEffect(() => {
        const makeAddress = () => {
            let line_1 = "";
            let line_2 = "";

            const adr = geneticTest?.address;

            if(adr) {
                line_1 += adr.street + " ";
                line_1 += adr.buildingNumber;
                line_1 += (adr.apartmentNumber && adr.apartmentNumber.sizes > 0) ?  "/" + geneticTest?.address?.apartmentNumber : "";

                if(geneticTest?.address?.apartmentNumber && geneticTest?.address?.apartmentNumber.sizes > 0) {
                    line_1 += "/" + geneticTest?.address?.apartmentNumber;
                }

                line_2 += geneticTest?.address?.zipCode + " ";
                line_2 += geneticTest?.address?.city;
            }

            return {line_1: line_1, line_2: line_2}
        }

        if(geneticTest) {
            const address = makeAddress();

            setAddressLine1(address.line_1);
            setAddressLine2(address.line_2);
        }
    }, [geneticTest])





    return <>
        <Layout
            header={t("inbox_genetic_test_label")}
            bottom={<EmptySection/>}
            top={<HeaderBuilder headerData={[]} isEditViewActive={true} moduleUrl={MODULE_URL}  />}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Paper sx={{p: 10, mb: 10}} elevation={8}>
                    <Accordion square defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{p: 0}}
                        >
                            <Typography variant="h4" component={"h4"}>Główne</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{p: 0, mb: 5}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: "25%" }} ></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Numer Badania
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{geneticTest?.geneticTestCode ? geneticTest.geneticTestCode : ""}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Status
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{geneticTest?.status ? geneticTest.status : ""}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{p: 0}}
                        >
                            <Typography variant="h4" component={"h4"}>Adres</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{p: 0, mb: 5}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: "25%" }} ></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Imię
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{geneticTest?.address?.firstName ? geneticTest?.address?.firstName : ""}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Nazwisko
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{geneticTest?.address?.lastName ? geneticTest?.address?.lastName : ""}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Adres
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{addressLine1}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Adres
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{addressLine2}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                E-Mail
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{geneticTest?.address?.emailAddress ? geneticTest?.address?.emailAddress : ""}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={"h6"}>
                                                Telefon
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{geneticTest?.address?.phoneNumber ? geneticTest?.address?.phoneNumber : ""}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            )}
        </Layout>
    </>
};
export default GeneticTest;
