import { Grid, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import CustomizedDateTimePickerMuiValidLocalTimeToUtc from "components/Edit/CustomizedDateTimePickerMuiValidLocalTimeToUtc";
import Wysiwyg from "components/Edit/Wysiwyg";
import CustomizedColorPicker from "components/Edit/CustomizedColorPicker";
import CustomizedIconChoice from "components/Edit/CustomizedIconChoice";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormContext } from "react-hook-form";
import CustomizedDatePickerMui from "components/Edit/CustomizedDatePickerMui";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";

const endpoint = "/banner/";
const title = "cms_banner_edit_label";
const moduleUrl = "/marketing/banners/";

const allTemplate = [
  { id: "TOP_NAVIGATION_BANNER", displayName: "Górny baner nawigacyjny" },
  // { id: "PROMOTION_BANNER", displayName: "Baner promocji" },
  { id: "PAGE_BANNER", displayName: "Baner na stronie" },
];

const allVisibility = [
  { id: "WHOLE_PAGE", displayName: "Cała strona" },
  { id: "MAIN_PAGE", displayName: "Strona główna" },
  { id: "SHOP", displayName: "Sklep" },
  { id: "MAIN_PAGE_AND_SHOP", displayName: "Strona główna i Sklep" },
  { id: "GUIDE", displayName: "Przewodnik" },
];

const allImagePosition = [
  { id: "RIGHT", displayName: "Prawa" },
  { id: "LEFT", displayName: "Lewa" },
];

const allPageBannerSize = [
  { id: "LARGE", displayName: "Duży" },
  { id: "LONG", displayName: "Długi" },
  { id: "SHORT", displayName: "Krótki" },
];

const BannerEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
    template: Yup.string().required(t("form-validation-required")),
    visibility: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      }),
    displayFrom: Yup.date()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER",
        then: Yup.date().typeError(t("form-validation-date")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER" || template === "PAGE_BANNER",
        then: Yup.date().nullable(),
      }),
    displayTo: Yup.date()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.date().typeError(t("form-validation-date")),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.date().nullable(),
      }),
    icon: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER",
        then: Yup.string(),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      }),
    iconAlt: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER",
        then: Yup.string(),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      }),
    iconColor: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      }),
    colorFont: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      }),
    linkColor: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      }),
    colorBackground: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      }),
    contents: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      }),
    promotionCode: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    promotionReduction: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PAGE_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PROMOTION_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    size: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    title: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    image: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    imagePosition: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    imageShift: Yup.number()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.number().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.number()
          .min(0, t("form-validation-required"))
          .typeError(t("form-validation-required"))
          .required(t("form-validation-required")),
      }),
    buttonTitle: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
    buttonHref: Yup.string()
      .when("template", {
        is: (template) => template === "TOP_NAVIGATION_BANNER" || template === "PROMOTION_BANNER",
        then: Yup.string().nullable(),
      })
      .when("template", {
        is: (template) => template === "PAGE_BANNER",
        then: Yup.string().required(t("form-validation-required")),
      }),
  });

  const defaultValue = {
    name: "",
    active: false,
    template: "TOP_NAVIGATION_BANNER",
    visibility: "WHOLE_PAGE",
    displayFrom: null,
    displayTo: null,
    icon: "",
    iconAlt: "",
    colorFont: "#fff",
    colorBackground: "#1ba8ce",
    iconColor: "#5c5c5c",
    contents: "",
    linkColor: "#fff",
    promotionCode: "",
    promotionReduction: "",
    size: "LARGE",
    title: "",
    image: "",
    imagePosition: "RIGHT",
    buttonTitle: "",
    buttonHref: "",
    imageShift: 0,
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = () => {
  const { t } = useTranslation("common");
  const { formState, watch, setValue } = useFormContext();
  const template = watch("template");
  const imageWatch = watch("image");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch name={"active"} rightLabel={t("cms_edit_banner_active")} topLabel={<>&nbsp;</>} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              label={t("cms_banner_template")}
              name={"template"}
              items={allTemplate}
              defaultValue={"TOP_NAVIGATION_BANNER"}
              noNullValue
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4" component={"h4"}>
              {t("cms_banner_edit_details")}
            </Typography>
          </Grid>
          {/*TOP NAVIGATOR BANNER START*/}
          {template === "TOP_NAVIGATION_BANNER" && (
            <>
              <Grid item xs={12} sm={12}>
                <CustomizedSelect
                  label={t("cms_banner_visibility")}
                  name={"visibility"}
                  items={allVisibility}
                  defaultValue={"pl"}
                  noNullValue
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedDateTimePickerMuiValidLocalTimeToUtc
                  label={t("cms_banner_display_from")}
                  name={"displayFrom"}
                  defaultEmpty
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedDateTimePickerMuiValidLocalTimeToUtc
                  label={t("cms_banner_display_to")}
                  name={"displayTo"}
                  defaultEmpty
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomizedIconChoice name={"icon"} label={t("cms_banner_icon")} />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomizedTextField label={t("cms_banner_icon_alt")} name={"iconAlt"} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <CustomizedColorPicker name={"colorBackground"} label={t("cms_banner_background_color")} />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomizedColorPicker name={"colorFont"} label={t("cms_banner_font_color")} />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomizedColorPicker name={"linkColor"} label={t("cms_banner_link_color")} />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomizedColorPicker name={"iconColor"} label={t("cms_banner_icon_color")} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg label={t("cms_banner_contents")} name={"contents"} simpleEditor />
                <FormHelperText error={true}>
                  {formState?.errors["contents"] ? formState?.errors["contents"].message : ""}
                </FormHelperText>
              </Grid>
            </>
          )}
          {/* TOP NAVIGATOR BANNER END */}
          {/* PROMOTION BANNER START */}
          {template === "PROMOTION_BANNER" && (
            <>
              <Grid item xs={12} sm={6}>
                <CustomizedDatePickerMui label={t("cms_banner_code_valid_to")} name="displayTo" defaultEmpty />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <CustomizedTextField label={t("cms_banner_reduction")} name={"promotionReduction"} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <CustomizedTextField label={t("cms_banner_code")} name={"promotionCode"} />
                </Grid>
              </Grid>
            </>
          )}
          {/* PROMOTION BANNER END */}
          {/* PAGE BANNER START*/}
          {template === "PAGE_BANNER" && (
            <>
              <Grid item xs={12}>
                <CustomizedTextField label={t("cms_banner_title")} name={"title"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={t("cms_banner_contents")} name={"contents"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedColorPicker name={"colorBackground"} label={t("cms_banner_background_color")} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedColorPicker name={"colorFont"} label={t("cms_banner_font_color")} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={t("cms_banner_button_title")} name={"buttonTitle"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={t("cms_banner_button_href")} name={"buttonHref"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect
                  label={t("cms_banner_size")}
                  name={"size"}
                  items={allPageBannerSize}
                  defaultValue={allPageBannerSize[0].id}
                  noNullValue
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect
                  label={t("cms_banner_position_image")}
                  name={"imagePosition"}
                  items={allImagePosition}
                  defaultValue={allImagePosition[0].id}
                  noNullValue
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedImagePicker
                  image={imageWatch}
                  setImage={(image) => setValue("image", image)}
                  label={t("cms_banner_image")}
                  placeholder={t("cms_banner_selected_image")}
                />
                <FormHelperText error sx={{ mt: "-15px", ml: "15px" }}>
                  {formState?.errors["image"] ? formState?.errors["image"].message : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={t("cms_banner_shift_image")} name="imageShift" type="number" />
              </Grid>
            </>
          )}
          {/* PAGE BANNER  END */}
        </Grid>
      </Paper>
    </>
  );
};

export default BannerEdit;
