import { Grid, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import Divider from "@mui/material/Divider";
import EditViewLanguage from "components/EditViewLanguage";

const endpoint = "/sms-setting/";
const title = "settings_sms_settings_label";
const moduleUrl = "/settings/sms/";

const SmsEdit = () => {
    const {id} = useParams();
    const current = useSelector(selectEditItem);
    const {t} = useTranslation("common");

    const validationSchema = Yup.object({
        sender: Yup.string().nullable(),
        gatewayUrl: Yup.string().nullable(),
        userName: Yup.string().nullable(),
        password: Yup.string().nullable(),
        senderId: Yup.string().nullable(),
        key: Yup.string().nullable()
    });

    const defaultValue = {
        sender: '',
        gatewayUrl: '',
        userName: '',
        password: '',
        senderId: '',
        key: ''
    };

    const refForm = useRef(null);

    return (
        <>
            <EditViewLanguage
                endpoint={endpoint}
                title={title}
                moduleUrl={moduleUrl}
                validationSchema={validationSchema}
                refForm={refForm}
                noDeleteButton={true}
                noBackButton={true}
                itemWithoutId={true}
                defaultValue={defaultValue}
            >
                <FormData id={id} current={current} refForm={refForm}/>
            </EditViewLanguage>
        </>
    );
};

const FormData = ({id, current, refForm}) => {
    const {t} = useTranslation("common");

    return <>
        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h4" component={"h4"}>{"Ustawienia"}</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Nadawca"}
                        name={"sender"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"URL bramki SMS"}
                        name={"gatewayUrl"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Nazwa użytkowinia"}
                        name={"userName"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Hasło"}
                        name={"password"}
                        type={"password"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Numer/ID nadawcy"}
                        name={"senderId"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Klucz klienta"}
                        name={"key"}
                    />
                </Grid>
            </Grid>
        </Paper>
    </>
};

export default SmsEdit;
