import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const DialogSelect = ({label, name, errors, items, disabled = false, value, setValue}) => {
    return (
        <>
            <Grid item xs={12}>
                <InputLabel htmlFor={name}>
                    {label}
                </InputLabel>
                <FormControl fullWidth size={"small"}>
                    <Select
                        fullWidth
                        id={name}
                        name={name}
                        elpertext={errors[name] ? errors[name].message : ""}
                        error={!!errors[name]}
                        disabled={disabled}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    >
                        {items.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.displayName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
}

export default DialogSelect;
