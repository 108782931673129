import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  tableCellClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import MoveToLabDialog from "modules/ShopOrders/MoveToLabDialog";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import LabeledRow from "components/Edit/Order/LabeledRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CityRow from "components/Edit/Order/CityRow";
import StreetRow from "components/Edit/Order/StreetRow";
import FormHelperText from "@mui/material/FormHelperText";
import EditOrderDialog from "modules/ShopOrders/EditOrderDialog";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import NewCodeDialog from "modules/ShopOrders/NewCodeDialog";

const actions = [
  {
    id: "EMPTY",
    displayName: "-- wybierz akcję --",
  },
  {
    id: "MOVE",
    displayName: "Przenieś do laboratorium",
  },
  {
    id: "BACK",
    displayName: "Cofnij do aktywacji",
  },
  {
    id: "RESET",
    displayName: "Zresetuj hasło",
  },
];

const endpoint = "/order/";
const title = "shop_order_edit_label";
const moduleUrl = "/shop/orders/";

const OrderEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    client: Yup.object({
      firstName: Yup.string().trim().required(t("form-validation-required")),
      lastName: Yup.string().trim().required(t("form-validation-required")),
      mail: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
      phoneNumber: Yup.string().nullable(),
      deliveryStreet: Yup.string().nullable(),
      deliveryBuildingNumber: Yup.string().nullable(),
      deliveryApartmentNumber: Yup.string().nullable(),
      deliveryZipCode: Yup.string().nullable(),
      deliveryCity: Yup.string().nullable(),
      invoiceStreet: Yup.string().nullable(),
      invoiceBuildingNumber: Yup.string().nullable(),
      invoiceApartmentNumber: Yup.string().nullable(),
      invoiceZipCode: Yup.string().trim().nullable(),
      invoiceCity: Yup.string().trim().nullable(),
      invoiceCompany: Yup.string().nullable(),
      invoiceVatNumber: Yup.string().nullable(),
    }),
    products: Yup.array(
      Yup.object({
        id: Yup.number(),
        amount: Yup.number(),
      }),
    ),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Zamówienie"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { setValue, watch, formState } = useFormContext();
  const { t } = useTranslation("common");
  const [resetResult, setResetResult] = useState("");
  const watchTotalCost = watch("totalCost");
  const watchClient = watch("client");
  const watchProducts = watch("products");
  const watchDiscountCodes = watch("discountCodes");
  const watchTestsNumbers = watch("testsNumbers");
  const [actionValue, setActionValue] = useState("EMPTY");
  const [actionSelectError, setActionSelectError] = useState(false);
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isResetResultDialogOpen, setIsResetResultDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    if (actionValue == "EMPTY") {
      setActionSelectError(true);
    } else {
      setIsActionDialogOpen(true);
    }
  };

  const handleAction = (product, action) => {
    let url = "/maintenance/";

    console.log(product);
    console.log(action);

    setIsActionDialogOpen(false);

    switch (action) {
      case "MOVE":
        url += "toLab";
        break;
      case "RESET":
        url += "toResetPassword";
        setResetResult("");
        break;
      case "BACK":
        url += "toActivation";
        break;
      default:
        return;
    }

    console.log(url);

    const d = {
      numberOfTest: product.numberTest,
    };

    backend.post(url, d).then((res) => {
      if (isSuccess(res)) {
        console.log(res.data);

        if (action == "RESET") {
          setResetResult(res.data.message);
          setIsResetResultDialogOpen(true);
        } else {
          enqueueSnackbar(res.data.message, {
            variant: "success",
          });
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (actionValue != "EMPTY") {
      setActionSelectError(false);
    }
  }, [actionValue]);

  const handleEditProducts = (products, totalCost) => {
    setValue("products", products);
    setValue("totalCost", totalCost);
    setIsEditDialogOpen(false);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={3}>
            <InputLabel> Numer zamówienia: </InputLabel>
            <Typography> {current?.orderNumber} </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <InputLabel> Data: </InputLabel>
            <Typography> {current?.created} </Typography>
          </Grid>
          <Grid item container xs={12} sm={6}>
            <Grid item xs={9}>
              <InputLabel htmlFor={name}>{"Dostępne akcje"}</InputLabel>
              <Select
                fullWidth
                value={actionValue}
                onChange={(e) => setActionValue(e.target.value)}
                error={actionSelectError}
                displayEmpty={false}
                disabled={watchTestsNumbers?.length == 0}
              >
                {actions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true}>{actionSelectError ? "Wybierz akcję" : ""}</FormHelperText>
            </Grid>
            <Grid item>
              <InputLabel>&nbsp;</InputLabel>
              <IconButton
                disabled={watchTestsNumbers?.length == 0}
                size={"small"}
                onClick={handleOpenDialog}
                sx={{ ml: "10px", background: color.secondary, "&:hover": { background: color.secondaryHover } }}
              >
                <DownloadDoneIcon sx={{ color: color.white }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Zamówienie
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={5}>
                  <Grid item xs={12} sm={12} md={9}>
                    <TableContainer
                      sx={{
                        border: "1px solid " + alpha(color.secondary, 0.4),
                        background: alpha(color.secondary, 0.1),
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Zamówione produkty:</TableCell>
                            <TableCell>Numer badania</TableCell>
                            <TableCell align={"right"}>Wartość</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {watchProducts?.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.codes}</TableCell>
                              <TableCell align={"right"}>{(row.amount * row.retailPrice).toFixed(2) + " zł"}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      variant={"outlined"}
                      size={"small"}
                      sx={{ mt: 5 }}
                      onClick={() => setIsEditDialogOpen(true)}
                      disabled
                    >
                      Edytuj zamówienie
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} className={"p-align-right"}>
                    <Typography variant={"h5"}>Total:</Typography>
                    <Typography variant={"h2"}>{watchTotalCost?.toFixed(2)}</Typography>
                    <Divider light sx={{ my: 3 }} />
                    {watchDiscountCodes?.length > 0 && (
                      <>
                        <Chip color={"success"} label={"Wykorzystano kod: " + watchDiscountCodes} />
                      </>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Dane klienta
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TableContainer>
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableBody>
                          <LabeledRow label={"Id klienta"} value={watchClient?.id} noEditable />
                          <LabeledRow
                            label={"Imię"}
                            value={watchClient?.firstName}
                            name={"client.firstName"}
                            validateNoEmpty
                          />
                          <LabeledRow label={"Email"} value={watchClient?.mail} name={"client.mail"} validateMail />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TableContainer>
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableBody>
                          <LabeledRow label={""} value={""} noEditable />
                          <LabeledRow
                            label={"Nazwisko"}
                            value={watchClient?.lastName}
                            name={"client.lastName"}
                            validateNoEmpty
                          />
                          <LabeledRow label={"Telefon"} value={watchClient?.phoneNumber} name={"client.phoneNumber"} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Dane dostawy
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableBody>
                      <LabeledRow
                        label={"Imię"}
                        value={watchClient?.firstName}
                        name={"client.firstName"}
                        validateNoEmpty
                      />
                      <LabeledRow
                        label={"Nazwisko"}
                        value={watchClient?.lastName}
                        name={"client.lastName"}
                        validateNoEmpty
                      />
                      <StreetRow
                        label={"Ulica"}
                        valueStreet={watchClient?.deliveryStreet}
                        valueBuildingNumber={watchClient?.deliveryBuildingNumber}
                        valueApartmentNumber={watchClient?.deliveryApartmentNumber}
                        nameStreet={"client.deliveryStreet"}
                        nameBuildingNumber={"client.deliveryBuildingNumber"}
                        nameApartmentNumber={"client.deliveryApartmentNumber"}
                      />
                      <CityRow
                        label={"Miasto"}
                        valueZipCode={watchClient?.deliveryZipCode}
                        valueCity={watchClient?.deliveryCity}
                        nameZipCode={"client.deliveryZipCode"}
                        nameCity={"client.deliveryCity"}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Dane do faktury
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableBody>
                      <LabeledRow
                        label={"Nazwa Firmy"}
                        value={watchClient?.invoiceCompany}
                        name={"client.invoiceCompany"}
                      />
                      <LabeledRow
                        label={"NIP / VAT ID"}
                        value={watchClient?.invoiceVatNumber}
                        name={"client.invoiceVatNumber"}
                      />
                      <StreetRow
                        label={"Ulica"}
                        valueStreet={watchClient?.invoiceStreet}
                        valueBuildingNumber={watchClient?.invoiceBuildingNumber}
                        valueApartmentNumber={watchClient?.invoiceApartmentNumber}
                        nameStreet={"client.invoiceStreet"}
                        nameBuildingNumber={"client.invoiceBuildingNumber"}
                        nameApartmentNumber={"client.invoiceApartmentNumber"}
                      />
                      <CityRow
                        label={"Miasto"}
                        valueZipCode={watchClient?.invoiceZipCode}
                        valueCity={watchClient?.invoiceCity}
                        nameZipCode={"client.invoiceZipCode"}
                        nameCity={"client.invoiceCity"}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      <MoveToLabDialog
        open={isActionDialogOpen}
        action={actionValue}
        handleClose={() => setIsActionDialogOpen(false)}
        handleSubmit={handleAction}
        products={watchTestsNumbers}
      />
      <EditOrderDialog
        open={isEditDialogOpen}
        close={() => setIsEditDialogOpen(false)}
        confirm={handleEditProducts}
        orderProducts={watchProducts}
      />
      <NewCodeDialog
        open={isResetResultDialogOpen}
        close={() => {
          setIsResetResultDialogOpen(false);
          setResetResult("");
        }}
        text={resetResult}
      />
    </>
  );
};

export default OrderEdit;
