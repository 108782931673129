import React, { useEffect, useState } from "react";
import { Select } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CriteriaDataTypeCell = ({ row, index, listCriteria, listName, typesCondition, mainOperator }) => {
  const { t } = useTranslation("common");
  const { setValue } = useFormContext();
  const [dataType, setDataType] = useState(null);

  const operatorNumber = [
    { id: "EQUALS", name: t("price_reduction_criteria_operator_equals"), twoValue: false },
    { id: "NOT_EQUAL", name: t("price_reduction_criteria_operator_not_equals"), twoValue: false },
  ];

  const operatorText = [
    { id: "IEQUALS", name: t("price_reduction_criteria_operator_iequals") },
    { id: "INOT_EQUAL", name: t("price_reduction_criteria_operator_inot_equal") },
    { id: "ICONTAINS", name: t("price_reduction_criteria_operator_icontains") },
    { id: "INOT_CONTAINS", name: t("price_reduction_criteria_operator_inot_contains") },
    { id: "ISTARTS_WITH", name: t("price_reduction_criteria_operator_istarts_with") },
    { id: "INOT_STARTS_WITH", name: t("price_reduction_criteria_operator_inot_starts_with") },
    { id: "IENDS_WITH", name: t("price_reduction_criteria_operator_iends_with") },
    { id: "INOT_ENDS_WITH", name: t("price_reduction_criteria_operator_inot_ends_with") },
  ];

  const setOperator = (newValue, index) => {
    listCriteria[index].operator = newValue;
    setValue(listName, listCriteria);
  };

  const setTextValue = (newValue, index) => {
    listCriteria[index].stringValue = newValue;
    listCriteria[index].numberFirstValue = "";
    listCriteria[index].numberSecondValue = "";
    setValue(listName, listCriteria);
  };

  const setNumberFirstValue = (newValue, index) => {
    listCriteria[index].numberFirstValue = newValue;
    listCriteria[index].StringValue = "";
    setValue(listName, listCriteria);
  };

  useEffect(() => {
    if (row.type === "product_id" && mainOperator === "ANY" && row.operator !== "EQUALS") {
      setOperator("EQUALS", index);
    } else if (row.type === "product_id" && mainOperator === "ALL" && row.operator !== "NOT_EQUAL") {
      setOperator("NOT_EQUAL", index);
    }
  }, [mainOperator, row.type]);

  useEffect(() => {
    if (row?.type !== "") {
      for (const type of typesCondition) {
        if (type.id === row.type) {
          setDataType(type.type);
        }
      }
    }
  }, [row, dataType]);

  if (dataType === "number" || dataType === "integer") {
    return (
      <>
        <TableCell>
          <FormControl size={"small"} fullWidth>
            <Select
              native
              id={"equalCondition-" + row.id}
              name={"equalCondition-" + row.id}
              value={row.operator}
              onChange={(e) => setOperator(e.target.value, index)}
              disabled={row.type === "product_id"}
            >
              {operatorNumber.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <TextField
            size={"small"}
            fullWidth
            onBlur={(e) => setNumberFirstValue(e.target.value, index)}
            defaultValue={row.numberFirstValue}
            type={"number"}
            inputProps={{ min: 0 }}
          />
        </TableCell>
        <TableCell />
      </>
    );
  } else if (dataType === "text") {
    return (
      <>
        <TableCell>
          <FormControl size={"small"} fullWidth>
            <Select
              native
              id={"equalCondition-" + row.id}
              name={"equalCondition-" + row.id}
              value={row.operator}
              onChange={(e) => setOperator(e.target.value, index)}
            >
              {operatorText.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <TextField
            size={"small"}
            defaultValue={row.stringValue}
            onBlur={(e) => setTextValue(e.target.value, index)}
            fullWidth
          />
        </TableCell>
        <TableCell />
      </>
    );
  } else {
    return <></>;
  }
};

export default CriteriaDataTypeCell;
