import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

const CustomAutocompleteSelect = ({label, allProducts, addProduct, name, errors}) => {
    const [actualValue, setActualValue] = useState(null)
    const [actualInputValue, setActualInputValue] = useState("")

    const handleChange = (value) => {
        addProduct(value);
        setActualValue(null);
        setActualInputValue("");
    }

        return (
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allProducts}
                getOptionLabel={(option) => option.name}
                inputValue={actualInputValue}
                value={actualValue}
                onChange={(e, newValue) => handleChange(newValue)}
                onInputChange={(e) => setActualInputValue(e.target.value)}
                renderInput={(params) => <TextField
                    {...params}
                    label={label}
                    fullWidth
                    helperText={errors[name] ? errors[name].message : ""}
                    error={!!errors[name]}
                />}
            />
      );
}

export default CustomAutocompleteSelect;