import React from "react";
import TableCell from "@mui/material/TableCell";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const CellAttachment = ({data, handleClick, row}) => {
    return (
        <TableCell onClick={(e) => handleClick(e, row)}>
            {data && <AttachFileIcon fontSize={"small"} sx={{color: alpha(color.black, 0.7)}}/>}
        </TableCell>
    );
};

export default CellAttachment;