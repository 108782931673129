import React from "react";
import TableCell from "@mui/material/TableCell";
import { ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CellLanguageName = ({data}) => {
    const {t} = useTranslation("common")

    const changeLink = <Link>zmień na domyślny</Link>

    return (
        <TableCell>
            <ListItem>
                {data?.default && <ListItemText primary={data?.name} secondary={"Domyślny"}/>}
                {!data?.default && <ListItemText primary={data?.name} secondary={changeLink}/>}
            </ListItem>
        </TableCell>
    );
};


export default CellLanguageName;