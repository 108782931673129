import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


const CellLanguageActions = ({data, row, languages,  handleClick}) => {
    const {t} = useTranslation("common");

    const [langs, setLangs] = useState([]);

    useEffect(() => {
        if(languages) {
            setLangs([...languages].sort((a, b) => {return a - b}));
        }
    }, [languages])

    return (
        <>
            {langs?.sort((a, b) => {
                return a.sortOrder - b.sortOrder
            }).map((language) => {
                if(data && data?.find(lang => {return language.code === lang.lang})) {
                    return(
                        <TableCell key={language.code} padding={"checkbox"}>
                            <Tooltip title={t("table_language_actions_edit") + " (" + language.code.toUpperCase() + ")"} placement="bottom" arrow={true} sx={{mr: "10px"}}>
                                <IconButton size={"small"} onClick={(e) => handleClick(e, row, language.code)}>
                                    <EditIcon fontSize={"small"} sx={{color: alpha(color.primary, 1)}}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        )
                } else {
                    return (
                        <TableCell key={language.code} padding={"checkbox"}>
                            <Tooltip title={t("table_language_actions_add")  + " (" + language.code.toUpperCase() + ")"} placement="bottom" arrow={true} sx={{mr: "10px"}}>
                                <IconButton size={"small"} onClick={(e) => handleClick(e, row, language.code)}>
                                    <AddCircleOutlineIcon fontSize={"small"} sx={{color: alpha(color.black, 0.4)}}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        )
                }
            })}
        </>
    );
};

export default CellLanguageActions;