import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import DataTable from "components/Table/DataTable";

const toolbarItems = [
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 0,
    type: "FIELDS_SEARCH",
    searchFieldName: "email:like",
  },
];

const ComplaintsList = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Layout
        header={t("shop_returns_and_complaints_label")}
        bottom={<EmptySection />}
        top={<HeaderBuilder headerData={toolbarItems} />}
      >
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <DataTable isClickable={false} modulePath={"/shop/complaints"} endpoint={"/complaint-logs/"} />
        </Paper>
      </Layout>
    </>
  );
};
export default ComplaintsList;
