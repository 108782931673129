import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import { useFormContext } from "react-hook-form";
import LanguagePanel from "components/Edit/DiscountCode/LanguagePanel";
import CustomizedDateTimePickerMuiValidLocalTimeToUtc from "components/Edit/CustomizedDateTimePickerMuiValidLocalTimeToUtc";
import Criteria from "components/Edit/PriceReductions/Criteria";

const endpoint = "/price-reduction/";
const title = "marketing_price_reductions_edit_label";
const moduleUrl = "/marketing/price-reduces/";

const PriceReducesEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    description: Yup.string().nullable(),
    languages: Yup.array(),
    typeDiscount: Yup.string().trim().required(t("form-validation-required")),
    discountValue: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .when("typeDiscount", {
        is: "PERCENT_OFF",
        then: Yup.number()
          .typeError(t("form-validation-number"))
          .positive(t("form-validation-number"))
          .max(100, t("form-validation-max-100"))
          .required(t("form-validation-required")),
      }),
    validFrom: Yup.date().typeError(t("form-validation-date")),
    validUntil: Yup.date().typeError(t("form-validation-date")),
    isProductCriteriaActive: Yup.bool(),
    productCriteriaList: Yup.array().nullable(),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Promocję"}
      >
        <FormData id={id} current={current} refForm={refForm} t={t} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ t }) => {
  const { watch } = useFormContext();
  const watchIsProductCriteriaActive = watch("isProductCriteriaActive");

  const typesDiscount = [
    { id: "PERCENT_OFF", displayName: t("price_reduction_type_discount_percent_off") },
    { id: "AMOUNT_OFF", displayName: t("price_reduction_type_discount_amount_off") },
  ];

  const typesConditionProduct = [
    { id: "name", name: t("price_reduction_product_type_condition_name"), type: "text" },
    { id: "product_id", name: t("price_reduction_product_type_condition_id"), type: "integer" },
    { id: "category_name", name: t("price_reduction_product_type_condition_name_category"), type: "text" },
    { id: "category_url", name: t("price_reduction_product_type_condition_url_category"), type: "text" },
  ];

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("price_reduction_edit_name")} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("price_reduction_edit_description")} name={"description"} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LanguagePanel />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              label={t("price_reduction_edit_promotion_type")}
              name={"typeDiscount"}
              items={typesDiscount}
              defaultValue={"PERCENT_OFF"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              label={t("price_reduction_edit_discount_value")}
              name={"discountValue"}
              type={"number"}
              step={0.01}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiValidLocalTimeToUtc
              label={t("price_reduction_edit_promotion_from")}
              name={"validFrom"}
              defaultEmpty
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiValidLocalTimeToUtc
              label={t("price_reduction_edit_promotion_until")}
              name={"validUntil"}
              defaultEmpty
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              {t("price_reduction_edit_criteria")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomizedSwitch
                  topLabel={""}
                  rightLabel={t("price_reduction_edit_product_criteria")}
                  name={"isProductCriteriaActive"}
                  defaultValue={false}
                />
              </Grid>
              {watchIsProductCriteriaActive && (
                <Grid item xs={12}>
                  <Criteria
                    typesCondition={typesConditionProduct}
                    mainListName={"productCriteriaList"}
                    listName={"criteriaItemList"}
                    mainOperatorName={"productCriteria"}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
};

export default PriceReducesEdit;
