import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { ListItemText } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MoveCell from "components/Edit/DnD/MoveCell";

export const RowDnDProductInCategory = ({ id, primaryText, secondaryText, index, handleMoveItem }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      handleMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.15 : 1;
  drag(drop(ref));
  return (
    <TableRow style={{ opacity }} ref={preview} data-handler-id={handlerId}>
      <TableCell>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </TableCell>
      <MoveCell drag={ref} />
    </TableRow>
  );
};
