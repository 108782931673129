import React from "react";
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import { useFormContext, useWatch } from "react-hook-form";

const DisplayName = ({ control, index }) => {
    const data = useWatch({
        control,
        name: `tabs.${index}`
    });
    return <>{data?.name}</>;
};

const CustomizedTabsWithDelControled = ({tabsList, activeTab, handleChange, handleDel, titleName = "title"}) => {
    const {register, control, formState, setValue} = useFormContext();

    return (
        <List component={Stack} direction="row" sx={{borderBottom: "1px solid " + alpha(color.primary, 0.15), mb: 4, pb: 0}}>
            {tabsList.map((item, index) =>
                <ListItem key={item.id}
                          sx={{
                              width: "auto",
                              marginBottom: 0,
                              marginLeft: 0,
                              marginRight: "10px",
                              paddingBottom: 0,
                              paddingTop: 0,
                              paddingLeft: 0,
                              border: "1px solid " + alpha(color.primary, 0.15),
                              borderBottom: 0,
                              borderRadius: "8px 8px 0 0",
                              maxHeight: 44,
                          }}
                          secondaryAction={
                              <IconButton edge="end" aria-label="delete" onClick={() => handleDel(index)}>
                                  <DeleteIcon fontSize="small"/>
                              </IconButton>
                          }>
                    <ListItemButton
                        sx={{
                            maxHeight: 44,
                            borderRadius: "8px 0 0 0",
                        }}
                        onClick={(e) => handleChange(e, index)}
                        selected={activeTab === index}
                    >
                        <ListItemText primary={
                            <Typography variant="body2" sx={{fontWeight: "bold"}}>
                                <DisplayName control={control} index={index} />
                            </Typography>}
                        />
                    </ListItemButton>
                </ListItem>
            )}
        </List>
    );
}

export default CustomizedTabsWithDelControled;
