import * as color from "assets/styles/jss/common/colors";
import * as font from "assets/styles/jss/common/fonts";

const submenuGroupStyles = {
  submenuGroup: {
    lineHeight: 3,
    color: "rgba(255, 255, 255, 0.4)",
    letterSpacing: -0.1,
    fontSize: 12,
    fontWeight: font.regular,
  },
  submenuGroup__li: {
    listStyleType: "none",
    fontSize: 13.6,
    color: color.white,
    fontWeight: font.bold,
    paddingLeft: "30px !important",
    display: "flex",
    alignItems: "center",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    "&:hover": {
      backgroundColor: color.secondaryHover,
      cursor: 'pointer',
    },
    "@media (max-width: 960px)": {
      "&:hover": {
        background: color.secondaryHover,
      },
      "&:hover svg": {
        display: "block !important",
      },
    },
  },
  submenuLink: {
    color: color.white,
    textDecoration: "none",
  },
  arrow: {
    alignItems: "center",
    color: color.white,
    display: "none !important",
    height: 25,
    marginLeft: "auto",
    marginRight: 10,
    width: 28,
  },
  clickedSubmenu: {
    background: color.secondaryHover,
    "& svg": {
      display: "block !important",
    },
  },
};

export default submenuGroupStyles;
