import { Grid, Paper } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import BasicConfiguration from "components/Edit/Configuration/BasicConfiguration";
import CustomizedTabsConfiguration from "components/Edit/Configuration/CustomizedTabsConfiguration";
import ShopConfiguration from "components/Edit/Configuration/ShopConfiguration";
import ContentConfiguration from "components/Edit/Configuration/ContentConfiguration";
import CustomizedTabPanel from "components/Edit/CustomizedTabPanel";
import EditViewLanguage from "components/EditViewLanguage";

const endpoint = "/site-configs/";
const title = "settings_configuration_edit_label";
const moduleUrl = "/settings/configuration/";

const tabs = [
  {
    id: 0,
    displayName: "Dane podstawowe",
  },
  {
    id: 1,
    displayName: "Ustawienia sklepu",
  },
  {
    id: 2,
    displayName: "Zarządzanie treścią",
  },
];

const ConfigurationEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    /* Basic information */
    vendorName: Yup.string().nullable(),
    vendorAddress: Yup.string().nullable(),
    vendorZipCode: Yup.string().nullable(),
    vendorCity: Yup.string().nullable(),
    vendorCountry: Yup.string().nullable(),
    vendorNip: Yup.string().nullable(),
    vendorAccount: Yup.string().nullable(),
    /* Shop */
    name: Yup.string().nullable(),
    siteUrl: Yup.string().nullable(),
    currency: Yup.string().trim().required(t("form-validation-required")),
    defaultMetaTitle: Yup.string().nullable(),
    defaultMetaDescription: Yup.string().nullable(),
    mainMenuId: Yup.string().nullable(),
    footerId: Yup.string().nullable(),
    blogPage: Yup.string().nullable(),
    productsPage: Yup.string().nullable(),

    /* Content Management */
    categories: Yup.array(),
    menuBoxes: Yup.array(),
    boxes: Yup.array(),
    products: Yup.array(),
    topCategories: Yup.array(),
    articles: Yup.array(),
    faqId: Yup.string().nullable(),
    sliderTopId: Yup.string().nullable(),
    sliderBottomId: Yup.string().nullable(),
    faqs: Yup.array(),
    mainPageBanners: Yup.array(),
    frequentlySearcheds: Yup.array(),
  });

  const defaultValue = {
    /* Basic information */
    vendorName: "",
    vendorAddress: "",
    vendorZipCode: "",
    vendorCity: "",
    vendorCountry: "",
    vendorNip: "",
    vendorAccount: "",
    /* Shop */
    name: "",
    siteUrl: "",
    currency: "EUR",
    defaultMetaTitle: "",
    defaultMetaDescription: "",
    mainMenuId: "",
    footerId: "",
    blogPage: "",
    productsPage: "",
    /* Content Management */
    categories: [],
    menuBoxes: [],
    boxes: [],
    products: [],
    topCategories: [],
    articles: [],
    faqId: null,
    sliderTopId: null,
    sliderBottomId: null,
    faqs: [],
    mainPageBanners: [],
    frequentlySearcheds: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        noDeleteButton={true}
        noBackButton={true}
        itemWithoutId={true}
        defaultValue={defaultValue}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={3} sm={3}>
            <CustomizedTabsConfiguration
              activeTab={activeTab}
              handleChange={(e, index) => setActiveTab(index)}
              tabsList={tabs}
            />
          </Grid>
          <Grid item xs={9} sm={9}>
            <CustomizedTabPanel value={activeTab} index={0}>
              <BasicConfiguration />
            </CustomizedTabPanel>
            <CustomizedTabPanel value={activeTab} index={1}>
              <ShopConfiguration />
            </CustomizedTabPanel>
            <CustomizedTabPanel value={activeTab} index={2}>
              <ContentConfiguration />
            </CustomizedTabPanel>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ConfigurationEdit;
