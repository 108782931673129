import axios from "axios";
import getBackendHost from "utils/backendUrl";

const backend = axios.create({
    withCredentials: true,
    baseURL: getBackendHost(),
    validateStatus: function (status) {
        return true;
    },
});

export default backend;

