import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, List, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import { useFormContext, useWatch } from "react-hook-form";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CustomizedListItem from "components/Edit/CustomizedListItem";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import BoxesMainPage from "components/Settings/Box/BoxesMainPage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RowDnDProductInCategory } from "components/Edit/DnD/RowDnDProductInCategory";
import BoxesMenu from "components/Settings/Box/BoxesMenu";
import FrequentlySearchedPhrazePanel from "components/Edit/Configuration/FrequentlySearchedPhrazePanel";

const MAX_SHORT_LIST = 3;

const ContentConfiguration = () => {
  const { control, getValues, setValue } = useFormContext();

  const [allActiveCategories, setAllActiveCategories] = useState([]);
  const [isOpenDialogChoiceCategories, setIsOpenDialogChoiceCategories] = useState(false);
  const [isMoreCategories, setIsMoreCategories] = useState(false);

  const [allActiveProducts, setAllActiveProducts] = useState([]);
  const [isOpenDialogChoiceProducts, setIsOpenDialogChoiceProducts] = useState(false);
  const [isMoreProducts, setIsMoreProducts] = useState(false);

  const [isOpenDialogChoiceRecommendedProducts, setIsOpenDialogChoiceRecommendedProducts] = useState(false);
  const [isMoreRecommendedProducts, setIsMoreRecommendedProducts] = useState(false);

  const [isOpenDialogChoiceTopCategories, setIsOpenDialogChoiceTopCategories] = useState(false);
  const [isMoreTopCategories, setIsMoreTopCategories] = useState(false);

  const [allActiveArticles, setAllActiveArticles] = useState([]);
  const [isOpenDialogChoiceArticles, setIsOpenDialogChoiceArticles] = useState(false);
  const [isMoreArticles, setIsMoreArticles] = useState(false);

  const [allActiveFaqsMainPage, setAllActiveFaqsMainPage] = useState([]);
  const [allActiveFaqs, setAllActiveFaqs] = useState([]);
  const [isOpenDialogChoiceFaqs, setIsOpenDialogChoiceFaqs] = useState(false);
  const [isMoreFaqs, setIsMoreFaqs] = useState(false);

  const [allActiveSlidersTop, setAllActiveSlidersTop] = useState([]);
  const [allActiveSlidersBottom, setAllActiveSlidersBottom] = useState([]);

  const [isOpenDialogSelectBanners, setIsOpenDialogSelectBanners] = useState(false);
  const [isMoreBanners, setIsMoreBanners] = useState(false);
  const [allActiveBanners, setAllActiveBanners] = useState(null);

  const watchCategoriesList = useWatch({
    control,
    name: `categories`,
  });

  const watchProductsList = useWatch({
    control,
    name: `products`,
  });

  const watchTopCategoriesList = useWatch({
    control,
    name: `topCategories`,
  });

  const watchArticlesList = useWatch({
    control,
    name: `articles`,
  });

  const watchFaqsList = useWatch({
    control,
    name: `faqs`,
  });

  const watchBannersList = useWatch({
    control,
    name: `mainPageBanners`,
  });

  const watchRecommendedProducts = useWatch({
    control,
    name: `recommendedProducts`,
  });

  const moveItem = (dragIndex, hoverIndex, fieldName) => {
    const fields = getValues(fieldName);
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue(fieldName, newFields);
  };

  useEffect(() => {
    backend.get("/article/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveArticles(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveArticles([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/category/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveCategories(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveCategories([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/product/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveProducts(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveProducts([]);
    };
  }, []);
  useEffect(() => {
    backend.get("/faq/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allFaqs = res.data;
          const activeFaqs = allFaqs.filter((item) => {
            return !item.offline;
          });
          const faq = getValues("faqId");

          setAllActiveFaqs(activeFaqs);

          if (
            !faq ||
            activeFaqs.find(function (element) {
              return element.id == faq;
            })
          ) {
            setAllActiveFaqsMainPage(activeFaqs);
          } else if (
            allFaqs.find(function (element) {
              return element.id == faq;
            })
          ) {
            setAllActiveFaqsMainPage([
              ...activeFaqs,
              ...allFaqs.filter((item) => {
                return item.id == faq;
              }),
            ]);
          } else {
            setAllActiveFaqsMainPage([...activeFaqs, { id: faq, displayName: "Item removed?", active: false }]);
          }
        }
      }
    });
    return () => {
      setAllActiveFaqsMainPage([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/slider/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allsliders = res.data;
          const activeSliders = allsliders.filter((item) => {
            return !item.offline;
          });
          const sliderTop = getValues("sliderTopId");
          const sliderBottom = getValues("sliderBottomId");

          if (
            !sliderTop ||
            activeSliders.find(function (element) {
              return element.id == sliderTop;
            })
          ) {
            setAllActiveSlidersTop(activeSliders);
          } else if (
            allsliders.find(function (element) {
              return element.id == sliderTop;
            })
          ) {
            setAllActiveSlidersTop([
              ...activeSliders,
              ...allsliders.filter((item) => {
                return item.id == sliderTop;
              }),
            ]);
          } else {
            setAllActiveSlidersTop([...activeSliders, { id: sliderTop, displayName: "Item removed?", active: false }]);
          }

          if (
            !sliderBottom ||
            activeSliders.find(function (element) {
              return element.id == sliderBottom;
            })
          ) {
            setAllActiveSlidersBottom(activeSliders);
          } else if (
            allsliders.find(function (element) {
              return element.id == sliderBottom;
            })
          ) {
            setAllActiveSlidersBottom([
              ...activeSliders,
              ...allsliders.filter((item) => {
                return item.id == sliderBottom;
              }),
            ]);
          } else {
            setAllActiveSlidersBottom([
              ...activeSliders,
              { id: sliderBottom, displayName: "Item removed?", active: false },
            ]);
          }
        }
      }
    });
    return () => {
      setAllActiveFaqsMainPage([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/banner/getAllPageBanner").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveBanners(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveBanners([]);
    };
  }, []);

  return (
    <>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            Kategorie
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setIsOpenDialogChoiceTopCategories(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!watchTopCategoriesList?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {watchTopCategoriesList?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreTopCategories) && (
                      <CustomizedListItem key={item.id} primaryText={item.displayName} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={watchTopCategoriesList}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreTopCategories}
                setFullListOpen={setIsMoreTopCategories}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Boxy
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <BoxesMainPage />
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Najczęściej wybierane badania
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setIsOpenDialogChoiceProducts(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!watchProductsList?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {watchProductsList?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreProducts) && (
                      <CustomizedListItem key={item.id} primaryText={item.name} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={watchProductsList}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreProducts}
                setFullListOpen={setIsMoreProducts}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Polecane badania
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ textAlign: "right", mb: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setIsOpenDialogChoiceRecommendedProducts(true)}
                >
                  Edytuj listę
                </Button>
              </Box>
              {!watchRecommendedProducts?.length > 0 ? (
                <Typography>Brak elementów</Typography>
              ) : (
                <>
                  <Table sx={{ pb: 0 }}>
                    <TableBody>
                      <DndProvider backend={HTML5Backend}>
                        {watchRecommendedProducts?.map(
                          (item, index) =>
                            (index < MAX_SHORT_LIST || isMoreRecommendedProducts) && (
                              <RowDnDProductInCategory
                                key={item.id}
                                id={item.id}
                                handleMoveItem={(dragIndex, hoverIndex) =>
                                  moveItem(dragIndex, hoverIndex, "recommendedProducts")
                                }
                                index={index}
                                primaryText={item.name}
                                secondaryText={item.url}
                              />
                            ),
                        )}
                      </DndProvider>
                    </TableBody>
                  </Table>
                  <MoreLessBoxIcon
                    list={watchRecommendedProducts}
                    maxShortListElement={MAX_SHORT_LIST}
                    fullListOpen={isMoreRecommendedProducts}
                    setFullListOpen={setIsMoreRecommendedProducts}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Popularne kategorie
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ textAlign: "right", mb: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setIsOpenDialogChoiceCategories(true)}
                >
                  Edytuj listę
                </Button>
              </Box>
              {!watchCategoriesList?.length > 0 ? (
                <Typography>Brak elementów</Typography>
              ) : (
                <>
                  <Table sx={{ pb: 0 }}>
                    <TableBody>
                      <DndProvider backend={HTML5Backend}>
                        {watchCategoriesList?.map(
                          (item, index) =>
                            (index < MAX_SHORT_LIST || isMoreCategories) && (
                              <RowDnDProductInCategory
                                key={item.id}
                                id={item.id}
                                handleMoveItem={(dragIndex, hoverIndex) =>
                                  moveItem(dragIndex, hoverIndex, "categories")
                                }
                                index={index}
                                primaryText={item.displayName}
                                secondaryText={item.url}
                              />
                            ),
                        )}
                      </DndProvider>
                    </TableBody>
                  </Table>
                  <MoreLessBoxIcon
                    list={watchCategoriesList}
                    maxShortListElement={MAX_SHORT_LIST}
                    fullListOpen={isMoreCategories}
                    setFullListOpen={setIsMoreCategories}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Artykuły
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setIsOpenDialogChoiceArticles(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!watchArticlesList?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {watchArticlesList?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreArticles) && (
                      <CustomizedListItem key={item.id} primaryText={item.title} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={watchArticlesList}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreArticles}
                setFullListOpen={setIsMoreArticles}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Najczęstsze pytania
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Grid item xs={12} sm={12}>
            <CustomizedSelect label={"Faq"} name={"faqId"} items={allActiveFaqsMainPage} />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Slajdery
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <CustomizedSelect label={"Slider Top"} name={"sliderTopId"} items={allActiveSlidersTop} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CustomizedSelect label={"Slider Bottom"} name={"sliderBottomId"} items={allActiveSlidersBottom} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            SG - Banery
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ textAlign: "right", mb: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setIsOpenDialogSelectBanners(true)}
                >
                  Edytuj listę
                </Button>
              </Box>
              {!watchBannersList?.length > 0 ? (
                <Typography>Brak elementów</Typography>
              ) : (
                <>
                  <Table sx={{ pb: 0 }}>
                    <TableBody>
                      <DndProvider backend={HTML5Backend}>
                        {watchBannersList?.map(
                          (item, index) =>
                            (index < MAX_SHORT_LIST || isMoreBanners) && (
                              <RowDnDProductInCategory
                                key={item.id}
                                id={item.id}
                                handleMoveItem={(dragIndex, hoverIndex) =>
                                  moveItem(dragIndex, hoverIndex, "mainPageBanners")
                                }
                                index={index}
                                primaryText={item.name}
                                secondaryText={item.buttonHref}
                              />
                            ),
                        )}
                      </DndProvider>
                    </TableBody>
                  </Table>
                  <MoreLessBoxIcon
                    list={watchBannersList}
                    maxShortListElement={MAX_SHORT_LIST}
                    fullListOpen={isMoreBanners}
                    setFullListOpen={setIsMoreBanners}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            FAQ - strona
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button variant="contained" color="secondary" size="small" onClick={() => setIsOpenDialogChoiceFaqs(true)}>
              Edytuj listę
            </Button>
          </Box>
          {!watchFaqsList?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {watchFaqsList?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreFaqs) && (
                      <CustomizedListItem key={item.id} primaryText={item.displayName} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={watchFaqsList}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreFaqs}
                setFullListOpen={setIsMoreFaqs}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {/*<Accordion square>*/}
      {/*  <AccordionSummary*/}
      {/*    expandIcon={<ExpandMoreIcon />}*/}
      {/*    aria-controls="panel1a-content"*/}
      {/*    id="panel1a-header"*/}
      {/*    sx={{ p: 0 }}*/}
      {/*  >*/}
      {/*    <Typography variant="h4" component={"h4"}>*/}
      {/*      Menu - Boxy*/}
      {/*    </Typography>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails sx={{ p: 0, mb: 5 }}>*/}
      {/*    <BoxesMenu />*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography variant="h4" component={"h4"}>
            Najczęściej wyszukiwane
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 5 }}>
          <FrequentlySearchedPhrazePanel name={"frequentlySearcheds"} label={"Wyszukiwane frazy"} />
        </AccordionDetails>
      </Accordion>
      <CategoryEditListDialogControled
        handleClose={() => setIsOpenDialogChoiceCategories(false)}
        open={isOpenDialogChoiceCategories}
        allItemsTable={Array.isArray(allActiveCategories) ? allActiveCategories : []}
        nameFormTables={"categories"}
        nameToDisplay={"displayName"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setIsOpenDialogChoiceProducts(false)}
        open={isOpenDialogChoiceProducts}
        allItemsTable={Array.isArray(allActiveProducts) ? allActiveProducts : []}
        nameFormTables={"products"}
        nameToDisplay={"name"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setIsOpenDialogChoiceTopCategories(false)}
        open={isOpenDialogChoiceTopCategories}
        allItemsTable={Array.isArray(allActiveCategories) ? allActiveCategories : []}
        nameFormTables={"topCategories"}
        nameToDisplay={"displayName"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setIsOpenDialogChoiceArticles(false)}
        open={isOpenDialogChoiceArticles}
        allItemsTable={Array.isArray(allActiveArticles) ? allActiveArticles : []}
        nameFormTables={"articles"}
        nameToDisplay={"title"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setIsOpenDialogChoiceFaqs(false)}
        open={isOpenDialogChoiceFaqs}
        allItemsTable={Array.isArray(allActiveFaqs) ? allActiveFaqs : []}
        nameFormTables={"faqs"}
        nameToDisplay={"displayName"}
      />
      {allActiveBanners && (
        <CategoryEditListDialogControled
          handleClose={() => setIsOpenDialogSelectBanners(false)}
          open={isOpenDialogSelectBanners}
          allItemsTable={allActiveBanners}
          nameFormTables={"mainPageBanners"}
          nameToDisplay={"name"}
          keepSortOrder
        />
      )}
      {allActiveProducts && (
        <CategoryEditListDialogControled
          handleClose={() => setIsOpenDialogChoiceRecommendedProducts(false)}
          open={isOpenDialogChoiceRecommendedProducts}
          allItemsTable={allActiveProducts}
          nameFormTables={"recommendedProducts"}
          nameToDisplay={"name"}
        />
      )}
    </>
  );
};

export default ContentConfiguration;
