import React from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { Circle } from "@mui/icons-material";

const CellMapStatus = ({ data, handleClick, row }) => {
  const { t } = useTranslation("common");
  let message = "";
  let color = "";

  switch (data) {
    case "OK":
      message = "Atrybuty pobrane dla wszystkich kodów produktu";
      color = "green";
      break;
    case "ERROR_ZERO_ATTRIBUTES":
      message = "Nie pobrano atrybutów dla podanych kodów produktu";
      color = "red";
      break;
    case "ERROR_MISSING_ATTRIBUTE":
      message = "Nie pobrano atrybutów dla niektórych kodów produktu";
      color = "orange";
      break;
    case "WARNING_MISSING_CODE":
      message = "Brak kodów produktu";
      color = "gray";
      break;
  }

  return (
    <TableCell title={message} onClick={(e) => handleClick(e, row)} align="center">
      {data && data !== "" && data !== "NOT_REQUIRED" && <Circle sx={{ fontSize: "16px", color: color }} />}
    </TableCell>
  );
};

export default CellMapStatus;
