import { Box, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Menu";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Layout = ({ header, children, actionButton, bottom, top, changeLanguage }) => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleClose = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.layoutContainer}>
      <Hidden mdDown>
        <div className={classes.sidebar}>
          <Sidebar />
        </div>
      </Hidden>

      <Hidden mdUp>
        {/* <Drawer
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            classes={{
              modal: classes.drawerModal,
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.sidebar}>
              <Sidebar />
            </div>
          </Drawer> */}
        {mobileOpen && (
          <Box id="mobile-menu" className={classes.mobileMenu} sx={{ zIndex: "modal" }}>
            <Sidebar showMobile={mobileOpen} />
          </Box>
        )}
      </Hidden>

      <div className={classes.mainWrapper}>
        <Header
          actionButton={actionButton}
          mobileToggle={handleClose}
          bottom={bottom}
          top={top}
          mobileOpened={mobileOpen}
          changeLanguage={changeLanguage}
        >
          {header}
        </Header>
        <div className={classes.main}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
