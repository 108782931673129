import React, { useEffect, useState } from "react";
import { Card, CardMedia, InputLabel, Typography } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MediaDialog from "modules/CMSResources/MediaDialog";
import { useFormContext } from "react-hook-form";
import ImageIcon from "@mui/icons-material/Image";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import getImage from "utils/imagesUrl";

const Details = (detailsImage, url) => {
    return (
        <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem/>}
            spacing={2}
        >
            {detailsImage?.name && <Typography variant={"body2"} color={color.black}>{detailsImage?.name}</Typography>}
            {detailsImage?.fileSize && <Typography variant={"body2"} color={color.black}>{Math.round(detailsImage?.fileSize / 1024) + ' kb'}</Typography>}
            {detailsImage?.dimensions && <Typography variant={"body2"} color={color.black}>{detailsImage?.dimensions}</Typography>}
            {url && !detailsImage?.name && !detailsImage?.fileSize && !detailsImage?.fileSize && <Typography variant={"body2"} color={color.black}>{url}</Typography>}

        </Stack>
    );
}

const CustomizedImagePicker = ({label, placeholder, nameUrl, nameAlt, image = undefined, setImage = undefined}) => {
    const [isMediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [detailsStack, setDetailsStack] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { watch  } = useFormContext();
    const watchUrl = watch(nameUrl, '');

    useEffect(() => {
        if(image?.length > 0) {
            setIsLoading(true);
            const data = {url: image}
            backend.post("/media/getDetails", data).then((res) => {
                if (isSuccess(res)) {
                    if (res.data != null) {
                        setDetailsStack(Details(res.data, image));
                    } else {
                        setDetailsStack(Details(null, image));
                    }
                }
                setIsLoading(false);
            });
        } else {
            setDetailsStack('');
        }
    }, [image]);

    useEffect(() => {
        if(watchUrl?.length > 0) {
            setIsLoading(true);
            const data = {url: watchUrl}
            backend.post("/media/getDetails", data).then((res) => {
                if (isSuccess(res)) {
                    if (res.data != null) {
                        setDetailsStack(Details(res.data, watchUrl));
                    } else {
                        setDetailsStack(Details(null, watchUrl));
                    }
                }
                setIsLoading(false);
            });
        } else if (!image) {
            setDetailsStack('');
        }
    }, [watchUrl]);


    return (
        <>
            {isMediaDialogOpen && <MediaDialog
                nameUrl={nameUrl}
                nameAlt={nameAlt}
                open={isMediaDialogOpen}
                close={() => setMediaDialogOpen(false)}
                setImage = {setImage}
                image = {image}
                />
            }
            <InputLabel>
                {label}
            </InputLabel>
            <Box onClick={() => setMediaDialogOpen(true)} sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 3,
                mb: 5,
                borderWidth: 2,
                borderRadius: 2,
                borderColor: alpha(color.primary, 0.15),
                borderStyle: 'dashed',
                backgroundColor: alpha(color.primary, 0.05),
                outline: 'none',
                transition: 'border .24s ease-in-out',
                cursor: 'pointer',
                '&:hover': {
                    borderColor: alpha(color.primary, 0.55),
                }
            }}>
                {image || watchUrl?.length > 0  ? (
                    <>
                        <Card sx={{borderRadius: 1, mr: 4, maxWidth: 1/2}}>
                            <CardMedia
                                component="img"
                                height="70"
                                image={image ? getImage(image) : getImage(watchUrl)}
                            />
                        </Card>
                        <div>
                            <Typography variant={"body2"} color={color.black} fontWeight={"bold"} sx={{ mb:1 }}>{placeholder}</Typography>
                            {isLoading ? ( <CircularProgress size={10} /> ) : (detailsStack)}
                        </div>
                    </>
                ) : (
                    <>
                        <ImageIcon fontSize={'large'} sx={{borderRadius: 1, mr: 4}} />
                        <Typography variant={"body2"} color={color.black} fontWeight={"bold"} >Wybierz plik</Typography>
                    </>
                )}
            </Box>
        </>
    );
}

export default CustomizedImagePicker;

