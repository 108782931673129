import { Grid, Paper } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import Wysiwyg from "components/Edit/Wysiwyg";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import PopupsItemsPanel from "components/Edit/Popups/PopupsItemsPanel";
import EditViewLanguage from "components/EditViewLanguage";

const endpoint = "/popups/";
const title = "marketing_popup_edit_label";
const moduleUrl = "/marketing/popups/";

const templateTypes = [
  { id: "MODAL_50", displayName: "Modal - 50%" },
  { id: "MODAL_100", displayName: "Modal - 100%" },
  { id: "MODAL_THANKS", displayName: "Modal - Podziękowania" },
];

const PopupsEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
    template: Yup.string().nullable(),
    delay: Yup.number().typeError(t("form-validation-number")).integer(t("form-validation-number")).nullable(),
    afterCloseNoAction: Yup.string().nullable(),
    afterCloseWithAction: Yup.string().nullable(),
    content: Yup.string().nullable(),
    links: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        link: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
  });

  const defaultValue = {
    name: "",
    active: "true",
    template: "",
    delay: "",
    afterCloseNoAction: "",
    afterCloseWithAction: "",
    content: "",
    links: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Popup"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch topLabel={"Status"} rightLabel={"Aktywny"} name={"active"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={"Szablon"} name={"template"} items={templateTypes} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CustomizedTextField label={"Opoźnienie włączenia"} name={"delay"} type={"number"} defaultValue={null} />
          </Grid>
          <Grid item xs={12} sm={3} />
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Po zamknięciu bez akcji"} name={"afterCloseNoAction"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Po zamknięciu z akcją"} name={"afterCloseWithAction"} />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <PopupsItemsPanel name={"links"} label={"Adresy"} />
          </Grid>
          <Grid item xs={12}>
            <Wysiwyg label={"Treść popupu"} name={"content"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PopupsEdit;
