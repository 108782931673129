import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@mui/material";

const DialogTextField = ({label, name, errors, value, setValue, disabled=false, type="text"}) => {
  return (
      <>
          <InputLabel htmlFor={name}>
              {label}
          </InputLabel>
          <TextField
              disabled={disabled}
              helperText={errors[name] ? errors[name].message : ""}
              error={!!errors[name]}
              variant="outlined"
              size="small"
              fullWidth={true}
              value={value}
              onChange={(e) => {setValue(e.target.value)}}
              type={type}
              inputProps={(type === 'number') ? { min: 0 } : {}}
          />
      </>
  );
}

export default memo(DialogTextField);
