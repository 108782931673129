import React, { useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import { Input, InputLabel, Typography } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: alpha(color.primary, 0.15),
    borderStyle: 'dashed',
    backgroundColor: alpha(color.primary, 0.05),
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
};

const focusedStyle = {
    borderColor: alpha(color.primary, 0.4),
};

const acceptStyle = {
    borderColor: color.green,
};

const rejectStyle = {
    borderColor: color.red,
};

const CustomizedDropZone = ({label, setFiles, selectedFile}) => {

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles.find(f => f);
        selectedFile(file);
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: 'image/*', maxFiles: 1});

    useEffect(() => {
        if (setFiles) {
            setFiles(acceptedFiles);
        }
    }, [acceptedFiles])

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <>
            <InputLabel>
                {label}
            </InputLabel>
            <div className="container">
                <div {...getRootProps({style})}>
                    <Input {...getInputProps()} />

                    {acceptedFiles.length ?
                        <>
                            <CheckIcon color={"warning"}/>
                            <Typography>{acceptedFiles[0].path}</Typography>
                        </>
                        :
                        <>
                            <FileDownloadIcon/>
                            <Typography>Przeciągnij lub wybierz plik graficzny</Typography>
                        </>}
                </div>
            </div>
        </>
    );
}

export default CustomizedDropZone;

