import Dialog from "@mui/material/Dialog";
import * as color from "assets/styles/jss/common/colors";
import { Box, Button, Grid, InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomSingleDatePicker from "components/CustomDatePicker/CustomSingleDatePicker";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import { useState } from "react";
import * as Yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef } from "react";

const AddOpinionDialog = ({ open, handleClose, handleSubmit, handleDeleteItem, defaultValues }) => {
  const validationSchema = Yup.object({
    id: Yup.number(),
    author: Yup.string().required("Pole autor jest wymagne"),
    content: Yup.string().required("Pole treść jest wymagane"),
    date: Yup.date().required("Date is required"),
    rate: Yup.number().required("Rate is required"),
    sortOrder: Yup.number().required("Sort order is required"),
  });
  const refForm = useRef(null);
  const methods = useForm({ criteriaMode: "all", defaultValues, resolver: yupResolver(validationSchema) });

  const [date, setDate] = useState(moment());
  const [rate, setRate] = useState(5);

  const ratingChanged = (newRating) => {
    methods.setValue("rate", newRating);
    setRate(newRating);
  };

  const handelDate = (date) => {
    setDate(date);
    methods.setValue("date", date.toDate());
  };

  const id = methods.watch("id");

  return (
    <Dialog
      open={open}
      keepMounted
      disablePortal
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        handleClose();
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <FormProvider {...methods}>
        <form ref={refForm} noValidate onSubmit={methods.handleSubmit((data) => console.log(data))}>
          <Grid container sx={{ mt: 5 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {id ? "Edytuj opinię" : "Dodaj opinię"}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={5}>
              <CustomizedTextField label={"Autor"} name={"author"} />
            </Grid>
            <Grid item xs={12} mt={5}>
              <CustomizedTextField label={"Treść"} name={"content"} />
            </Grid>
            <Grid item xs={12} mt={5}>
              <InputLabel>Data dodania opinii</InputLabel>
              <CustomSingleDatePicker placeholder="asdsad" date={date} setDate={handelDate} />
            </Grid>
            <Grid item xs={12} mt={5} display="flex" flexDirection="column">
              <InputLabel>Ocena klienta</InputLabel>
              <Box display="flex" alignItems="center" gap={4}>
                <Typography>{rate}</Typography>
                <ReactStars
                  value={rate}
                  count={5}
                  edit={true}
                  onChange={ratingChanged}
                  size={24}
                  activeColor="#ffd700"
                />
              </Box>
            </Grid>
            <Grid container item xs={12} mt={5}>
              {id === -1 && (
                <Grid item xs={6}>
                  <Button variant="outlined" color="default" onClick={handleClose}>
                    Zamknij
                  </Button>
                </Grid>
              )}
              {id !== -1 && (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="default"
                    sx={{ color: color.red }}
                    onClick={() => handleDeleteItem(id)}
                  >
                    Usuń
                  </Button>
                </Grid>
              )}
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleLocalSubmit}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Zapisz
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default AddOpinionDialog;
