import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "layout/Layout";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchCrudItem, selectListError, selectListIsLoading } from "redux/slices/crud";
import backend from "../../api/backend";
import {
  clearCurrentItem,
  fetchCrudList,
  selectEditItem,
  selectListFilters,
  setListPage,
} from "../../redux/slices/crud";
import { changeLanguage, selectLangCode, setCurrentLanguageCode, setIsPolishOnly } from "../../redux/slices/language";
import { isSuccess } from "../../utils/http";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import EditActionButtons from "components/Edit/EditActionButtons";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationDialog from "components/ConfirmationDialog";
import Error from "components/Error";
import { selectMenuItems, setCurrentSelectedLevelOne, setCurrentSelectedLevelZero } from "redux/slices/menu";
import { findMenu } from "utils/menu";

const toolbarItems = [
  {
    name: "toolbar_language_selector",
    tooltip: "toolbar_language_selector_tooltip",
    icon: "",
    action: "changeLanguage",
    disabled: false,
    sortOrder: 0,
    type: "SELECT_LANGUAGE",
  },
];

const EMPTY_ID = "";

const EditViewLanguage = ({
  children,
  endpoint,
  title,
  moduleUrl,
  validationSchema,
  defaultValue,
  refForm = useRef(null),
  preview,
  testMessage,
  noDeleteButton,
  noBackButton,
  itemWithoutId,
  setDefaultsProcess,
  customSave,
  deleteButtonTitle,
}) => {
  const { id, langCode } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const filters = useSelector(selectListFilters);
  const [blockSetFields, setBlockSetFields] = useState(true);
  const [ignoreError, setIgnoreError] = useState(false);

  const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);

  const currentLanguage = useSelector(selectLangCode);

  const current = useSelector(selectEditItem);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  }); // initialize the hook

  useEffect(() => {
    if (langCode && currentLanguage != langCode) {
      saveLanguage(langCode);
    }

    dispatch(clearCurrentItem());

    if (id === "new") {
      setIgnoreError(true);
      dispatch(setIsPolishOnly(true));
      if (currentLanguage != "pl") {
        saveLanguage("pl");
      }
    } else {
      dispatch(setIsPolishOnly(false));
      getItem();
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, []);

  const menuItems = useSelector(selectMenuItems);
  useEffect(() => {
    const { levelZero, levelOne } = findMenu(menuItems);
    dispatch(setCurrentSelectedLevelZero(levelZero));
    dispatch(setCurrentSelectedLevelOne(levelOne));
  }, [menuItems]);

  useEffect(() => {
    if (current?.id == null) {
      methods.reset(defaultValue);
    } else if (current && !blockSetFields) {
      Object.keys(current).forEach((k) => {
        methods.setValue(k, current[k]);
      });
    }
  }, [current]);

  useEffect(() => {
    if (id != "new") {
      setBlockSetFields(false);
      setIgnoreError(false);
      dispatch(setIsPolishOnly(false));
    }
  }, [id]);

  useEffect(() => {
    if (id != "new" && currentLanguage != langCode) {
      getItem();
      itemWithoutId ? history.push(moduleUrl + currentLanguage) : history.push(moduleUrl + id + "/" + currentLanguage);
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (langCode && currentLanguage != langCode) {
      saveLanguage(langCode);
    }

    if (id != "new" && currentLanguage != langCode) {
      getItem();
    }
  }, [langCode]);

  /* Preview */
  const handlePreview = () => {
    const WEB_HOST = process.env.REACT_APP_WEB_HOST;
    const URL = methods.getValues("url");
    const SUFIX = `?previd=${current?.previd}`;

    const fullUrl = WEB_HOST + URL + SUFIX;

    if (URL && URL.length > 0 && URL.startsWith("/") && current?.previd) {
      window.open(fullUrl, "_blank");
    } else {
      enqueueSnackbar(t("preview_error_url"), {
        variant: "warning",
      });
    }
  };

  const handleSave = () => {
    if (customSave) {
      customSave();
    } else {
      refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const handleSubmit = (d) => {
    _onSubmit(d);
  };

  const _onSubmit = (d) => {
    let idTmp = id;

    if ((id === "new" || current == null) && !itemWithoutId) {
      idTmp = 0;
    } else if (itemWithoutId) {
      idTmp = EMPTY_ID;
    }

    backend.post(endpoint + idTmp, d).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        if (res.data.item != null) {
          if (idTmp === 0) {
            const newId = res.data.item.id;
            dispatch(fetchCrudItem({ endpoint, id: newId }));
            history.push(moduleUrl + newId + "/pl");
          } else {
            getItem();
          }
        } else {
          history.push(moduleUrl);
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const getItem = () => {
    if (itemWithoutId) {
      const id = EMPTY_ID;
      dispatch(fetchCrudItem({ endpoint, id }));
    } else {
      dispatch(fetchCrudItem({ endpoint, id }));
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenedRemoveDialog(true);
  };

  const handleDeleteFromDB = () => {
    backend.delete(endpoint + id).then((res) => deleteResponseFunction(res));
    setOpenedRemoveDialog(false);
  };

  const deleteResponseFunction = (res) => {
    if (isSuccess(res)) {
      enqueueSnackbar(t("common_status_successfully"), {
        variant: "success",
      });
      dispatch(setListPage(0));
      dispatch(fetchCrudList({ endpoint, filters }));
      history.push(moduleUrl);
    } else {
      enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
        variant: "error",
      });
    }
  };

  const saveLanguage = (langCode) => {
    changeLanguage(langCode).then((r) => {
      if (r === true) {
        dispatch(setCurrentLanguageCode(langCode));
      }
    });
  };

  return (
    <Layout
      header={t(title)}
      changeLanguage
      top={
        <HeaderBuilder
          headerData={toolbarItems}
          isEditViewActive={true}
          moduleUrl={moduleUrl}
          noBackButton={noBackButton}
          noDeleteButton={noDeleteButton || id == "new"}
          handleDelete={handleOpenDeleteDialog}
          deleteButtonTitle={deleteButtonTitle}
        />
      }
      bottom={<EmptySection />}
    >
      <FormProvider {...methods} handleDelete={handleOpenDeleteDialog}>
        {isLoading && !ignoreError ? (
          <CircularProgress />
        ) : error.isError && !ignoreError ? (
          <Error error={error} />
        ) : !error.isError && current == null && !ignoreError && !itemWithoutId ? (
          <Error error={{ message: "no data" }} />
        ) : (
          <>
            <form ref={refForm} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
              {children}
            </form>
            <EditActionButtons
              handleSave={handleSave}
              handleDelete={handleOpenDeleteDialog}
              handlePreview={preview ? handlePreview : false}
              handleTest={testMessage ? testMessage : false}
              handleSetDefaultProcess={id != "new" && setDefaultsProcess ? setDefaultsProcess : false}
              noDeleteButton={noDeleteButton || id == "new"}
            />
          </>
        )}
      </FormProvider>
      <ConfirmationDialog
        open={openedRemoveDialog}
        close={() => setOpenedRemoveDialog(false)}
        confirm={handleDeleteFromDB}
      />
    </Layout>
  );
};

export default EditViewLanguage;
