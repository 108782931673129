import React, { useEffect, useState } from "react";
import { Button, Grid, Select } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import FormControl from "@mui/material/FormControl";
import DiscountCriteriaTableRow from "components/Edit/DiscountCode/DiscountCriteriaTableRow";
import * as color from "assets/styles/jss/common/colors";
import Divider from "@mui/material/Divider";
import { useFormContext } from "react-hook-form";
import ConfirmationDialog from "components/ConfirmationDialog";
import Stack from "@mui/material/Stack";

const meetsConditions = [
    // { id: "NONE", name: "Brak"},
    { id: "ALL", name: "Spełnia wszystkie warunki" },
    { id: "ANY", name: "Spełnia jeden z warunków" },
];

const DiscountCriteriaTable = ({typesCondition, listName, mainOperatorName, matchName}) => {
    const {setValue, watch, getValues} = useFormContext();
    const watchMainOperator = watch(mainOperatorName);
    const watchlist = watch(listName);
    const [nextId, setNextId] = useState(0);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        if(!watchlist) {
            setValue(listName, [])
        }
    }, [])

    useEffect(() => {
        if(watchMainOperator == null) {
            setMainOperator('ANY');
        }
    }, [watchMainOperator])

    const addCriteria = () => {
        const newItem = {
            id: nextId,
            type: typesCondition[0]['id'],
            operator: "EQUALS",
            boolValue: true,
            numberFirstValue: "",
            numberSecondValue: "",
            stringValue: ""
        }

        setNextId(prevState => prevState - 1);
        setValue(listName, [...watchlist, newItem]);
    }

    const setMatchName = (newValue) => {
        setValue(matchName, newValue)
    }

    const setMainOperator = (newValue) => {
        setValue(mainOperatorName, newValue)
    }

    const handleDeleteRow = (row) => {
        setRowToDelete(row);
        setDeleteDialogOpen(true);
    }

    const removeItem = () => {
        const index = watchlist?.findIndex(object => {
            return object.id === rowToDelete.id;
        });

        watchlist?.splice(index, 1);
        setValue(listName, watchlist);

        setRowToDelete(null);
        setDeleteDialogOpen(false);
    }


    return (
        <>
            <Grid item xs={12}>
                <Divider sx={{ background: color.black, mb: 4, mt: 0 }} />
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
                <Grid item>
                    <Stack direction="row" spacing={5}>
                        {/*{matchName &&*/}
                        {/*    <TextField*/}
                        {/*        size={"small"}*/}
                        {/*        onBlur={(e) => setMatchName(e.target.value)}*/}
                        {/*        defaultValue={getValues(matchName)}*/}
                        {/*    />*/}
                        {/*}*/}

                        <FormControl size={"small"}>
                            <Select
                                native
                                value={watchMainOperator}
                                onChange={(e) => setMainOperator(e.target.value)}
                            >
                                {meetsConditions?.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item>
                    <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={addCriteria}
                    >
                        Dodaj warunek
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: 5 }}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            <DiscountCriteriaTableRow
                                listCriteria={watchlist}
                                listName={listName}
                                typesCondition={typesCondition}
                                mainOperator={watchMainOperator}
                                handleDeleteRow={handleDeleteRow}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <ConfirmationDialog
                open={deleteDialogOpen}
                close={() => setDeleteDialogOpen(false)}
                confirm={removeItem}
            />
        </>
    );
};

export default DiscountCriteriaTable;

