const TabPanel = (props) => {
  const { children, value, index, sx, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} style={sx} {...other} >
        {children}
    </div>
  );
};

export default TabPanel;
