import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PublicRoute =  ({isLogged, component: Component, ...rest}) => {
    return <Route {...rest} render={props => (
        !isLogged
            ? <Component {...props} />
            : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
}



