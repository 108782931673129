import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";
import { isSuccess } from "utils/http";

export const fetchSelectedLang = createAsyncThunk('user/selectedLang', async (id, thunkAPI) => {
    const url = `/user/selectedLang`;
    const res = await backend.get(url);
    if (res.status === 200) {
        return res.data.language;
    } else {
        return 'pl';
    }
});

export const fetchAvailableLangs = createAsyncThunk('language/availableLangs', async (id, thunkAPI) => {
    const url = `/language/all/active`;
    const res = await backend.get(url);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
});

export const changeLanguage = async (lang)  => {
    const data = {
        language: lang,
    };
    const result = await backend.post('/user/changeLanguage', data);
    return isSuccess(result);
};

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        isLoading: false,
        list: [],
        selected: 'pl',
        isPolishOnly: false,
    }, reducers: {
        setCurrentLanguageCode(state, action) {
            state.selected = action.payload;
        },
        setIsPolishOnly(state, action) {
            state.isPolishOnly = action.payload;
        },
    }, extraReducers: {
        [fetchSelectedLang.pending]: (state, action) => {
            state.isLoading = true;
        },
        [fetchSelectedLang.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [fetchSelectedLang.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.selected = action.payload;
        },
        [fetchAvailableLangs.pending]: (state, action) => {
            state.isLoading = true;
        },
        [fetchAvailableLangs.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [fetchAvailableLangs.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.list = action.payload;
        },
    },
});

export const selectLangCode = (state) => state.language.selected;
export const selectLanguages = (state) => state.language.list;
export const selectIsPolishOnly = (state) => state.language.isPolishOnly;
export const {setCurrentLanguageCode, setIsPolishOnly} = languageSlice.actions;

export default languageSlice.reducer;
