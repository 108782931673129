import Dialog from "@mui/material/Dialog";
import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

const NewCodeDialog = ({ close, open, text }) => {
  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={() => close()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              {text}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                color="default"
                onClick={() => {
                  close();
                }}
              >
                OK
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default NewCodeDialog;
