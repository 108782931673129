import { Grid, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import EditViewLanguage from "components/EditViewLanguage";

const endpoint = "/mail-setting/";
const title = "settings_mail_settings_label";
const moduleUrl = "/settings/mail/";

const MailEdit = () => {
    const {id} = useParams();
    const current = useSelector(selectEditItem);
    const {t} = useTranslation("common");

    const validationSchema = Yup.object({
        senderName: Yup.string().nullable(),
        senderMail: Yup.string().email(t("form-validation-mail")).nullable(),
        encryption: Yup.string().trim().required(t("form-validation-required")),
        smtpAuthentication: Yup.bool().required(t("form-validation-required")),
        smtpHost: Yup.string().trim().nullable(),
        smtpPort: Yup.number()
            .typeError(t("form-validation-number"))
            .positive(t("form-validation-number"))
            .integer(t("form-validation-number")),
        smtpUserName: Yup.string().trim().nullable(),
        smtpPassword: Yup.string().trim().nullable()
    });

    const defaultValue = {
        senderName: '',
        senderMail: '',
        encryption: 'SSL',
        smtpAuthentication: true,
        smtpHost: '',
        smtpPort: 587,
        smtpUserName: '',
        smtpPassword: ''
    };

    const refForm = useRef(null);

    return (
        <>
            <EditViewLanguage
                endpoint={endpoint}
                title={title}
                moduleUrl={moduleUrl}
                validationSchema={validationSchema}
                refForm={refForm}
                noDeleteButton={true}
                noBackButton={true}
                itemWithoutId={true}
                defaultValue={defaultValue}
            >
                <FormData id={id} current={current} refForm={refForm}/>
            </EditViewLanguage>
        </>
    );
};

const FormData = ({id, current, refForm}) => {
    const {register, control, formState} = useFormContext();
    const {t} = useTranslation("common");

    return <>
        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h4" component={"h4"}>{"Ustawienia nadawcy"}</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Nazwa"}
                        name={"senderName"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedTextField
                        label={"Email"}
                        name={"senderMail"}
                    />
                </Grid>
            </Grid>
        </Paper>

        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h4" component={"h4"}>{"Ustawienia SMTP"}</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12}>
                                <FormControl>
                                    <FormLabel>Szyfrowanie</FormLabel>
                                    <Controller
                                        control={control}
                                        name="encryption"
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <FormControlLabel value="NONE" control={<Radio/>} label="Brak"/>
                                                <FormControlLabel value="SSL" control={<Radio/>} label="SSL"/>
                                                <FormControlLabel value="TLS" control={<Radio/>} label="TLS"/>
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CustomizedSwitch
                                    name={"smtpAuthentication"}
                                    rightLabel={"Uwierzytelnianie"}
                                    topLabel={""}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={6}>
                                <CustomizedTextField
                                    label={"Host"}
                                    name={"smtpHost"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomizedTextField
                                    label={"Port"}
                                    name={"smtpPort"}
                                    type={"number"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomizedTextField
                                    label={"Nazwa użytkowinia"}
                                    name={"smtpUserName"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomizedTextField
                                    label={"Hasło"}
                                    name={"smtpPassword"}
                                    type={"password"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    </>;
};

export default MailEdit;
