import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchGroupList = createAsyncThunk('group/list', async (data, thunkAPI) => {
  const res = await backend.get('/groups/');
  return res.data;
});

const groupSlice = createSlice({
  name: 'groups',
  initialState: {
    isLoading: false,
    list: [],
    userGroups: [],
  },
  reducers: {},
  extraReducers: {
    [fetchGroupList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchGroupList.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [fetchGroupList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    },
  },
});

export const selectGroupList = (state) => state.groups.list;

export default groupSlice.reducer;
