import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchWorkerList = createAsyncThunk('workers/list', async (data, thunkAPI) => {
  const res = await backend.get('/users/workers/');
  return res.data;
});

const workersSlice = createSlice({
  name: 'workers',
  initialState: {
    isLoading: false,
    list: [],
  },
  reducers: {},
  extraReducers: {
    [fetchWorkerList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchWorkerList.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [fetchWorkerList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    },
  },
});

export const selectWorkerList = (state) => state.workers.list;

export default workersSlice.reducer;
