import styles from "./styles.js";
import makeStyles from "@mui/styles/makeStyles";
import SubmenuUl from "../SubmenuUl/SubmenuUl.js";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import { ListItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentSelectedLevelOne, setCurrentSelectedLevelOne } from "redux/slices/menu";

const useStyles = makeStyles(styles);

const Submenu = ({group, main}) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory();

    const link = useSelector(selectCurrentSelectedLevelOne)

    const markLink = (page) => {
        dispatch(setCurrentSelectedLevelOne(page.path));
        history.push(page.path);
    };

    return group.map((group, gIndex) => (
        <SubmenuUl name={group.name} key={"group" + gIndex}>
            {group.pages.map((page, sIndex) => (
                <ListItem onClick={() => markLink(page)}
                          key={"page.title" + gIndex + sIndex}
                          className={clsx(classes.submenuGroup__li, page.path === link && classes.clickedSubmenu)}
                          link={page.path}
                >
                    {/*<Link className={classes.submenuLink} to={page.path}>*/}
                    {/*    {page.title}*/}
                    {/*</Link>*/}
                    <Typography variant={"body3"} className={classes.submenuLink}>
                        {page.title}
                    </Typography>
                    <EastIcon className={classes.arrow}/>
                </ListItem>
            ))}
        </SubmenuUl>
    ))

};

export default Submenu;
