import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import DataTable from "components/Table/DataTable";

const items = {
    "toolbarItems": [
        {
            "name": "toolbar_button_add",
            "tooltip": "toolbar_button_add_tooltip",
            "icon": "",
            "action": "add",
            "disabled": false,
            "sortOrder": 0,
            "type": "BUTTON"
        },
        {
            "name": "toolbar_icon_copy",
            "tooltip": "toolbar_icon_duplicate_tooltip",
            "icon": "ContentCopy",
            "action": "copy",
            "disabled": false,
            "sortOrder": 1,
            "type": "ICON"
        }, {
            "name": "toolbar_icon_del",
            "tooltip": "toolbar_icon_del_tooltip",
            "icon": "Delete",
            "action": "delete",
            "disabled": false,
            "sortOrder": 2,
            "type": "ICON"
        }, {
            "name": "toolbar_search_field",
            "tooltip": "toolbar_search_field_tooltip",
            "icon": "Search",
            "action": "search",
            "disabled": false,
            "sortOrder": 4,
            "type": "FIELDS_SEARCH"
        }
    ],
    "headCells": [
        {
            "type": "DATA",
            "id": "name",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": false,
            "sortable": true,
            "label": "settings_role_name"
        },
        {
            "type": "DATA",
            "id": "date",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": true,
            "sortable": true,
            "label": "settings_role_date"
        },
    ],
    "pagination": {
        "page": 0,
        "limit": 15,
        "order": "id",
        "totalItems": 43,
        "totalPages": 3
    },
    "items": [
        {
            "id": 1,
            "name": "Administrator",
            "date": 1613423432423,
        },
        {
            "id": 2,
            "name": "Super Admin",
            "date": 1613423432423,
        },
        {
            "id": 3,
            "name": "Laboratory",
            "date": 1613423432423,
        },
        {
            "id": 4,
            "name": "Seller",
            "date": 1613423432423,
        },
        {
            "id": 5,
            "name": "Laboratorium Diagnostyka",
            "date": 1613423432423,
        },
        {
            "id": 6,
            "name": "Badamy Geny",
            "date": 1613423432423,
        },

    ],
    "filters": [
        {
            "placeholder": "Rola",
            "destFieldName": "groups.id",
            "url": "/groups",
            "srcFieldName": "name",
            "type": "ASYNCHRONOUS",
            "wide": true
        }
    ]
}

const RolesList = () => {
    const {t} = useTranslation("common");

    return (
        <Layout
            header={t("settings_roles_label")}
            bottom={<EmptySection/>}
            top={<HeaderBuilder
                headerData={items.toolbarItems}
                moduleUrl={"/settings/roles"}
            />}
        >
            <Paper sx={{p: 10, mb: 10}} elevation={8}>
                <DataTable
                    modulePath={"/settings/roles"}
                    endpoint={"/roles/"}
                />
            </Paper>
        </Layout>
    );
};
export default RolesList;
