import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React from "react";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BasicConfiguration = () => {
    return (
        <>
            <Accordion square defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{p: 0}}
                >
                    <Typography variant="h4" component={"h4"}>Dane do faktury</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0, mb: 5}}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12}>
                            <CustomizedTextField
                                label={"Nazwa firmy"}
                                name={"vendorName"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomizedTextField
                                label={"Adres"}
                                name={"vendorAddress"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CustomizedTextField
                                label={"Kod pocztowy"}
                                name={"vendorZipCode"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CustomizedTextField
                                label={"Miasto"}
                                name={"vendorCity"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CustomizedTextField
                                label={"Kraj"}
                                name={"vendorCountry"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomizedTextField
                                label={"NIP"}
                                name={"vendorNip"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomizedTextField
                                label={"Numer konta"}
                                name={"vendorAccount"}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default BasicConfiguration;
