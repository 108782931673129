import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import DataTableStatic from "components/Table/DataTableStatic";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";

const items = {
    "toolbarItems": [
        {
            "name": "toolbar_search_field",
            "tooltip": "toolbar_search_field_tooltip",
            "icon": "Search",
            "action": "search",
            "disabled": false,
            "sortOrder": 4,
            "type": "FIELDS_SEARCH"
        }
    ],
    "headCells": [
        {
            "type": "DATA",
            "id": "case_name",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": false,
            "sortable": true,
            "label": "shop_case_name"
        },
        {
            "type": "DATA",
            "id": "details",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": false,
            "sortable": true,
            "label": "shop_details"
        },
        {
            "type": "DATA",
            "id": "date",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": true,
            "sortable": true,
            "label": "shope_make_date"
        },
        {
            "type": "DATA",
            "id": "status",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": false,
            "sortable": true,
            "label": "shop_status"
        },
        {
            "type": "ACTION",
            "id": "actions",
            "numeric": false,
            "disablePadding": false,
            "bool": false,
            "date": false,
            "sortable": false,
            "label": "shop_actions"
        },
    ],
    "pagination": {
        "page": 0,
        "limit": 15,
        "order": "id",
        "totalItems": 43,
        "totalPages": 3
    },
    "items": [
        {
            "id": 1,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 1,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 1,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 2,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 3,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 4,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 5,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 6,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 7,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 8,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 9,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 10,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 11,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 12,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 13,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 14,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
        {
            "id": 15,
            "case_name": "Zapytanie ze strony",
            "details": "Dzień dobry, czy jest możl...",
            "date": 1582142342323,
            "status": "Ważny",
            "actions": "",
        },
    ],
    "filters": [
        {
            "placeholder": "Rola",
            "destFieldName": "groups.id",
            "url": "/groups",
            "srcFieldName": "name",
            "type": "ASYNCHRONOUS",
            "wide": true
        }
    ]
}

const SystemMessagesList = () => {
    const {t} = useTranslation("common");

    return (
        <Layout
            header={t("shop_system messages_label")}
            bottom={<EmptySection/>}
            top={<HeaderBuilder headerData={items.toolbarItems}/>}
        >
            <Paper sx={{p: 10, mb: 10}} elevation={8}>
                <DataTableStatic data={items}/>
            </Paper>
        </Layout>
    );
};
export default SystemMessagesList;
