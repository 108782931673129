import Dialog from "@mui/material/Dialog";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

const urlWithoutNewItem = [
    "/home/inbox",
    "/home/shortcuts",
    "/shop/invoices",
    "/shop/contact-forms",
    "/shop/product-inquiries",
    "/shop/complaints",
    "/shop/system-messages",
    "/settings/sms",
    "/settings/mail",
    "/settings/translations",
    "/settings/configuration",
    "/settings/codes",
    "/settings/log"

]

const ShortcutsEditListDialog = ({open, handleClose, handleConfirm, menuItem}) => {
    const [selectedItem, setSelectedItem] = useState("");
    const [errors, setErrors] = useState({});

    const {t} = useTranslation("common");

    const testNoEmptyValue = (name, value) => {
        if(!value || value === "") {
            setErrors((prevState) => ({
                    ...prevState,
                    [name]: { message: t("form-validation-required") }

                })
            )
            return true;
        }
        return false;
    }

    const handleSubmit = () => {
        let isError = false
        setErrors({})

        testNoEmptyValue("selectedItem", selectedItem) ? isError = true : '';

        if (!isError) {
            setErrors({});
            setSelectedItem("");
            handleConfirm(selectedItem);
            handleClose();
        }
    }

    const setValue = (e) => {
        setSelectedItem(e.target.value);
        if(e.target.value !== "") {
            setErrors({});
        }
    }

    return <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Dodaj skrót</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="grouped-native-select">
                        Wybierz skrót do dodania
                    </InputLabel>
                    <FormControl fullWidth size={"small"}>
                        <Select
                            native
                            id="grouped-native-select"
                            value={selectedItem}
                            onChange={(e) => setValue(e)}
                            error={!!errors["selectedItem"]}
                        >
                            <option value={""} />
                            {menuItem.length && menuItem.map((mainMenuItem, m_index) => (
                                mainMenuItem.group.map((subMenuItem, s_index) => (
                                    <optgroup key={"main-"+ m_index + "group-" + s_index} label={mainMenuItem.name + " > " + subMenuItem.name}>
                                        {subMenuItem.pages.map((item, i_index) => (
                                            <Fragment key={"main-"+ m_index + "group-" + s_index + "item-" + i_index}>
                                                <option value={item.path}>
                                                    {item.title}
                                                </option>
                                                {!urlWithoutNewItem.find(url => url == item.path)
                                                    &&
                                                <option value={item.path + '/new'}>
                                                    {item.title} (nowy element)
                                                </option>}
                                            </Fragment>
                                        ))}
                                    </optgroup>
                                ))
                            ))}
                        </Select>
                        <FormHelperText>{errors["selectedItem"] ? errors["selectedItem"].message : ""}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container sx={{mt: 5}}>
                <Grid item xs={6}>
                    <Button variant="outlined" color="default" onClick={handleClose}>
                        Zamknij
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Dodaj
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
};
export default ShortcutsEditListDialog;
