import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogTextField from "components/Edit/Dialog/DialogTextField";

const TestSmsDialog = ({open, handleConfirm, handleClose}) => {
    const {t} = useTranslation("common");
    const [errors, setErrors] = useState({});
    const [telephoneNumber, setTelephoneNumber] = useState("");

    const testNoEmptyValue = (name, value) => {
        if (!value || value === "") {
            setErrors((prevState) => ({
                    ...prevState,
                    [name]: {message: t("form-validation-required")}
                })
            )
            return true;
        }
        return false;
    }

    const handleSubmit = () => {
        let isError = false;
        setErrors({})

        testNoEmptyValue("telephoneNumber", telephoneNumber) ? isError = true : '';

        if(!isError) {
            setErrors({});
            handleConfirm(telephoneNumber);
        }

        // const TestTelephoneNumber = /^\+(?:[0-9] ?){6,14}[0-9]$/;
        // isError = telephoneNumber.match(TestTelephoneNumber);
        // if (isError == null) {
        //     setErrors({"telephoneNumber": { message: t("form-validation-telephone-number") }})
        // } else {
        //     setErrors({});
        //     handleConfirm(telephoneNumber);
        // }
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Test wysyłki sms</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogTextField
                        label={"Numer telefonu:"}
                        name={"telephoneNumber"}
                        errors={errors}
                        value={telephoneNumber}
                        setValue={setTelephoneNumber}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{mt: 5}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} >
                        Wyślij Test
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </>;
};
export default TestSmsDialog;
