import { Button, Hidden, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import MiniHeaderSelectLanguage from "layout/Header/MiniHeaderSelectLanguage";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles(styles);

const Header = ({ children, mobileToggle, mobileOpened, actionButton, top, changeLanguage }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const history = useHistory();

  return (
    <>
      <div className={classes.header}>
        <div className={classes.upperSection}>
          {/* <Breadcrumbs /> */}
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open mobile menu"
              onClick={mobileToggle}
              className={classes.smallButton}
              size="large"
            >
              {!mobileOpened && <MenuIcon />}
              {mobileOpened && <CloseIcon />}
            </IconButton>
          </Hidden>
        </div>
        <div className={classes.label}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Typography variant="h1" compo>
              {children}
            </Typography>
            {changeLanguage && <MiniHeaderSelectLanguage />}
          </Stack>
        </div>
        <div className={classes.actions}>{top}</div>
        <div className={classes.actionButton}>
          {actionButton && (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                history.push(actionButton);
              }}
            >
              dodaj className={classes.actionButton}
            </Button>
          )}
        </div>
      </div>
      {/*{bottom}*/}
    </>
  );
};

export default Header;
