import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import ExportConsentsDialog from "modules/Marketing/ExportConsentsDialog";
import DataTable from "components/Table/DataTable";
import getBackendHost from "utils/backendUrl";
import axios from "axios";
import moment from "moment";

const toolbarItems = [
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
    searchFieldName: "value:like",
  },
  {
    name: "toolbar_button_export",
    tooltip: "toolbar_button_export_tooltip",
    icon: "",
    action: "export_dialog",
    disabled: false,
    sortOrder: 3,
    type: "BUTTON_EXPORT",
  },
];

function getCSV(type) {
  const url = getBackendHost() + "/marketing-consents/get-csv/" + type;
  const dateTime = moment(new Date()).format("YYYY_MM_DD_HH_MM");
  const fileName = "agreement_" + type.toLowerCase() + "_" + dateTime + ".csv";
  console.log(fileName);

  axios
    .get(url, {
      withCredentials: true,
      responseType: "arraybuffer",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
}

const ConsentsList = () => {
  const { t } = useTranslation("common");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleExport = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <Layout
        header={t("marketing_consents_label")}
        bottom={<EmptySection />}
        top={
          <HeaderBuilder
            headerData={toolbarItems}
            moduleUrl={"/marketing/consents"}
            actionExportDialog={handleExport}
          />
        }
      >
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <DataTable modulePath={"/marketing/consents"} endpoint={"/marketing-consents/"} />
        </Paper>
      </Layout>
      <ExportConsentsDialog
        open={isDialogOpen}
        handleExport={(type) => {
          getCSV(type);
        }}
        handleClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};
export default ConsentsList;
