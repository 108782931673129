import React from "react";
import {useSelector} from "react-redux";
import {selectMenuItems} from "redux/slices/menu";
import CircularProgress from "@mui/material/CircularProgress";
import {Redirect} from "react-router-dom";

const StartPage = () => {
    const menu = useSelector(selectMenuItems);

    const isMyShortcutAvailable = () => {
        const START_GROUP_ID = -1000;
        const SHORTCUT_ID = -1120;

        if(Array.isArray(menu) && menu.length > 0)
        {
            const startGroup = menu?.filter(item => item.id == START_GROUP_ID);

            if(startGroup && startGroup.length > 0) {
                const startGroupPages = startGroup[0]?.group[0]?.pages;

                if(startGroupPages && startGroupPages.length > 0) {
                    return startGroupPages.some(item => item.id == SHORTCUT_ID);
                }
            }
        }

        return false;
    }

    if(menu?.length > 0 && isMyShortcutAvailable()) {
        return <Redirect to={"/home/shortcuts"} />
    } else if (menu?.length > 0 && !isMyShortcutAvailable()) {
        return <Redirect to={"/home/inbox"} />
    } else {
        return <CircularProgress />
    }
};
export default StartPage;
