import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import DiscountCriteriaTable from "components/Edit/DiscountCode/DiscountCriteriaTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import DiscountCodePanel from "components/Edit/DiscountCode/DiscountCodePanel";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import { useFormContext } from "react-hook-form";
import ProductCriteria from "components/Edit/DiscountCode/OrderCriteria";
import LanguagePanel from "components/Edit/DiscountCode/LanguagePanel";
import CustomizedDateTimePickerMuiValidLocalTimeToUtc from "components/Edit/CustomizedDateTimePickerMuiValidLocalTimeToUtc";

const endpoint = "/discount-code/";
const title = "marketing_discount_code_edit_label";
const moduleUrl = "/marketing/discount-codes/";

const types = [
  { id: "ORDER_ITEM", displayName: "Zamówienie produktu" },
  { id: "ORDER", displayName: "Zamówienie" },
  // { id: 'FULFILLMENT_GROUP', displayName: "Grupa" },
];

const typesDiscountOrderItem = [
  { id: "PERCENT_OFF", displayName: "Zniżka procentowa" },
  { id: "AMOUNT_OFF", displayName: "Zniżka kwotowa" },
  { id: "FIX_PRICE", displayName: "Stała cena" },
];

const typesDiscountOrder = [
  { id: "PERCENT_OFF", displayName: "Zniżka procentowa" },
  { id: "AMOUNT_OFF", displayName: "Zniżka kwotowa" },
];

const typesConditionCustomer = [
  // { id: "loggedIn", name: "Zalogowany", type: 'bool'},
  // { id: "registered", name: "Zarejestrowany", type: 'bool'},
  // { id: "id", name: "ID", type: 'integer'},
  { id: "receiveEmail", name: "Otrzymuje E-Maile", type: "bool" },
  { id: "emailAddress", name: "Adres e-mail", type: "text" },
  // { id: "userName", name: "Nazwa użytkownika", type: 'text'},
  { id: "firstName", name: "Imię", type: "text" },
  { id: "lastName", name: "Nazwisko", type: "text" },
];

const typesConditionOrder = [
  { id: "subTotal", name: "Suma częściowa zamówienia", type: "number" },
  { id: "currencyCode", name: "Kod waluty", type: "text" },
];

const typesConditionProduct = [
  { id: "name", name: "Nazwa Produktu", type: "text" },
  { id: "price", name: "Cena", type: "number" },
  { id: "quantity", name: "Ilość", type: "number" },
  { id: "category_name", name: "Kategoria - Nazwa", type: "text" },
  { id: "category_url", name: "Kategoria - URL", type: "text" },
  // { id: "product_manufacturer", name: "Producent",  type: 'text'},
  // { id: "sku_longDescription", name: "Opis",  type: 'text'}
];

const DiscountCodeEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    type: Yup.string().trim().required(t("form-validation-required")),
    description: Yup.string().nullable(),
    languages: Yup.array(),
    typeDiscount: Yup.string().trim().required(t("form-validation-required")),
    discountValue: Yup.number().typeError(t("form-validation-number")).positive(t("form-validation-number")),
    validFrom: Yup.date().typeError(t("form-validation-date")).nullable(),
    validUntil: Yup.date().typeError(t("form-validation-date")).nullable(),
    isCustomerCriteriaActive: Yup.bool().nullable(),
    customerCriteria: Yup.string().nullable(),
    customerCriteriaList: Yup.array(),
    isOrderCriteriaActive: Yup.bool().nullable(),
    orderCriteria: Yup.string().nullable(),
    orderCriteriaList: Yup.array(),
    isProductCriteriaActive: Yup.bool().nullable(),
    productCriteriaList: Yup.array(),
    maxNumberUses: Yup.number().nullable(),
    activeWhenPriceReduced: Yup.bool().nullable(),
    discountCumulative: Yup.bool().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Kod Rabatowy"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = () => {
  const { watch, setValue, getValues } = useFormContext();
  const watchIsCustomerCriteriaActive = watch("isCustomerCriteriaActive");
  const watchIsOrderCriteriaActive = watch("isOrderCriteriaActive");
  const watchIsProductCriteriaActive = watch("isProductCriteriaActive");
  const watchType = watch("type");

  const [typesDiscount, setTypesDiscount] = useState([]);

  useEffect(() => {
    if (watchType == "ORDER") {
      setTypesDiscount(typesDiscountOrder);
      if (getValues("typeDiscount") == "FIX_PRICE") {
        setValue("typeDiscount", "PERCENT_OFF");
      }
    } else if (watchType == "ORDER_ITEM") {
      setTypesDiscount(typesDiscountOrderItem);
    }
  }, [watchType]);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={"Typ"} name={"type"} items={types} defaultValue={"ORDER"} noNullValue />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={"Opis"} name={"description"} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LanguagePanel />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              label={"Typ zniżki"}
              name={"typeDiscount"}
              items={typesDiscount}
              defaultValue={"PERCENT_OFF"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Wartość zniżki"} name={"discountValue"} type={"number"} step={0.01} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiValidLocalTimeToUtc label={"Ważny od"} name={"validFrom"} defaultEmpty />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDateTimePickerMuiValidLocalTimeToUtc label={"Ważny do"} name={"validUntil"} defaultEmpty />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Kryteria przyznania zniżki
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container spacing={5}>
              {/*<Grid item xs={12}>*/}
              {/*    <CustomizedSwitch*/}
              {/*        topLabel={""}*/}
              {/*        rightLabel={"Konkretni klienci"}*/}
              {/*        name={"isCustomerCriteriaActive"}*/}
              {/*        defaultValue={false}*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*{watchIsCustomerCriteriaActive &&*/}
              {/*    <DiscountCriteriaTable*/}
              {/*        typesCondition={typesConditionCustomer}*/}
              {/*        listName={"customerCriteriaList"}*/}
              {/*        mainOperatorName={"customerCriteria"}*/}
              {/*    />}*/}
              {watchType == "ORDER" && (
                <>
                  <Grid item xs={12}>
                    <CustomizedSwitch
                      topLabel={""}
                      rightLabel={"Konkretne zamówienia"}
                      name={"isOrderCriteriaActive"}
                      defaultValue={false}
                    />
                  </Grid>
                  {watchIsOrderCriteriaActive && (
                    <DiscountCriteriaTable
                      typesCondition={typesConditionOrder}
                      listName={"orderCriteriaList"}
                      mainOperatorName={"orderCriteria"}
                    />
                  )}
                </>
              )}
              {watchType == "ORDER_ITEM" && (
                <>
                  <Grid item xs={12}>
                    <CustomizedSwitch
                      topLabel={""}
                      rightLabel={"Konkretne produkty"}
                      name={"isProductCriteriaActive"}
                      defaultValue={false}
                    />
                  </Grid>
                  {watchIsProductCriteriaActive && (
                    <Grid item xs={12}>
                      <ProductCriteria
                        typesCondition={typesConditionProduct}
                        mainListName={"productCriteriaList"}
                        listName={"criteriaItemList"}
                        mainOperatorName={"productCriteria"}
                        matchName={"match"}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Kody rabatowe
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container spacing={5}>
              <DiscountCodePanel name={"discountCodeItems"} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Ustawienia zaawansowane
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container spacing={5}>
              {/*<Grid item xs={12}>*/}
              {/*    <CustomizedTextField*/}
              {/*        label={"Max liczba użyć kodu na klienta"}*/}
              {/*        name={"maxNumberUses"}*/}
              {/*        type={"number"}*/}
              {/*        defaultValue={0}*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*    <Divider sx={{ mt:4, mb:0, p:0 }}/>*/}
              {/*</Grid>*/}
              {watchType == "ORDER_ITEM" && (
                <>
                  <Grid item xs={12}>
                    <CustomizedSwitch
                      topLabel={""}
                      rightLabel={"Zastosuj przy obniżonej cenie"}
                      name={"activeWhenPriceReduced"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 0, p: 0 }} />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <CustomizedSwitch
                  topLabel={""}
                  rightLabel={"Zniżka sumuje się z innymi kodami"}
                  name={"discountCumulative"}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
};

export default DiscountCodeEdit;
