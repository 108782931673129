import { Grid, InputLabel, LinearProgress, List, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedListItemVariable from "components/Edit/Mail/CustomizedListItemVariable";
import TextField from "@mui/material/TextField";
import TestSmsDialog from "modules/Marketing/TestSmsDialog";
import EditViewLanguage from "components/EditViewLanguage";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectEditItem } from "redux/slices/crud";

const endpoint = "/sms-template/";
const title = "marketing_sms_edit_label";
const moduleUrl = "/marketing/sms/";

const variables = [
  {
    id: 0,
    name: "@openCode@",
    description: "kod otwarcia",
  },
  {
    id: 1,
    name: "@testCode@",
    description: "kod badania",
  },
];

const SmsEdit = () => {
  const { t } = useTranslation("common");

  const [openDialogTestSms, setOpenDialogTestSms] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    content: Yup.string().trim().required(t("form-validation-required")),
  });

  const defaultValue = {
    name: "",
    content: "",
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        testMessage={() => setOpenDialogTestSms(true)}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń SMS"}
      >
        <FormData openDialogTestSms={openDialogTestSms} handleCloseDialogTestSms={() => setOpenDialogTestSms(false)} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ openDialogTestSms, handleCloseDialogTestSms }) => {
  const { register, control, formState, setValue } = useFormContext();
  const [numberCharacters, setNumberCharacters] = useState(0);
  const [testIsProgress, setTestIsProgress] = useState(0);
  const current = useSelector(selectEditItem);
  const { watch } = useFormContext();
  const watchContent = watch("content");
  const textRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (current) {
      setNumberCharacters(current.content.length);
    }
  }, []);

  const handleSendTestSms = (number) => {
    handleCloseDialogTestSms();

    const d = {
      phoneNumber: number,
      content: watchContent,
    };

    setTestIsProgress((prev) => prev + 1);

    backend.post(endpoint + "test-send-sms", d).then((res) => {
      if (isSuccess(res)) {
        if (res.data) {
          enqueueSnackbar("Test wiadomości został wysłany poprawnie", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Wysyłka wiadomości testowej - nieudana", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
      setTestIsProgress((prev) => prev - 1);
    });
  };

  const handleAddCode = (code) => {
    const start = textRef?.current.selectionStart;
    const end = textRef?.current.selectionEnd;
    const newText = textRef?.current.value.slice(0, start) + code + textRef?.current.value.slice(end);
    setValue("content", newText);
    setNumberCharacters(newText.length);
  };

  return (
    <>
      <Grid container spacing={5}>
        {/* main */}
        <Grid item xs={12} sm={8}>
          <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomizedTextField label={"Nazwa"} name={"name"} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor={"content"}>{"Treść sms"}</InputLabel>
                <TextField
                  {...register("content")}
                  inputRef={textRef}
                  helperText={formState?.errors["content"] ? formState?.errors["content"].message : ""}
                  error={!!formState?.errors["content"]}
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  multiline
                  minRows={5}
                  onChange={(e) => setNumberCharacters(e.target.value.length)}
                />
                {"Znaki: " + numberCharacters}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {testIsProgress > 0 && (
                <>
                  {testIsProgress > 1 ? (
                    <InputLabel>Wysyłka wiadomości ({testIsProgress})...</InputLabel>
                  ) : (
                    <InputLabel>Wysyłka wiadomości...</InputLabel>
                  )}
                  <LinearProgress />
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
        {/* right */}
        <Grid item xs={12} sm={4}>
          <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
            <Grid container spacing={5}>
              <List>
                {variables.map((item) => (
                  <CustomizedListItemVariable
                    key={item.id}
                    primaryText={item.name}
                    secondaryText={item.description}
                    handleClick={() => handleAddCode(item.name)}
                  />
                ))}
              </List>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <TestSmsDialog
        open={openDialogTestSms}
        handleClose={handleCloseDialogTestSms}
        handleConfirm={handleSendTestSms}
      />
    </>
  );
};

export default SmsEdit;
