import * as color from "assets/styles/jss/common/colors";

const menuCategoryStyles = {
  img: {
    color: color.white,
    height: 22,
    width: "auto",
  },
  name: {
  },
  category: {
    flexDirection: "column",
    height: 60,
    alignItems: "center",
    justifyContent: "center !important",
    cursor: "pointer",
    "@media (max-width: 960px)": {
      paddingLeft: 20,
      justifyContent: "flex-start !important",
      flexDirection: "row",
      height: 42,
      "&:hover": {
        backgroundColor: color.secondary,
      },
    },
  },
  clicked: {
    background: color.secondary,
  },
  imgWrapper: {
    paddingTop: 4,
    "@media (max-width: 960px)": {
      width: 30,
      height: 30,
      textAlign: "center",
      marginRight: 5,
    },
  },
};

export default menuCategoryStyles;
