import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogTextField from "components/Edit/Dialog/DialogTextField";
import * as color from "assets/styles/jss/common/colors";

const AddSectionItemDialog = ({open, handleClose, handleRemove, handleConfirm, itemToEdit}) => {
    const {t} = useTranslation("common");
    const [errors, setErrors] = useState({});
    const [value, setValue] = useState("");

    useEffect(() => {
        if(itemToEdit) {
            setValue(itemToEdit.value);
        } else {
            resetValue();
        }
    }, [itemToEdit, open])

    const testNoEmptyValue = (name, value) => {
        if (!value || value === "") {
            setErrors((prevState) => ({
                    ...prevState,
                    [name]: {message: t("form-validation-required")}
                })
            )
            return true;
        }
        return false;
    }

    const handleSubmit = () => {
        let isError = false;
        setErrors({})

        testNoEmptyValue("value", value) ? isError = true : '';

        if (!isError) {
            setErrors({});

            let sectionItem = {
                value: value
            }

            if(itemToEdit) {
                sectionItem = {...sectionItem, id : itemToEdit.id, sortOrder: itemToEdit.sortOrder}
            }

            resetValue();
            handleConfirm(sectionItem);
        }
    }

    const resetValue = () => {
        setErrors({});
        setValue("");
    }

    const handleDeleteItem = () => {
        if (itemToEdit) {
            handleRemove(itemToEdit);
        }

        resetValue();
        handleClose();
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            disablePortal
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>Dodaj element</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogTextField
                        label={"Etykieta"}
                        name={"value"}
                        errors={errors}
                        value={value}
                        setValue={setValue}
                    />
                </Grid>
            </Grid>

            <Grid container sx={{mt: 5}}>
                <Grid item xs={6}>
                    <Button variant="outlined" color="default" sx={{color: color.red}} onClick={handleDeleteItem}>
                        Usuń
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Zapisz
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </>;
};
export default AddSectionItemDialog;
