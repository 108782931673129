import { Grid, Paper } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import EditViewNoLanguage from "components/EditViewNoLanguage";

const endpoint = "/sitemap/";
const title = "settings_sitemap_edit_label";
const moduleUrl = "/settings/sitemap/";

const frequency = [
    {id: 'hourly', displayName: 'Co godzinę'},
    {id: 'daily', displayName: 'Codziennie'},
    {id: 'weekly', displayName: 'Co tydzień'},
    {id: 'monthly', displayName: 'Co miesiąc'},
    {id: 'yearly', displayName: 'Co rok'},
    {id: 'always', displayName: 'Zawsze'},
    {id: 'never', displayName: 'Nigdy'}
]

const priorities = [
    {
        id: '0',
        displayName: "0"
    },
    {
        id: '0.25',
        displayName: "0.25"
    },
    {
        id: '0.5',
        displayName: "0.5"
    },
    {
        id: '0.75',
        displayName: "0.75"
    },
    {
        id: '1',
        displayName: "1"
    }
]

const SitemapEdit = () => {
    const {id} = useParams();
    const current = useSelector(selectEditItem);
    const {t} = useTranslation("common");

    const validationSchema = Yup.object({
        url: Yup.string().trim().required(t("form-validation-required")),
        priority: Yup.string().trim().required(t("form-validation-required")),
        frequency: Yup.string().trim().required(t("form-validation-required")),
        excludeFromSiteMap: Yup.bool().nullable(),
    });

    const refForm = useRef(null);

    return (
        <>
            <EditViewNoLanguage
                endpoint={endpoint}
                title={title}
                moduleUrl={moduleUrl}
                validationSchema={validationSchema}
                refForm={refForm}
            >
                <FormData id={id} current={current} refForm={refForm}/>
            </EditViewNoLanguage>
        </>
    );
};

const FormData = ({id, current, refForm}) => {
    const {t} = useTranslation("common");

    return <>
        <Paper sx={{p: 10, mb: 10}} elevation={8}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                    <CustomizedTextField
                        label={"URL"}
                        name={"url"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedSelect
                        name={"priority"}
                        label={"Priorytet"}
                        items={priorities}
                        defaultValue={'0.5'}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedSelect
                        name={"frequency"}
                        label={"Częstotliwość zmian"}
                        items={frequency}
                        defaultValue={'hourly'}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomizedSwitch
                        name={"excludeFromSiteMap"}
                        topLabel={""}
                        rightLabel={"Usuń z sitemapy"}
                    />
                </Grid>
            </Grid>
        </Paper>
    </>
};

export default SitemapEdit;
