import { Grid, Paper } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import SliderItemsPanel from "components/Edit/Slider/SliderItemsPanel";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";

const endpoint = "/slider/";
const title = "cms_slider_edit_label";
const moduleUrl = "/cms/sliders/";

const SliderEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    interval: Yup.number().typeError(t("form-validation-number")).integer(t("form-validation-number")),
    offline: Yup.bool().nullable(),
    items: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
        content: Yup.string().nullable(),
        url: Yup.string().nullable(),
        buttonContent: Yup.string().nullable(),
        backgroundImage: Yup.string().nullable(),
        backgroundImageAltText: Yup.string().nullable(),
        backgroundMobileImage: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
  });

  const defaultValue = {
    name: "",
    interval: "",
    offline: "true",
    items: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Slider"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={6}>
            <CustomizedTextField label={"Interwał [ms]"} name={"interval"} type={"number"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSwitch
              name={"offline"}
              rightLabel={t("cms_edit_page_offline")}
              topLabel={t("cms_edit_page_state")}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <SliderItemsPanel label={"Lista slajdów"} name={"items"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SliderEdit;
