import styles from "./styles.js";
import makeStyles from "@mui/styles/makeStyles";
import { List } from "@mui/material";

const useStyles = makeStyles(styles);

const SubmenuUl = (props) => {
    const classes = useStyles();
    return (
        <List className={`${classes.dropdown} ${props.className}`}>
            <span className={classes.name}>{props.name.toUpperCase()}</span>
            {props.children}
        </List>
    );
};

export default SubmenuUl;
