import React from "react";
import TableCell from "@mui/material/TableCell";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/PhoneEnabled";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";

const CellMarketingConsents = ({data, row, handleClick}) => {
    return (
        <TableCell onClick={(e) => handleClick(e, row)}>
            {data?.mailConsent && <MailIcon fontSize={"small"} sx={{marginRight: "10px", color: alpha(color.black, 0.7),}}/>}
            {data?.phoneConsent && <PhoneIcon fontSize={"small"} sx={{color: alpha(color.black, 0.7),}}/>}
        </TableCell>

    );
};


export default CellMarketingConsents;