import React from "react";
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";

const CustomizedTabsConfiguration = ({tabsList, activeTab, handleChange}) => {

    return (
        <List>
            {tabsList.map((item, index) =>
                <ListItem key={item.id} sx={{ m:0, borderRadius: 6 }}>
                    <ListItemButton
                        sx={{ borderRadius: 2 }}
                        onClick={(e) => handleChange(e, index)}
                        selected={activeTab === index}
                    >
                        <ListItemText primary={
                            <Typography variant="body2" sx={{fontWeight: "bold"}}>
                                {item.displayName}
                            </Typography>}
                        />
                    </ListItemButton>
                </ListItem>
            )}
        </List>
    );
}

export default CustomizedTabsConfiguration;
