import { Paper } from "@mui/material";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../layout/Layout";
import DataTable from "components/Table/DataTable";

const toolbarItems = [
  {
    name: "toolbar_button_add",
    tooltip: "toolbar_button_add_tooltip",
    icon: "",
    action: "add",
    disabled: false,
    sortOrder: 0,
    type: "BUTTON",
  },
  {
    name: "toolbar_icon_copy",
    tooltip: "toolbar_icon_duplicate_tooltip",
    icon: "ContentCopy",
    action: "copy",
    disabled: false,
    sortOrder: 1,
    type: "ICON",
  },
  {
    name: "toolbar_icon_del",
    tooltip: "toolbar_icon_del_tooltip",
    icon: "Delete",
    action: "delete",
    disabled: false,
    sortOrder: 2,
    type: "ICON",
  },
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
    searchFieldName: "firstName:like,lastName:like,mail:like",
  },
];

const CustomersList = () => {
  const { t } = useTranslation("common");

  return (
    <Layout
      header={t("shop_customers_label")}
      bottom={<EmptySection />}
      top={<HeaderBuilder headerData={toolbarItems} moduleUrl={"/shop/customers"} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable modulePath={"/shop/customers"} endpoint={"/client/"} />
      </Paper>
    </Layout>
  );
};
export default CustomersList;
