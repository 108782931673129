import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "layout/Layout";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchCrudItem, selectListError, selectListIsLoading } from "redux/slices/crud";
import backend from "../../api/backend";
import {
  clearCurrentItem,
  fetchCrudList,
  selectEditItem,
  selectListFilters,
  setListPage,
} from "../../redux/slices/crud";
import { isSuccess } from "../../utils/http";
import EmptySection from "components/EmptySection";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import EditActionButtons from "components/Edit/EditActionButtons";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationDialog from "components/ConfirmationDialog";
import Error from "components/Error";
import { selectMenuItems, setCurrentSelectedLevelOne, setCurrentSelectedLevelZero } from "redux/slices/menu";
import { findMenu } from "utils/menu";

const EditViewNoLanguage = ({
  children,
  endpoint,
  title,
  moduleUrl,
  validationSchema,
  onSubmit,
  refForm = useRef(null),
  preview,
  testMessage,
  noDeleteButton,
  noBackButton,
  customSaveFunc = null,
  deleteButtonTitle,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const filters = useSelector(selectListFilters);
  const [blockSetFields, setBlockSetFields] = useState(true);
  const [ignoreError, setIgnoreError] = useState(false);

  const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);

  const current = useSelector(selectEditItem);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  }); // initialize the hook

  useEffect(() => {
    dispatch(clearCurrentItem());
    if (id === "new") {
      setIgnoreError(true);
    } else {
      dispatch(fetchCrudItem({ endpoint, id }));
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, []);

  const menuItems = useSelector(selectMenuItems);
  useEffect(() => {
    const { levelZero, levelOne } = findMenu(menuItems);
    dispatch(setCurrentSelectedLevelZero(levelZero));
    dispatch(setCurrentSelectedLevelOne(levelOne));
  }, [menuItems]);

  useEffect(() => {
    if (current && !blockSetFields) {
      Object.keys(current).forEach((k) => {
        methods.setValue(k, current[k]);
      });
    }
  }, [current]);

  useEffect(() => {
    if (id != "new") {
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, [id]);

  /*TO DO*/
  const handlePreview = () => {
    console.log("Podgląd");
  };

  const handleSave = () => {
    console.log("Save");
    refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleSubmit = (d) => {
    console.log(d);

    if (typeof onSubmit === "function") {
      const data = onSubmit(d);
      console.log(data);
      _onSubmit(data);
    } else {
      _onSubmit(d);
    }
  };

  const _onSubmit = (d) => {
    let idTmp = id;

    if (id === "new" || current == null) {
      idTmp = 0;
    }

    backend.post(endpoint + idTmp, d).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        if (res.data.item != null) {
          if (idTmp === 0) {
            const newId = res.data.item.id;
            dispatch(fetchCrudItem({ endpoint, id: newId }));
            history.push(moduleUrl + newId);
          } else {
            dispatch(fetchCrudItem({ endpoint, id }));
          }
        } else {
          history.push(moduleUrl);
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleOpenDeleteDialog = () => {
    setOpenedRemoveDialog(true);
  };

  const handleDeleteFromDB = () => {
    console.log("Delete");

    backend.delete(endpoint + id).then((res) => deleteResponseFunction(res));

    setOpenedRemoveDialog(false);
  };

  const deleteResponseFunction = (res) => {
    if (isSuccess(res)) {
      enqueueSnackbar(t("common_status_successfully"), {
        variant: "success",
      });
      dispatch(setListPage(0));
      dispatch(fetchCrudList({ endpoint, filters }));
      history.push(moduleUrl);
    } else {
      enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
        variant: "error",
      });
    }
  };

  return (
    <Layout
      header={t(title)}
      top={
        <HeaderBuilder
          headerData={[]}
          isEditViewActive={true}
          moduleUrl={moduleUrl}
          noBackButton={noBackButton}
          noDeleteButton={noDeleteButton || id == "new"}
          handleDelete={handleOpenDeleteDialog}
          deleteButtonTitle={deleteButtonTitle}
        />
      }
      bottom={<EmptySection />}
    >
      <FormProvider {...methods} handleDelete={handleOpenDeleteDialog}>
        {isLoading && !ignoreError ? (
          <CircularProgress />
        ) : error.isError && !ignoreError ? (
          <Error error={error} />
        ) : !error.isError && current == null && !ignoreError ? (
          <Error error={{ message: "no data" }} />
        ) : (
          <>
            <form ref={refForm} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
              {children}
            </form>
            <EditActionButtons
              handleSave={typeof customSaveFunc === "function" ? customSaveFunc : handleSave}
              handleDelete={handleOpenDeleteDialog}
              handlePreview={preview ? handlePreview : false}
              handleTest={testMessage ? testMessage : false}
              noDeleteButton={noDeleteButton || id == "new"}
            />
          </>
        )}
      </FormProvider>
      <ConfirmationDialog
        open={openedRemoveDialog}
        close={() => setOpenedRemoveDialog(false)}
        confirm={handleDeleteFromDB}
      />
    </Layout>
  );
};

export default EditViewNoLanguage;
