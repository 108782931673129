import { Grid, Paper } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import Wysiwyg from "components/Edit/Wysiwyg";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";

const endpoint = "/footer/";
const title = "cms_footer_edit_label";
const moduleUrl = "/cms/footers/";

const FooterEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    leftContent: Yup.string(),
    centerContent: Yup.string(),
    rightContent: Yup.string(),
    offline: Yup.bool().nullable(),
  });

  const defaultValue = {
    name: "",
    leftContent: "",
    centerContent: "",
    rightContent: "",
    offline: "true",
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Stopkę"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSwitch
              name={"offline"}
              rightLabel={t("cms_edit_page_offline")}
              topLabel={t("cms_edit_page_state")}
            />
          </Grid>

          <Grid item xs={12}>
            <Wysiwyg label={"Lewa strona stopki"} name={"leftContent"} />
          </Grid>
          <Grid item xs={12}>
            <Wysiwyg label={"Środek stopki"} name={"centerContent"} />
          </Grid>
          <Grid item xs={12}>
            <Wysiwyg label={"Prawa strona stopki"} name={"rightContent"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default FooterEdit;
