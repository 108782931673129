import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabPanel from "components/Edit/CustomizedTabPanel";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import axios from "axios";

const endpoint = "/client/";
const title = "shop_customer_edit_label";
const moduleUrl = "/shop/customers/";

const CustomerEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required(t("form-validation-required")),
    lastName: Yup.string().trim().required(t("form-validation-required")),
    mail: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
    phoneNumber: Yup.string().nullable(),
    deliveryStreet: Yup.string().nullable(),
    deliveryBuildingNumber: Yup.string().nullable(),
    deliveryApartmentNumber: Yup.string().nullable(),
    deliveryZipCode: Yup.string().nullable(),
    deliveryCity: Yup.string().nullable(),
    invoiceCompany: Yup.string().nullable(),
    invoiceVatNumber: Yup.string().nullable(),
    invoiceStreet: Yup.string().nullable(),
    invoiceBuildingNumber: Yup.string().nullable(),
    invoiceApartmentNumber: Yup.string().nullable(),
    invoiceZipCode: Yup.string().nullable(),
    invoiceCity: Yup.string().nullable(),
    mailConsent: Yup.boolean().nullable(),
    telephoneConsent: Yup.boolean().nullable(),
    active: Yup.boolean().nullable(),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        deleteButtonTitle={"Usuń Kliena"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const history = useHistory();
  const { watch, setValue } = useFormContext();
  const watchOrders = watch("orders");
  const [activeTabLinked, setActiveTabLinked] = useState(0);

  useEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setValue("ip", res.data.IPv4);
    };

    getIp();
  }, []);

  const handleChangeLinked = (event, newValue) => {
    setActiveTabLinked(newValue);
  };

  const handleEditOrder = (orderId) => {
    history.push("/shop/orders/" + orderId);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel>ID klienta</InputLabel>
            <Typography> {id} </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch topLabel={<>&nbsp;</>} rightLabel={"Aktywny"} name={"active"} />
          </Grid>
          {/*<Grid item xs={12} sm={6}>*/}
          {/*    <InputLabel>*/}
          {/*        Posiada konto?*/}
          {/*    </InputLabel>*/}
          {/*    {current?.registered && <Chip label="Zarejestrowany" color="success" size="small"/>}*/}
          {/*    {!current?.registered && <Chip label="Niezarejestrowany" color="warning" size="small"/>}*/}
          {/*</Grid>*/}
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Imię"} name={"firstName"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwisko"} name={"lastName"} />
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Dane kontaktowe
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Email"} name={"mail"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Telefon"} name={"phoneNumber"} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Adres dostawy
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Ulica"} name={"deliveryStreet"} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <CustomizedTextField label={"Nr domu"} name={"deliveryBuildingNumber"} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <CustomizedTextField label={"Nr lokalu / mieszkania"} name={"deliveryApartmentNumber"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Kod pocztowy"} name={"deliveryZipCode"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Miasto"} name={"deliveryCity"} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Dane kontaktowe
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Nazwa firmy"} name={"invoiceCompany"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"NIP / VAT ID "} name={"invoiceVatNumber"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Ulica"} name={"invoiceStreet"} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <CustomizedTextField label={"Nr domu"} name={"invoiceBuildingNumber"} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <CustomizedTextField label={"Nr lokalu / mieszkania"} name={"invoiceApartmentNumber"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Kod pocztowy"} name={"invoiceZipCode"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomizedTextField label={"Miasto"} name={"invoiceCity"} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      <Tabs onChange={handleChangeLinked} value={activeTabLinked} aria-label="tabSection" centered>
        <Tab label="Zamówienia" />
        <Tab label="Zgody marketingowe" />
      </Tabs>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <TabPanel value={activeTabLinked} index={0}>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Numer zamówienia</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Suma</TableCell>
                    <TableCell>Data utworzenia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watchOrders?.map((row) => (
                    <TableRow key={row.id} onClick={() => handleEditOrder(row.id)}>
                      <TableCell>{row.orderNumber}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.totalCost}</TableCell>
                      <TableCell>{moment.utc(row.created).format("DD.MM.yyyy HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                  {watchOrders?.length == 0 && (
                    <TableRow>
                      <TableCell align={"center"} colSpan={4}>
                        Brak zamówień
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTabLinked} index={1}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <CustomizedSwitch
                topLabel={""}
                rightLabel={"Zgoda na marketing emailowy"}
                name={"mailConsent"}
                defaultValue={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomizedSwitch
                topLabel={""}
                rightLabel={"Zgoda na marketing telefoniczny"}
                name={"telephoneConsent"}
                defaultValue={false}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Paper>
    </>
  );
};

export default CustomerEdit;
