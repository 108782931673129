import React from "react";
import { InputLabel } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ImageIcon from "@mui/icons-material/Image";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { useFormContext } from "react-hook-form";
import {
  IcnAlergie,
  IcnBadaniaDNA,
  IcnBadaniaPrenatalne,
  IcnBadanieKrwi,
  IcnBadanieMoczu,
  IcnBadanieZWymazu,
  IcnKoronawirus,
  IcnMikrofloraJelitowa,
  IcnNietolerancjePokarmowe,
  IcnSuplementy,
  IcnDostawaKurierem,
} from "assets/icons";

const icons = [
  { id: "IcnAlergie", displayName: "Alergie" },
  { id: "IcnBadaniaDNA", displayName: "Badania DNA" },
  { id: "IcnBadanieKrwi", displayName: "Badanie Krwi" },
  { id: "IcnBadanieMoczu", displayName: "Badanie Moczu" },
  { id: "IcnBadaniaPrenatalne", displayName: "Badania Prenatalne" },
  { id: "IcnBadanieZWymazu", displayName: "Badanie Z Wymazu" },
  { id: "IcnDostawaKurierem", displayName: "Dostawa Kurierem" },
  { id: "IcnKoronawirus", displayName: "Koronawirus" },
  { id: "IcnMikrofloraJelitowa", displayName: "Mikroflora Jelitowa" },
  { id: "IcnNietolerancjePokarmowe", displayName: "Nietolerancje Pokarmowe" },
  { id: "IcnSuplementy", displayName: "Suplementy" },
];

const iconSx = { borderRadius: 1, mr: 4 };

const CustomizedIconChoice = ({ label, name }) => {
  const { watch } = useFormContext();
  const icon = watch(name);

  const getIcon = () => {
    switch (icon) {
      case "IcnNietolerancjePokarmowe":
        return <IcnNietolerancjePokarmowe sx={iconSx} />;
      case "IcnBadaniaDNA":
        return <IcnBadaniaDNA sx={iconSx} />;
      case "IcnBadaniaPrenatalne":
        return <IcnBadaniaPrenatalne sx={iconSx} />;
      case "IcnMikrofloraJelitowa":
        return <IcnMikrofloraJelitowa sx={iconSx} />;
      case "IcnBadanieKrwi":
        return <IcnBadanieKrwi sx={iconSx} />;
      case "IcnAlergie":
        return <IcnAlergie sx={iconSx} />;
      case "IcnKoronawirus":
        return <IcnKoronawirus sx={iconSx} />;
      case "IcnBadanieMoczu":
        return <IcnBadanieMoczu sx={iconSx} />;
      case "IcnSuplementy":
        return <IcnSuplementy sx={iconSx} />;
      case "IcnBadanieZWymazu":
        return <IcnBadanieZWymazu sx={iconSx} />;
      case "IcnDostawaKurierem":
        return <IcnDostawaKurierem sx={iconSx} />;
      default:
        return <ImageIcon sx={iconSx} />;
    }
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 3,
          mb: 5,
          borderWidth: 2,
          borderRadius: 2,
          borderColor: alpha(color.primary, 0.15),
          borderStyle: "dashed",
          backgroundColor: alpha(color.primary, 0.05),
          outline: "none",
          transition: "border .24s ease-in-out",
          cursor: "pointer",
          "&:hover": {
            borderColor: alpha(color.primary, 0.55),
          },
        }}
      >
        {getIcon()}
        <CustomizedSelect name={name} label="" items={icons} defaultValue={""} />
      </Box>
    </>
  );
};

export default CustomizedIconChoice;
